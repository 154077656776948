




































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component
/*
A wrapper component for the form-group and form-input.
@group INPUT ELEMENTS
 */
export default class BaseInput extends Vue {
  // unique identifier
  @Prop()
  id: string | number

  // if the input is disabled
  @Prop()
  disabled: boolean

  // if its writable (mostly used in combination with role rights)
  @Prop()
  writeable: boolean

  // if its readonly (mostly used in combination with role rights)
  @Prop()
  readonly: boolean

  // adds a delete icon for the input field
  @Prop()
  deletable: boolean

  // if the delete action is disabled
  @Prop({ default: false })
  deleteDisabled: boolean

  // the label for the input
  @Prop()
  label: string

  // if the input field is required
  @Prop()
  required: boolean

  // the model for the input data
  @Prop({ required: true })
  value: string

  // the current state of the input field
  @Prop()
  state: boolean

  // the label column width for sm screen sizes
  @Prop({ default: 12 })
  labelSM: number

  // the label column width for md screen sizes
  @Prop({ default: 4 })
  labelMD: number

  internalModel: string | null = null

  updateValue (e: string): void {
    this.internalModel = e
    this.$emit('input', e)
  }

  remove (): void {
    this.$emit('remove', this.id)
  }

  setSoftHyphensInLabels (label: string): string {
    return label.replace(/#shy#/g, '&shy;')
  }

  @Watch('value', { immediate: true })
  onValueChanged (newValue: string): void {
    this.internalModel = newValue
  }
}
