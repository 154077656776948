import { CompanyProfile, InquiryDto } from '@/store/company/types'
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import {
  GET_PROFILE_DATA_EDIT,
  SAVE_OWN_PROFILE,
  INQUIRE_TALENT,
  INQUIRE_TALENT_CANCEL
} from '@/store/company/actions'
import { MU_SET_PROFILE_DATA } from '@/store/company/mutations'
import { CompanyService } from '@/common/api.service'
import { GenericRequest, SimpleServerResponse } from '@/store/types'

@Module
class CompanyState extends VuexModule {
  profile: CompanyProfile | undefined = undefined

  get companyProfileGetter (): CompanyProfile | undefined {
    return this.profile
  }

  @Action({ rawError: true })
  public [GET_PROFILE_DATA_EDIT] (): Promise<CompanyProfile> {
    return CompanyService.getProfile().then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [SAVE_OWN_PROFILE] (dataRequest: CompanyProfile): Promise<SimpleServerResponse> {
    return CompanyService.saveOwnProfile(dataRequest).then(data => {
      this.context.commit(MU_SET_PROFILE_DATA, dataRequest)
      return data.data
    }).catch(error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [INQUIRE_TALENT] (data: InquiryDto): Promise<InquiryDto> {
    return CompanyService.inquiryTalent(data)
  }

  @Action({ rawError: true })
  public [INQUIRE_TALENT_CANCEL] (data: GenericRequest): Promise<SimpleServerResponse> {
    return CompanyService.cancelInquiry(data)
  }

  @Mutation
  public [MU_SET_PROFILE_DATA] (profile: CompanyProfile): void {
    this.profile = profile
  }
}

export default CompanyState
