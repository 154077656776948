












































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import fileUploader from '@/store/common/FileUploader'
import { MediaFile } from '@/store/commonFormInput/types'
import ClickableIcon from '@/components/common/ClickableIcon.vue'

@Component({
  components: { ClickableIcon }
})
/*
The media uploader component enables the user to upload media files like images as profile pictures or videos.
@group INPUT ELEMENTS
 */
export default class MediaUploader extends Vue {
  // Define the upload file type. Valid types:
  // `'PHOTO'`, `'VIDEO'`
  @Prop({
    required: true,
    validator: function (value) { return value === 'PHOTO' || value === 'VIDEO' }
  })
  uploadType: string

  // unique identifier
  @Prop({ required: true })
  id: string

  // the image that is displayed in the media uploader
  @Prop()
  displayImage: MediaFile

  // if its writable (mostly used in combination with role rights)
  @Prop()
  writable: boolean

  showDelete = false

  @Watch('displayImage', { immediate: true })
  onPropertyChanged (value: MediaFile): void {
    this.fileToUpload = value
    this.setBackgroundImage()
  }

  inputFieldId = this.id + '_field'
  fileToUpload: MediaFile = {}
  fileInputField: any = null
  fileInput: HTMLElement | null = null
  isLoading = false

  chooseFile (): void {
    if (this.fileInputField !== null && this.writable) {
      this.fileInputField.click()
    }
  }

  addFileOnDrop (event: DragEvent): void {
    event.preventDefault()
    event.stopPropagation()

    if (event.dataTransfer !== null && this.writable) {
      const file = event.dataTransfer.files[0]
      this.onFileChange(file)
    }
  }

  onFileChange (file: File): void {
    this.$root.$emit('dismiss-alert')
    if (this.uploadType === 'PHOTO') {
      this.uploadImage(file).then((file: MediaFile) => {
        this.showDelete = true
        this.emitNewFile(file)
      }).catch(() => {
        this.isLoading = false
      })
    } else {
      this.uploadVideo(file).then((file: MediaFile) => {
        this.emitNewFile(file)
      }).catch(() => {
        this.isLoading = false
      })
    }
  }

  emitNewFile (file: MediaFile): void {
    this.isLoading = false
    this.fileToUpload = file
    this.setBackgroundImage()
    this.$emit('fileChanged', this.fileToUpload)
  }

  setBackgroundImage (): void {
    let displayImage
    if (this.uploadType === 'PHOTO' && this.fileToUpload?.file) {
      displayImage = this.fileToUpload.file
    }

    if (this.uploadType === 'VIDEO' && this.fileToUpload?.base64ImageData) {
      displayImage = this.fileToUpload.base64ImageData
    }

    if (this.fileInput !== null) {
      if (displayImage) {
        this.fileInput.style.backgroundImage = 'url(\'' + displayImage + '\')'
        this.fileInput.style.backgroundPosition = 'center center'
        this.fileInput.style.backgroundSize = 'contain'
        this.fileInput.style.backgroundRepeat = 'no-repeat'
        this.showDelete = true
      } else {
        this.fileInput.style.backgroundImage = ''
      }
    }
  }

  uploadImage (file: File): Promise<MediaFile> {
    return new Promise<MediaFile>((resolve, reject) => {
      const maxFileSize = 5
      if (!file.type.match('image.*')) {
        this.$root.$emit('alert',
          this.$t('warning.error!').toString(),
          this.$t('warning.wrong-mimeType', { mimeTypes: this.$t('warning.image-types').toString() }).toString(),
          true
        )
        reject(Error('wrong mime-type'))
      }

      const fileUri = fileUploader.checkFileSize(file, maxFileSize)

      if (fileUri === undefined) {
        this.$root.$emit(
          'alert',
          this.$t('warning.error!').toString(),
          this.$t('warning.the-file-is-too-big', {
            fileName: file.name,
            maxFileSizeInMB: maxFileSize
          }).toString(),
          true
        )
        reject(Error('too big'))
      } else {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function (): void {
          const fileToUpload = {
            file: fileUri,
            fileForUpload: file,
            url: file.name,
            uri: file.name,
            documentType: 'IMAGE',
            base64ImageData: reader.result as string
          }
          resolve(fileToUpload)
        }
        reader.onerror = function (): void {
          reject(Error('error while reading'))
        }
      }
    }) as Promise<MediaFile>
  }

  uploadVideo (file: File): Promise<MediaFile> {
    return new Promise((resolve, reject) => {
      const maxFileSize = 15
      if (!file.type.match('video.*')) {
        this.$root.$emit('alert',
          this.$t('warning.error!').toString(),
          this.$t('warning.wrong-mimeType', { mimeTypes: this.$t('warning.video-types').toString() }).toString(),
          true)
        reject(Error('wrong mime-type'))
      }

      const fileUri = fileUploader.checkFileSize(file, maxFileSize)

      if (fileUri === undefined) {
        this.$root.$emit(
          'alert',
          this.$t('warning.error!').toString(),
          this.$t('warning.the-file-is-too-big', {
            fileName: file.name,
            maxFileSizeInMB: maxFileSize
          }),
          true
        )
        reject(Error('too big'))
      } else {
        const video = document.createElement('video')
        video.src = fileUri
        video.load()

        let base64ImageData: string

        video.addEventListener('loadeddata', async function () {
          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')

          if (context !== null) {
            const [w, h] = [video.videoWidth, video.videoHeight]
            canvas.width = w
            canvas.height = h

            video.currentTime = 0

            video.addEventListener('seeked', function () {
              context.drawImage(video, 0, 0, w, h)
              base64ImageData = canvas.toDataURL('image/jpeg')

              const fileToUpload = {
                ...file,
                file: fileUri,
                fileForUpload: file,
                url: file.name,
                uri: file.name,
                documentType: 'VIDEO',
                base64ImageData: base64ImageData
              }
              resolve(fileToUpload)
            })
          }
        })
      }
    }) as Promise<MediaFile>
  }

  clearInput (event: Event): void {
    this.$root.$emit('dismiss-alert')
    this.$emit('removeMedia', this.id)
    this.setBackgroundImage()
    this.$nextTick(() => {
      this.showDelete = false
      this.fileToUpload = {}
      this.fileInput = document.getElementById(this.id)
      if (this.fileInputField) this.fileInputField.value = ''
      this.setBackgroundImage()
    })
  }

  mounted (): void {
    this.fileInput = document.getElementById(this.id)
    this.fileInputField = document.getElementById(this.inputFieldId)

    window.addEventListener('dragover', (event) => {
      event.preventDefault()
    }, false)

    window.addEventListener('drop', (event) => {
      event.preventDefault()
    }, false)
  }

  beforeDestroy (): void {
    window.removeEventListener('dragover', (event) => {
      event.preventDefault()
    }, false)

    window.removeEventListener('drop', (event) => {
      event.preventDefault()
    }, false)
  }
}
