


























































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import MetaMenuButton from './MetaMenuButton.vue'
import breakPoints from '@/common/breakpoints'
import MetaMenuChangeLanguage from './MetaMenuChangeLanguage.vue'
import MetaMenuLogin from '@/components/menus/meta-menu/MetaMenuLogin.vue'
import { Action, Getter } from 'vuex-class'
import MetaMenuSearch from '@/components/menus/meta-menu/MetaMenuSearch.vue'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'

@Component({
  components: { ConfirmationModal, MetaMenuSearch, MetaMenuLogin, MetaMenuChangeLanguage, MetaMenuButton }
})
/*
@group MENUS
The meta menu is displayed on the top right and is used for login/logout, language selection and the global search
*/
export default class MetaMenu extends Vue {
  @Getter('isLoggedIn') isLoggedIn: boolean

  @Action('logout')
  public LOGOUT: () => Promise<boolean>

  // To show only the language switcher. This is used if the system backend is not reachable
  // - the user can switch the language of the according alert but not login
  @Prop()
  onlyLanguage: boolean

  clicked: HTMLElement | null = null
  menuButtons: HTMLElement[] = new Array(3)
  buttonIds = ['login', 'language', 'search', 'logout']
  icons = ['vpn_key', 'language', 'search', 'power_settings_new']
  showText = false

  openMenu (id: string): void {
    if (this.clicked !== null) {
      this.clicked.classList.remove('clicked')
    }

    this.clicked = document.getElementById(id)

    if (this.clicked !== null) {
      this.clicked.classList.add('clicked')
    }
  }

  goToSearch (): void {
    window.open('https://www.hszg.de/suche.html', '_blank')
  }

  logout (): void {
    // TODO show warning if backend-logout failed
    this.LOGOUT().finally(() => {
      this.$router.push({ name: 'home' })
    })
  }

  public closeMenu (): void {
    if (this.clicked !== null) {
      this.clicked.classList.remove('clicked')
      this.clicked = null
    }
  }

  iconsOnly (): boolean {
    this.showText = document.body.offsetWidth > breakPoints.phone
    return this.showText
  }

  mounted (): void {
    this.buttonIds.forEach((id: string, index: number) => {
      const el: HTMLElement | null = document.getElementById(id)
      if (el !== null) {
        this.menuButtons[index] = el
      }
    })
    this.$nextTick(
      function () {
        window.addEventListener('resize', () => {
          this.iconsOnly()
        })
      }
    )

    this.iconsOnly()
  }

  updated (): void {
    this.iconsOnly()
  }

  beforeDestroy (): void {
    window.removeEventListener('resize', () => {
      this.iconsOnly()
    })
  }
}
