



























import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
/*
A wrapper component for form groups, used in the BaseForm component
@group FORMS
 */
export default class CustomFormGroup extends Vue {
  // unique identifier
  @Prop()
  id: string

  // the label for the input
  @Prop()
  label: string

  // an object for css classes of the form group
  @Prop()
  classObject: never

  // disable padding
  @Prop()
  noPadding: boolean

  // if its writable (mostly used in combination with role rights)
  @Prop({ required: true })
  writeable: boolean

  // if its readonly (mostly used in combination with role rights)
  @Prop({ required: true })
  readonly: boolean

  // the state of the input, used for the invalid feedback
  @Prop({ default: false })
  state: boolean

  // if the input is required
  @Prop({ default: false })
  required: boolean

  // the column width of labels on a small resolution device
  @Prop({ default: 12 })
  labelSM: number

  // the column width of labels on a medium resolution device
  @Prop({ default: 4 })
  labelMD: number

  setSoftHyphensInLabels (label: string): string {
    return label.replace(/#shy#/g, '&shy;')
  }
}
