import { VuexModule, Module, Action } from 'vuex-module-decorators'
import { DashboardService } from '@/common/api.service'
import {
  GET_DASHBOARD_WELCOME,
  GET_DASHBOARD_PROFILE_PICTURE,
  GET_DASHBOARD_EVENTS,
  GET_DASHBOARD_NEWS_NEW_OWN_EVENTS,
  GET_DASHBOARD_COMPETENCES,
  GET_DASHBOARD_KODE_STATUS,
  GET_DASHBOARD_COMPANY_PROGRESS,
  GET_DASHBOARD_JOB_ADS,
  GET_DASHBOARD_MENTORING_PROGRESS,
  GET_DASHBOARD_NEWS_NEW_JOB_ADS,
  GET_DASHBOARD_NEWS_NEW_COMPETENCE_METERINGS,
  GET_DASHBOARD_NEW_MESSAGES,
  GET_DASHBOARD_KODE_APPLICATIONS,
  GET_DASHBOARD_OPEN_INQUIRIES,
  GET_DASHBOARD_OWN_OPEN_MENTOR_REQUESTS,
  GET_DASHBOARD_OWN_OPEN_INQUIRIES, GET_DASHBOARD_OPEN_ACTIVATION_REQUESTS
} from '@/store/dashboard/actions'
import {
  DashboardEventsType,
  DashboardMentoringType,
  DashboardNewContent,
  DashboardOpenActivationRequestsType,
  DashboardOpenInquiriesType,
  DashboardOwnOpenInquiriesType,
  DashboardOwnOpenMentorRequestsType,
  DashboardWelcomeType
} from '@/store/dashboard/types'
import { FileDto } from '@/store/types'
import { Event } from '@/store/event/types'
import { CompetenceImprovement } from '@/store/kode/types'
import { JobAdType } from '@/store/jobAd/types'
import { CompanyProfile } from '@/store/company/types'
import { Messages } from '@/store/user/types'

@Module
class DashboardState extends VuexModule {
  @Action({ rawError: true })
  public [GET_DASHBOARD_WELCOME] (): Promise<DashboardWelcomeType> {
    return DashboardService.getDashboardWelcome().then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [GET_DASHBOARD_PROFILE_PICTURE] (profileType: string): Promise<FileDto> {
    return DashboardService.getDashboardProfilePicture(profileType).then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [GET_DASHBOARD_EVENTS] (): Promise<DashboardEventsType> {
    return DashboardService.getDashboardEvents().then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [GET_DASHBOARD_NEWS_NEW_OWN_EVENTS] (): Promise<Array<Event>> {
    return DashboardService.getDashboardNewsNewOwnEvents().then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [GET_DASHBOARD_COMPETENCES] (): Promise<CompetenceImprovement> {
    return DashboardService.getDashboardCompetences().then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [GET_DASHBOARD_KODE_STATUS] (): Promise<string> {
    return DashboardService.getDashboardKodeStatus().then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [GET_DASHBOARD_COMPANY_PROGRESS] (): Promise<CompanyProfile> {
    return DashboardService.getDashboardCompanyProgress().then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [GET_DASHBOARD_JOB_ADS] (): Promise<Array<JobAdType>> {
    return DashboardService.getDashboardJobAds().then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [GET_DASHBOARD_MENTORING_PROGRESS] (): Promise<DashboardMentoringType> {
    return DashboardService.getDashboardMentoringProgress().then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [GET_DASHBOARD_NEWS_NEW_JOB_ADS] (): Promise<DashboardNewContent> {
    return DashboardService.getDashboardNewJobAds().then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [GET_DASHBOARD_NEWS_NEW_COMPETENCE_METERINGS] (): Promise<DashboardNewContent> {
    return DashboardService.getDashboardNewsNewCompetenceMeterings().then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [GET_DASHBOARD_NEW_MESSAGES] (): Promise<Array<Messages>> {
    return DashboardService.getDashboardNewMessages().then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [GET_DASHBOARD_KODE_APPLICATIONS] (): Promise<DashboardNewContent> {
    return DashboardService.getDashboardKodeApplications().then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [GET_DASHBOARD_OPEN_INQUIRIES] (): Promise<DashboardOpenInquiriesType> {
    return DashboardService.getDashboardOpenInquiries().then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [GET_DASHBOARD_OWN_OPEN_MENTOR_REQUESTS] (): Promise<DashboardOwnOpenMentorRequestsType> {
    return DashboardService.getDashboardOwnOpenMentorRequests().then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [GET_DASHBOARD_OWN_OPEN_INQUIRIES] (): Promise<DashboardOwnOpenInquiriesType> {
    return DashboardService.getDashboardOwnOpenInquiries().then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [GET_DASHBOARD_OPEN_ACTIVATION_REQUESTS] (): Promise<DashboardOpenActivationRequestsType> {
    return DashboardService.getDashboardOpenActivationRequests().then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }
}

export default DashboardState
