import moment from 'moment'

export const getDateFormatBasedOnLocale = (locale: string, showTime = false): string => {
  switch (locale) {
    case 'de': {
      return showTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'
    }
    case 'en': {
      return showTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'
    }

    default: {
      return showTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'
    }
  }
}

export const localFormat = (date: string | Date | undefined | null, locale: string, withTime = false): string => {
  if (!date) {
    return ''
  }
  let format
  switch (locale) {
    case 'de': {
      format = withTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'
      break
    }
    case 'en': {
      format = withTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'
      break
    }

    default: {
      format = 'DD.MM.YYYY'
    }
  }
  return moment(date).format(format)
}

export const parseDateTime = (date: string | Date | undefined | null, withoutTime = false): string => {
  if (!date) {
    return ''
  }
  return withoutTime ? moment(date).format('YYYY-MM-DD') : moment(date).format('YYYY-MM-DDTHH:mm:ss')
}

export const parseTime = (date: string | Date): string => {
  if (!date) {
    return ''
  }
  return moment(date).format('HH:mm')
}

export const getLanguageObjectForDatePicker = (locale: string): object => {
  switch (locale) {
    case 'de': {
      return require('vue2-datepicker/locale/de.js')
    }
    case 'en': {
      return require('vue2-datepicker/locale/en.js')
    }
    default: {
      return require('vue2-datepicker/locale/de.js')
    }
  }
}

export const isDateBefore = (dateToCheck: Date, compareDate?: Date): boolean => {
  let compareTo = compareDate
  if (compareDate === undefined) {
    compareTo = new Date()
  }
  return !moment(dateToCheck).isBefore(compareTo)
}
