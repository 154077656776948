import { VuexModule, Module, Action } from 'vuex-module-decorators'
import { AdminService } from '@/common/api.service'
import { GET_OVERVIEW } from '@/store/adminPanel/actions.type'
import { Embedded, PanelModel } from '@/store/adminPanel/types'

@Module
class AdminPanelModule extends VuexModule {
  @Action({ rawError: true })
  public [GET_OVERVIEW] (): Promise<Embedded | undefined> {
    return AdminService.getOverview().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }
}

export default AdminPanelModule
