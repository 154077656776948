


















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
/*
A wrapper button with an icon and a slot for the icon description.
 */
export default class ClickableIcon extends Vue {
  // the icon string from google material icons
  @Prop()
  icon: string

  // a parameter for the emitted click event - usable for a possible toggle target
  @Prop({ default: '' })
  toggleTarget: string

  // the value for the button
  @Prop({ default: '' })
  value: string

  // a description string for the icon
  @Prop({ default: '' })
  text: string

  emitClick (): void {
    if (this.toggleTarget !== '') {
      this.$root.$emit('bv::toggle::collapse', this.toggleTarget)
    }
    this.$emit('click')
  }
}
