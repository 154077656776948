





















































































































































































































































































































































































import { Component, Prop } from 'vue-property-decorator'
import { Form } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'
import Vue from 'vue'
import BaseInput from '@/components/common/BaseInput.vue'
import MediaUploader from '@/components/common/MediaUploader.vue'
import DatePicker from 'vue2-datepicker'
import moment from 'moment'
import AutocompleteInput from '@/components/common/AutocompleteInput.vue'
import CustomFormGroup from '@/components/common/CustomFormGroup.vue'

@Component({
  components: { CustomFormGroup, MediaUploader, BaseInput, DatePicker, AutocompleteInput }
})
/*
The base form is an important component to set up forms in the portal.
To use it, fill the essential props, especially the "fields" prop.
Every field has an input type (e.g. TEXT, TEXTAREA, EMAIL, PASSWORD, SELECT, MULTISELECT, ...).
All types can be found in the formTypes.ts file.
Some types like SELECT also need options to work. Those are the options the user can select.
@group FORMS
 */
export default class BaseForm extends Vue {
  // h3 title
  @Prop()
  title: string

  // if the included from fields are writable by the user (often used in combination with role rights)
  @Prop({ required: true })
  writeable: boolean

  // if the included form fields are readonly by the user (often used in combination with role rights)
  @Prop({ required: true })
  readonly: boolean

  /*
  The main prop - it is used to define the form fields. All fields are defined in an array of the Form-type.
  Example:
  [
    {
      id: 'salutation',
      key: 'salutation',
      type: InputTypes.SELECT,
      label: this.$i18n.t('personal-information.salutation').toString(),
      options: this.salutations
    }
  ]
  */
  @Prop({ required: true })
  fields: Form[]

  // one or two column layout
  @Prop()
  oneColLayout: boolean

  // like a v-model for normal components this is the variable in which the form data is stored
  @Prop()
  model: never

  // Special option for some input types like the autocomplete input and the custom select.
  // When set a delete icon will be placed next to the input.
  @Prop({ default: false })
  deletable: boolean

  // If the delete icon and mechanic will be disabled. Used in combination with the deletable prop.
  @Prop({ default: false })
  deleteDisabled: boolean

  // the column width of labels on a small resolution device
  @Prop()
  labelSM: number

  // the column width of labels on a medium resolution device
  @Prop()
  labelMD: number

  // index for iterating baseforms in list
  @Prop()
  modelIndex: number

  types = InputTypes

  start = moment().format('HH:mm')

  clearMedia (id: string | number): void {
    this.$emit('removeMedia', id)
  }

  /*
    returns false if compDate is a valid/selectable date
   */
  validateDateOptions (date: Date, check: any, fullDay?: boolean): boolean {
    if (check && (check.start || check.end)) {
      // moment(undefined) always returns a valid date = now
      const start = moment(check.start)
      const end = moment(check.end)
      const compDate = moment(date)
      if (!compDate.isValid()) {
        return false
      }
      if (check.start !== undefined && start.isValid()) {
        return fullDay ? start.isAfter(compDate) : start.subtract(1, 'd').isAfter(compDate)
      }

      if (check.end !== undefined && end.isValid()) {
        return fullDay ? end.isBefore(compDate) : end.subtract(1, 'd').isBefore(compDate)
      }
    }

    return false
  }

  dismissAlert (id: string | number): void {
    this.$emit('input', id)
    this.$root.$emit('dismiss-alert')
  }

  addMultiSelect (e: any, id: string | number): void {
    this.dismissAlert(id)
    this.$emit('select', this.model, id)
  }

  watch: {
    model: {
      handler: 'dismissAlert';
      immediate: true;
      deep: true;
    };
  }
}
