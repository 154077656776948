import moment, { Moment } from 'moment'
import { passwordRegex } from '@/common/regex'
import i18n from '@/i18n'
import { KeyValue } from '@/store/commonFormInput/types'
import { getDateFormatBasedOnLocale, parseTime } from '@/common/datePicker'
import { UserServerObject } from '@/store/user/types'
import { User } from '@/store/types'

export const parseURL = (link: string): string => {
  return link.startsWith('http') ? link : 'https://' + link
}

export const parseDateToOutputDate = (date: Date | string | undefined, locale: string, showTime = true): string | undefined => {
  if (date) {
    return moment(date).locale('De').format(getDateFormatBasedOnLocale(locale, showTime))
  }
  return date
}

export const parseDateToLocalDate = (date: Date | string | undefined): string | undefined => {
  if (date) {
    return moment(date).locale('De').format('YYYY-MM-DD')
  }
  return undefined
}

export const parseLocalDateToDate = (date: Date | string | undefined): Date | undefined => {
  if (date) {
    return new Date(date)
  }
  return undefined
}

export const parseBase64ImageString = (imageAsBase64: string): string => {
  return imageAsBase64.replace(/\r\n/g, '')
    .replace('dataimage', 'data:image')
    .replace('base64', 'base64,')
}

export const parseUserServerObjectToUser = (userServerObject: UserServerObject): User => {
  return {
    appUserId: userServerObject.id ? userServerObject.id : '',
    domain: '',
    roles: userServerObject.roles ? userServerObject.roles : [],
    username: userServerObject.username ? userServerObject.username : '',
    rights: userServerObject.rights ? userServerObject.rights : []
  }
}

export const checkPassword = (password: string) => {
  const regex = new RegExp(passwordRegex())
  return regex.test(password)
}

export const compareDate = (date1: string | Date | Moment | undefined, date2: string | Date | Moment | undefined): boolean => {
  return moment(date1).format('YYYY-MM-DD') === moment(date2).format('YYYY-MM-DD')
}

export const isDateBetween = (dateToCheck: Date | string | undefined, start: Date | string | undefined, end: Date | string | undefined): boolean => {
  if (compareDate(start, end)) {
    return compareDate(dateToCheck, end)
  }
  return moment(dateToCheck).isAfter(moment(start)) && moment(dateToCheck).isBefore(moment(end))
}

export const dateIsBeforeOrSame = (dateToCheck: Date, compareDate?: Date): boolean => {
  if (compareDate === undefined) {
    compareDate = new Date()
  }
  compareDate.setDate(compareDate.getDate() + 1)
  return !moment(dateToCheck).isBefore(compareDate)
}

export const getAllDatesBetweenTwoDates = (date1: Date | string, date2: Date | string) => {
  if (!date1 || !date2) {
    return []
  }

  const dates = []

  const currDate = moment(date1).startOf('day')
  const lastDate = moment(date2).startOf('day')

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate())
  }

  return dates
}

export const competenceEnumFromId = (id: string): string => {
  return Object.keys((i18n.t('all-competences')))[Object.values((i18n.t('all-competences'))).findIndex(c => c.id === id)]
}

export const getBaseCompetenceObjectArray = (baseCompetencesStringArray: string[]): KeyValue[] => {
  return baseCompetencesStringArray.map(competence => ({ value: competence, name: i18n.t('kode.base-competences.' + competence + '.long').toString() }))
}

export const getCompetenceObjectArray = (competencesStringArray: string[]): KeyValue[] => {
  return competencesStringArray.map(competence => ({ value: competence, name: i18n.t('all-competences.' + competence + '.title').toString() }))
}

export const getTopicObjectArray = (topicStringArray: string[]): KeyValue[] => {
  return topicStringArray.map(topic => ({ value: topic, name: i18n.t('events.topics.' + topic).toString() }))
}

export const getCompetenceTitleArray = (competencesStringArray: string[]): string[] => {
  return competencesStringArray.map(competence => (i18n.t('all-competences.' + competence + '.title').toString()))
}

export const getBaseCompetenceTitleArray = (competencesStringArray: string[]): string[] => {
  return competencesStringArray.map(competence => i18n.t('kode.base-competences.' + competence + '.long').toString())
}

export const getCompetenceValueAsStringArray = (competencesObjectArray: KeyValue[]): string[] => {
  return competencesObjectArray.map(comp => comp.value) as string[]
}

export const getAllBaseCompetencesAsKeyValue = (): KeyValue[] => {
  return Object.values((i18n.t('kode.base-competences'))).map(content => ({ value: content.short, name: content.long }))
}

export default {
  parseDateToLocalDate,
  parseLocalDateToDate,
  parseBase64ImageString
}

export const compareValues = (key: string, desc = false, isDate = false) => {
  return function innerSort (a: any, b: any) {
    if (!Object.prototype.hasOwnProperty.call(a, key) || !Object.prototype.hasOwnProperty.call(b, key)) return 0
    let varA
    let varB
    if (isDate) {
      varA = new Date(a[key])
      varB = new Date(b[key])
    } else {
      varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key]
      varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key]
    }
    let comparison = 0
    if (varA > varB) comparison = 1
    else if (varA < varB) comparison = -1
    return (desc ? (comparison * -1) : comparison)
  }
}

export const compareByKey = (val1: object, val2: object, key: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  if (val1[key] < val2[key]) {
    return -1
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  if (val1[key] > val2[key]) {
    return 1
  }
  return 0
}

export const sortArray = (array: any, key: string, desc = false, isDate = false) => {
  array.sort(compareValues(key, desc, isDate))
}

export const getTimeRange = (startDate: Date, endDate: Date): string => {
  const from = parseTime(startDate)
  const to = parseTime(endDate)
  return i18n.t('events.time-to', { from, to }).toString()
}

export const isUUID = (s: any) => {
  if (!s || !isNaN(s) || Array.isArray(s) || !moment(s).isValid()) {
    return false
  }
  return s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$')
}

export const isDate = (s: any) => {
  if (!s || Array.isArray(s)) {
    return false
  }
  return s.match('^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}$')
}

// shorten text to desired length at the next space (up to 20 chars away) and add suffix
export const shortenString = (description: string, length: number, suffix?: string) => {
  let nextSpace = description.indexOf(' ', length)
  if (nextSpace > 0) {
    if (nextSpace > (length + 20)) nextSpace = length + 20
    return description.substr(0, nextSpace) + (suffix || '')
  } else {
    if (description.length > length) return description.substr(0, length) + (suffix || '')
    else return description
  }
}
