import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import store from './store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VCalendar from 'v-calendar'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import './custom.scss'
import i18n from './i18n'
import MetaMenu from '@/components/menus/meta-menu/MetaMenu.vue'
import HeaderAndMainMenu from '@/components/header/HeaderAndMainMenu.vue'
import ApiService from '@/common/api.service'
import CustomSelect from '@/components/common/CustomSelect.vue'
import PasswordInput from '@/components/common/PasswordInput.vue'
import EmailInput from '@/components/common/EmailInput.vue'
import PhoneInput from '@/components/common/PhoneInput.vue'
import Component from 'vue-class-component'
import { getDateFormatBasedOnLocale, getLanguageObjectForDatePicker } from '@/common/datePicker'
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import CustomFormGroup from '@/components/common/CustomFormGroup.vue'
import 'moment/locale/de'
import { shortenString } from '@/common/globals'
moment.locale('de')

Vue.config.productionTip = false

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VCalendar)

Vue.use(VueAxios, axios)

ApiService.init()
ApiService.setHeader()

Vue.component('VueSlider', VueSlider)
Vue.component('meta-menu', MetaMenu)
Vue.component('header-and-main-menu', HeaderAndMainMenu)
Vue.component('custom-select', CustomSelect)
Vue.component('custom-form-group', CustomFormGroup)
Vue.component('password-input', PasswordInput)
Vue.component('email-input', EmailInput)
Vue.component('phone-input', PhoneInput)
Vue.component('multiselect', Multiselect)

Object.defineProperty(Vue.prototype, '$locale', {
  get: function () {
    return i18n.locale
  },
  set: function (locale) {
    i18n.locale = locale
  }
})

Vue.prototype.$getDateFormat = getDateFormatBasedOnLocale
Vue.prototype.$getDatePickerTranslation = getLanguageObjectForDatePicker

// import the colors form sass export (see colors.scss for export and colors.d.ts for type)
Vue.prototype.$colors = require('@/assets/styles/colors.scss')

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

// global filter to format date
Vue.filter('moment', function (date: Date): string {
  if (date) {
    return moment(date).format(getDateFormatBasedOnLocale(i18n.locale))
  }
  return ''
})

// shorten text to desired length at the next space (up to 20 chars away) and add suffix
Vue.filter('shorten', function (description: string, length: number, suffix?: string): string {
  return shortenString(description, length, suffix)
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
