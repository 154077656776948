import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { MenuEntry, RootState, RouteRight } from './types'
import UserState from './user/user.module'
import mainMenuEntries from '@/store/common/mainMenu.ts'
import CompanyState from '@/store/company/company.module'
import JobAdState from '@/store/jobAd/jobAd.module'
import EventState from '@/store/event/event.module'
import AdminState from '@/store/adminPanel/adminPanel.module'
import StudentState from '@/store/student/student.module'
import { allRoutes } from '@/router'
import KodeState from '@/store/kode/kode.module'
import EmployeeState from '@/store/employee/employee.module'
import MentorState from '@/store/mentor/mentor.module'
import InquiryState from '@/store/inquiry/inquiry.module'
import DashboardState from '@/store/dashboard/dashboard.module'
import SearchState from '@/store/search/search.module'
import EvaluationState from '@/store/evaluation/evaluation.module'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: {
    currentPage: localStorage.getItem('hszg_page') !== null ? JSON.parse(localStorage.getItem('hszg_page') as string) : [-1],
    showDesktopVersion: false,
    currentScreenWidth: 0,
    isContentLoading: false,
    isPageLoading: true
  },
  modules: {
    UserState,
    StudentState,
    CompanyState,
    JobAdState,
    EmployeeState,
    KodeState,
    EventState,
    MentorState,
    InquiryState,
    DashboardState,
    AdminState,
    SearchState,
    EvaluationState
  },
  getters: {
    getMenuEntries: (state, getters): MenuEntry[] => {
      let rights: string[] = []
      if (getters.userGetter) {
        rights = [...rights, ...getters.userGetter.rights, ...getters.userGetter.roles]
      }

      // use JSON parse/stringify for a deep copy of the array
      const menuEntries = JSON.parse(JSON.stringify(mainMenuEntries))

      // create a helper array with routes and rights
      const routeRights: Array<RouteRight> = []
      allRoutes.forEach(function (r) {
        routeRights.push({ route: r.name, rights: r.meta?.rights })
        if (r.children?.length) {
          r.children.forEach(function (c) {
            routeRights.push({ route: c.name, rights: c.meta?.rights })
          })
        }
      })

      // add all route-rights to the menu entries
      menuEntries.forEach(function (e: MenuEntry) {
        if (e.children.length) {
          e.children.forEach(function (c) {
            routeRights.forEach(function (r) {
              if (r.rights && r.route === c.route) c.rights = [...r.rights]
            })
          })
        } else {
          routeRights.forEach(function (r) {
            if (r.rights && r.route === e.route) e.rights = [...r.rights]
          })
        }
      })

      // filter all the menu entries according to the users rights
      return menuEntries.filter(function (e: MenuEntry) {
        if (e.children.length) {
          e.children = e.children.filter(function (c) {
            return c.rights ? c.rights.filter(r => rights.includes(r)).length : true
          })
          return e.children.length > 0
        } else {
          return e.rights ? e.rights.filter(r => rights.includes(r)).length : true
        }
      })
    },
    getCurrentPage: (state): number[] => {
      return state.currentPage
    }
  },
  mutations: {
    setCurrentPage: (state, newPage: number[]): void => {
      state.currentPage = newPage
      localStorage.setItem('hszg_page', JSON.stringify(newPage))
    },

    setShowDesktopVersion: (state, showDesktopVersion: boolean): void => {
      state.showDesktopVersion = showDesktopVersion
    },

    setCurrentScreenWidth: (state, width: number): void => {
      state.currentScreenWidth = width
    },

    setContentLoading: (state, isLoading: boolean): void => {
      state.isContentLoading = isLoading
    },

    setPageLoading: (state, isLoading: boolean): void => {
      state.isPageLoading = isLoading
    }
  }
}

export default new Vuex.Store<RootState>(store)
