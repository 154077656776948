

































import { Component, Vue } from 'vue-property-decorator'
import ClickableIcon from '@/components/common/ClickableIcon.vue'
import i18n from '@/i18n'
@Component({
  components: { ClickableIcon }
})
/*
@group MENUS
The language switcher for the meta menu
*/
export default class MataMenuChangeLanguage extends Vue {
  languages: string[] = []

  changeLanguage (clickedElement: HTMLElement): void {
    const oldLanguage = this.$root.$i18n.locale
    const oldLanguageElement = document.getElementById(oldLanguage)
    if (oldLanguageElement !== null) {
      oldLanguageElement.classList.remove('current')
    }

    this.$root.$i18n.locale = clickedElement.id
    clickedElement.classList.add('current')

    // fix url according to locale selection
    let url = this.$route.path
    const locale = i18n.locale === process.env.VUE_APP_I18N_FALLBACK_LOCALE ? '' : '/' + i18n.locale
    if (this.languages.some(lang => lang === url.substring(1, url.indexOf('/', 1)))) {
      url = url.substring(this.$route.path.indexOf('/', 1))
    }
    history.pushState(
      {},
      '',
      locale + url
    )
    this.$emit('close')
  }

  mounted (): void {
    this.languages = this.$root.$i18n.availableLocales
    const lang = this.$root.$i18n.locale
    const selectedLanguage: HTMLElement | null = document.getElementById(lang)
    if (selectedLanguage !== null) {
      selectedLanguage.classList.add('current')
    }
  }
}
