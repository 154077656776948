import {
  GET_USER_INFO,
  LOGOUT,
  RESET_PASSWORD,
  UPDATE_LOGGED_IN_USER,
  GET_CONTACT,
  GET_JOB_FAMILIES,
  UPDATE_STUDENT_STATE,
  LOGIN,
  REGISTER,
  CONFIRM_REGISTRATION,
  REQUEST_RESET_PASSWORD,
  REFRESH,
  SET_AUTH,
  ACCEPT_DATA_PROTECTION_DECLARATION,
  GET_CURRENT_DECLARATION_CONTENT,
  GET_CURRENT_TERMS_OF_SERVICE_CONTENT,
  ACCEPT_TERMS_OF_SERVICE, GET_ACCOUNT_SETTINGS, SET_ACCOUNT_SETTINGS, SET_PASSWORD, AUTH_WITH_REFRESH,
  GET_ALL_SYNONYMS, DELETE_PHOTO, SAVE_PHOTO, GET_PHOTO,
  GET_IS_ALIVE,
  SEND_CONTACT_FORM,
  LOAD_IMAGE,
  GET_ALL_FAQ,
  GET_MESSAGES, DELETE_ACCOUNT, GET_CURRENT_IMPRINT_CONTENT, SET_ACTIVATION_STATUS_OWN_PROFILE
} from '@/store/user/actions.type'
import {
  MU_DELETE_USER_TOKEN,
  MU_GET_USER_INFO,
  MU_SET_USER_TOKEN, MU_UPDATE_STUDENT_STATE, MU_USER_SET_PHOTO_FILENAME,
  USER_LOG_IN,
  USER_LOG_OUT
} from '@/store/user/mutation.type'
import {
  FileDto,
  FileRequest,
  GenericRequest,
  Qualification,
  SimpleServerResponse,
  User,
  UserProgressState
} from '@/store/types'
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import domain from '@/store/common/userDomain'
import ApiService, { CompanyService, UserService } from '@/common/api.service'
import {
  AccountSettings, ChangePassword,
  ConfirmRegistrationCredentials, FAQDto,
  JobFamily,
  LoginCredentials, Messages, RegistrationCredentials,
  ResetPasswordCredentials,
  UserServerObject
} from '@/store/user/types'
import { ContactFormContent } from '@/store/commonFormInput/types'

@Module({
  name: 'UserState'
})
class UserState extends VuexModule {
  photoFileName: string | undefined = undefined

  get photoFileNameGetter (): string | undefined {
    return this.photoFileName
  }

  user: User | undefined = localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user') as string) : undefined
  currentToken: string | undefined = this.bearerTokenFromStorage
  refreshToken: string | undefined = localStorage.getItem('refresh') !== null ? JSON.parse(localStorage.getItem('refresh') as string) : undefined
  studentProgressState: UserProgressState = {
    jobTarget: false,
    strengthWeakness: false,
    competenceImprovement: false,
    receiveSupport: false,
    useSupport: false,
    receiveInquiry: false,
    mentor: false
  }

  companyProgressState: UserProgressState = {
    mentor: false
  }

  staffProgressState: UserProgressState = {
    mentor: false
  }

  get userGetter (): User | undefined {
    return this.user
  }

  get tokenGetter (): string | undefined {
    return this.currentToken
  }

  get refreshTokenGetter (): string | undefined {
    return this.refreshToken
  }

  get jwtDataGetter (): any | undefined {
    return this.currentToken ? JSON.parse(atob(this.currentToken.split('.')[1])) : undefined
  }

  get userStateGetter (): UserProgressState {
    if (this.isStudentLoggedIn) {
      return this.studentProgressState
    }

    if (this.isCompanyLoggedIn) {
      return this.companyProgressState
    }

    if (this.isStaffLoggedIn) {
      return this.staffProgressState
    }

    return { mentor: false }
  }

  get isCompanyLoggedIn (): boolean {
    return this.user !== undefined && this.user.domain === domain.COMPANY
  }

  get isStudentLoggedIn (): boolean {
    return this.user !== undefined && this.user.domain === domain.STUDENT
  }

  get isStaffLoggedIn (): boolean {
    return this.user !== undefined && this.user.domain === domain.EMPLOYEE
  }

  get isLoggedIn (): boolean | undefined {
    return this.user && this.user.domain !== null && Object.values(domain).includes(this.user.domain)
  }

  get bearerTokenFromStorage (): string | undefined {
    return localStorage.getItem('bearer') !== null ? JSON.parse(localStorage.getItem('bearer') as string) : undefined
  }

  @Action
  public [SET_AUTH] (token: string | undefined): Promise<void> {
    if (token === undefined) {
      token = this.bearerTokenFromStorage
    }

    if (token === undefined) {
      throw Promise.reject(new Error('token undefined'))
    }
    ApiService.setAuth(token)
    return Promise.resolve()
  }

  @Action({ rawError: true })
  public [REGISTER] (params: RegistrationCredentials): Promise<SimpleServerResponse> {
    return UserService.register(params).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [CONFIRM_REGISTRATION] (params: ConfirmRegistrationCredentials): Promise<SimpleServerResponse> {
    return UserService.confirmRegistration(params).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [LOGIN] (params: LoginCredentials): Promise<UserServerObject> {
    return UserService.login(params).then(data => {
      // the next line is only temporary - delete when all rights are set
      // also in the router delete the "role-rights"
      if (data.rights && data.roles) data.rights = [data.roles[0], ...data.rights]

      this.context.commit(USER_LOG_IN, data)
      this.context.commit(MU_SET_USER_TOKEN, data)
      return (data)
    })
  }

  @Action({ rawError: true })
  public [REFRESH] (): Promise<UserServerObject> {
    if (this.refreshTokenGetter !== undefined) {
      ApiService.setAuth(this.bearerTokenFromStorage)
      return UserService.refresh(this.refreshTokenGetter).then(data => {
        this.context.commit(MU_SET_USER_TOKEN, data.data)
        return data.data
      }, error => {
        throw error.data
      })
    } else {
      throw new Error('no refresh token found')
    }
  }

  @Action({ rawError: true })
  public [AUTH_WITH_REFRESH] (token: string): Promise<UserServerObject> {
    return UserService.refresh(token).then(data => {
      this.context.commit(MU_SET_USER_TOKEN, data.data)
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action
  public [UPDATE_LOGGED_IN_USER] (newValue: User): string {
    const startPage = 'dashboard'

    if (newValue.roles.includes('ALUMNI') || newValue.roles.includes('STUDENT')) {
      newValue.domain = 'STUDENT'
    }

    if (newValue.roles.includes('COMPANY')) {
      newValue.domain = 'COMPANY'
    }

    if (newValue.roles.includes('EMPLOYEE')) {
      newValue.domain = 'EMPLOYEE'
    }

    this.context.commit(USER_LOG_IN, newValue)
    return startPage
  }

  @Action({ rawError: true })
  public [LOGOUT] (): Promise<SimpleServerResponse> {
    return UserService.logout().then(data => {
      this.context.commit(USER_LOG_OUT)
      this.context.commit(MU_DELETE_USER_TOKEN)
      return data.data
    }, (error) => {
      this.context.commit(USER_LOG_OUT)
      this.context.commit(MU_DELETE_USER_TOKEN)
      throw error
    })
  }

  @Action
  public [GET_CURRENT_DECLARATION_CONTENT] (locale: string): Promise<SimpleServerResponse> {
    return UserService.getCurrentDeclarationContent(locale).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action
  public [ACCEPT_DATA_PROTECTION_DECLARATION] (): Promise<SimpleServerResponse> {
    return UserService.acceptDataProtectionDeclaration().then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action
  public [GET_CURRENT_TERMS_OF_SERVICE_CONTENT] (locale: string): Promise<SimpleServerResponse> {
    return UserService.getCurrentTermsOfServiceContent(locale).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action
  public [ACCEPT_TERMS_OF_SERVICE] (): Promise<SimpleServerResponse> {
    return UserService.acceptTermsOfService().then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action
  public [GET_USER_INFO] (): void {
    /*
      return UserService.getUserRole(this.getters.token).then(({data}) => {
              context.commit(MU_GET_USER_INFO, data)
              return data
          }
      )
      */
  }

  @Action
  public [UPDATE_STUDENT_STATE] (updatedState: UserProgressState): void {
    this.context.commit(MU_UPDATE_STUDENT_STATE, updatedState)
  }

  @Action({ rawError: true })
  public [REQUEST_RESET_PASSWORD] (username: string): Promise<SimpleServerResponse> {
    return UserService.requestPasswordReset(username).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [RESET_PASSWORD] (params: ResetPasswordCredentials): Promise<SimpleServerResponse> {
    return UserService.resetPassword(params).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action
  public [GET_CONTACT] (contactId: string): object {
    return UserService.getContact(contactId)
  }

  @Action
  public [GET_JOB_FAMILIES] (): JobFamily[] {
    return UserService.getJobFamilies()
  }

  @Action
  public [GET_ACCOUNT_SETTINGS] (): Promise<SimpleServerResponse> {
    return UserService.getAccountSettings().then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action
  public [SET_ACCOUNT_SETTINGS] (params: AccountSettings): Promise<SimpleServerResponse> {
    return UserService.setAccountSettings(params).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [SET_PASSWORD] (params: ChangePassword): Promise<SimpleServerResponse> {
    return UserService.setPassword(params).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_ALL_SYNONYMS] (): Promise<Array<Qualification>> {
    return UserService.getAllSynonyms().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_IS_ALIVE] (): Promise<SimpleServerResponse> {
    return UserService.getIsAlive().then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_PHOTO] (role: string): Promise<FileDto> {
    return UserService.getProfilePhoto(role).then(data => {
      this.context.commit(MU_USER_SET_PHOTO_FILENAME, data.filename)
      return data
    }).catch(error => {
      this.context.commit(MU_USER_SET_PHOTO_FILENAME, undefined)
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [LOAD_IMAGE] (request: FileRequest): Promise<FileDto> {
    const service = request.id ? UserService.getImage(request) : UserService.getProfilePhoto(this.user?.domain || '')
    return service.then(data => {
      return data
    }).catch(error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [SAVE_PHOTO] (fileRequest: FileRequest): Promise<SimpleServerResponse> {
    return UserService.saveProfilePhoto(fileRequest).then(data => {
      if (fileRequest.file !== undefined) {
        this.context.commit(MU_USER_SET_PHOTO_FILENAME, fileRequest.file.filename)
      }
      return data.data
    }).catch(error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [DELETE_PHOTO] (role: string): Promise<SimpleServerResponse> {
    return UserService.deleteProfilePhoto(role).then(data => {
      this.context.commit(MU_USER_SET_PHOTO_FILENAME, undefined)
      return data.data
    }).catch(error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [SEND_CONTACT_FORM] (content: ContactFormContent): Promise<SimpleServerResponse> {
    return UserService.sendContactForm(content).then(data => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_ALL_FAQ] (locale: string): Promise<Array<FAQDto>> {
    return UserService.getAllFAQ(locale).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_MESSAGES] (): Promise<Array<Messages>> {
    return UserService.getMessages().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action
  public [DELETE_ACCOUNT] (): Promise<SimpleServerResponse> {
    return UserService.deleteAccount().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action
  public [GET_CURRENT_IMPRINT_CONTENT] (locale: string): Promise<SimpleServerResponse> {
    return UserService.getCurrentImprintContent(locale).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [SET_ACTIVATION_STATUS_OWN_PROFILE] (data: GenericRequest): Promise<SimpleServerResponse> {
    return UserService.setActivationStatusForOwnProfile(data).then(data => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  }

  @Mutation
  public [USER_LOG_IN] (user: User): void {
    this.user = { ...this.user, ...user, rights: [...user.rights] }
    localStorage.setItem('user', JSON.stringify(user))
  }

  @Mutation
  public [USER_LOG_OUT] (): void {
    this.user = undefined
    localStorage.removeItem('user')
    localStorage.removeItem('hszg_page')
  }

  @Mutation
  public [MU_SET_USER_TOKEN] (data: UserServerObject): void {
    this.currentToken = data.bearer
    this.refreshToken = data.refreshToken
    ApiService.setAuth(data.bearer)
    localStorage.setItem('bearer', JSON.stringify(data.bearer))
    localStorage.setItem('refresh', JSON.stringify(data.refreshToken))
  }

  @Mutation
  public [MU_DELETE_USER_TOKEN] (): void {
    this.currentToken = undefined
    this.refreshToken = undefined
    ApiService.deleteAuth()
    localStorage.removeItem('bearer')
    localStorage.removeItem('refresh')
  }

  @Mutation
  public [MU_GET_USER_INFO] (data: User): void {
    this.user = data
  }

  @Mutation
  public [MU_UPDATE_STUDENT_STATE] (data: UserProgressState): void {
    if (this.isStudentLoggedIn) {
      this.studentProgressState = data
    }

    if (this.isCompanyLoggedIn) {
      this.companyProgressState = data
    }

    if (this.isStaffLoggedIn) {
      this.staffProgressState = data
    }
  }

  @Mutation
  public [MU_USER_SET_PHOTO_FILENAME] (filename: string | undefined): void {
    this.photoFileName = filename
  }
}

export default UserState
