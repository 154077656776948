




















































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { KeyValue } from '@/store/commonFormInput/types'

@Component
/*
A wrapper component for the select input. Adds optional elements like delete button and invalid feedback
@group INPUT ELEMENTS
 */
export default class CustomSelect extends Vue {
  // unique identifier
  @Prop({ required: true })
  id: string

  // the options for the select input
  @Prop({ required: true })
  options: KeyValue[]

  // the model used for the data
  @Prop({ required: true })
  value: string

  // if the input is disabled
  @Prop({ required: false })
  disabled: boolean

  // if the input is required
  @Prop({ required: false })
  required: boolean

  // if a delete button should be shown
  @Prop({ default: false })
  deletable: boolean

  // the state of the input, used for the invalid feedback
  @Prop({ required: false, default: true })
  state: boolean

  // if the select input allows a multiselect
  @Prop({ required: false })
  multiple: boolean

  @Watch('value')
  onValueChange (newValue: string): void {
    this.internalModel = newValue
  }

  @Watch('state')
  onStateChange (newValue: boolean): void {
    this.validationState = newValue
  }

  internalModel = ''

  validationState: boolean | null = null

  emitInput (event: HTMLInputElement): void {
    this.$root.$emit('dismiss-alert')
    if (!(this.value === null && event.value === '')) {
      this.$emit('input', event.value, this.value)
      this.validationState = true
    }
  }

  mounted (): void {
    this.internalModel = this.value
    this.validationState = this.state
    const selectElement = document.getElementById(this.id)
    if (selectElement !== null) {
      selectElement.style.backgroundPosition = 'right ' + 1.5 * 29 + 'px center'
    }
  }
}
