import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import {
  DELETE_META_PROFILE,
  GET_ALL_META_PROFILES,
  SAVE_META_PROFILE,
  TRANSFORM_COMPETENCE_CORRIDORS_FOR_BACKEND,
  TRANSFORM_COMPETENCE_CORRIDORS_FOR_FRONTEND,
  GET_JOB_FINDER_RESULTS,
  GET_KODE_BRIDGE_RESULT,
  SAVE_KODE_EVALUATION_VISIBILITY,
  GET_KODE_EVALUATION_VISIBILITY,
  DELETE_KODE_EVALUATION_SESSION,
  SAVE_KODE_EVALUATION_SESSION,
  GET_KODE_EVALUATION_SESSION,
  GET_ALL_KODE_EVALUATION_SESSION,
  GET_COMPETENCES_TO_IMPROVE_OWN,
  SAVE_COMPETENCES_TO_IMPROVE_OWN,
  GET_ALL_COMPETENCES,
  SET_EVALUATION_DETAILS_SENT,
  GET_WAITING_FOR_KODE_DETAILS
} from '@/store/kode/actions'
import { KodeService } from '@/common/api.service'
import {
  CompetenceCorridorForFrontend,
  CompetenceCorridorServerObject,
  KodeEvaluationSession,
  MetaProfile,
  TalentPdfAllowance,
  CompetenceImprovement, JobFinderType, KodeEvaluationDetailsSentType, KodeEvaluationTalentData
} from '@/store/kode/types'
import { SimpleServerResponse } from '@/store/types'
import { MU_SET_META_PROFILES } from '@/store/kode/mutations'

@Module
class KodeState extends VuexModule {
  metaProfiles: Array<MetaProfile> = new Array<MetaProfile>()

  get metaProfilesGetter (): Array<MetaProfile> {
    return this.metaProfiles
  }

  @Action({ rawError: true })
  public [GET_ALL_COMPETENCES] (): Promise<Array<string>> {
    return KodeService.getAllCompetences().then(data => {
      return data.map(competence => competence.name)
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_ALL_META_PROFILES] (): Promise<Array<MetaProfile>> {
    return KodeService.getAllMetaProfiles().then(data => {
      this.context.commit(MU_SET_META_PROFILES, data)
      return data
    }, error => {
      throw error.data
    })
  }

  @Action
  public [TRANSFORM_COMPETENCE_CORRIDORS_FOR_FRONTEND] (corridorsFromServer: Array<CompetenceCorridorServerObject>): Promise<Array<CompetenceCorridorForFrontend>> {
    const result = new Array<CompetenceCorridorForFrontend>()

    corridorsFromServer.forEach(corridor => {
      const frontEndCorridor: CompetenceCorridorForFrontend = {
        competence: corridor.competence,
        range: [corridor.corridorMin, corridor.corridorMax],
        id: corridor.id
      }

      result.push(frontEndCorridor)
    })

    return Promise.resolve(result)
  }

  @Action
  public [TRANSFORM_COMPETENCE_CORRIDORS_FOR_BACKEND] (corridorsFromFrontend: Array<CompetenceCorridorForFrontend>): Promise<Array<CompetenceCorridorServerObject>> {
    const result = new Array<CompetenceCorridorServerObject>()

    corridorsFromFrontend.forEach(corridor => {
      const backendCorridor: CompetenceCorridorServerObject = {
        competence: corridor.competence,
        corridorMin: corridor.range[0],
        corridorMax: corridor.range[1],
        id: corridor.id
      }

      result.push(backendCorridor)
    })

    return Promise.resolve(result)
  }

  @Action({ rawError: true })
  public [SAVE_META_PROFILE] (metaProfile: MetaProfile): Promise<SimpleServerResponse> {
    return KodeService.saveMetaProfile(metaProfile).then(data => {
      return data
    }, error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [DELETE_META_PROFILE] (id: string): Promise<SimpleServerResponse> {
    return KodeService.deleteMetaProfile(id).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_JOB_FINDER_RESULTS] (): Promise<Array<JobFinderType>> {
    return KodeService.getJobFinderResults()
  }

  @Action({ rawError: true })
  public [GET_KODE_BRIDGE_RESULT] (targetCompetenceProfileId: string): Promise<SimpleServerResponse> {
    return KodeService.getKodeBridgeResult(targetCompetenceProfileId).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_COMPETENCES_TO_IMPROVE_OWN] (): Promise<SimpleServerResponse> {
    return KodeService.getCompetencesToImproveOwn().then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [SAVE_COMPETENCES_TO_IMPROVE_OWN] (improvement: CompetenceImprovement): Promise<SimpleServerResponse> {
    return KodeService.saveCompetencesToImproveOwn(improvement).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_ALL_KODE_EVALUATION_SESSION] (): Promise<Array<KodeEvaluationSession>> {
    return KodeService.getKodeEvaluationSessions().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_KODE_EVALUATION_SESSION] (sessionId: string): Promise<KodeEvaluationSession> {
    return KodeService.getKodeEvaluationSession(sessionId).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [SAVE_KODE_EVALUATION_SESSION] (session: KodeEvaluationSession): Promise<string> {
    return KodeService.saveKodeEvaluationSession(session).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [DELETE_KODE_EVALUATION_SESSION] (sessionId: string): Promise<SimpleServerResponse> {
    return KodeService.deleteKodeEvaluationSession(sessionId).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_KODE_EVALUATION_VISIBILITY] (talentIdList: string[]): Promise<Array<TalentPdfAllowance>> {
    return KodeService.getKodeEvaluationVisibility(talentIdList).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [SAVE_KODE_EVALUATION_VISIBILITY] (evaluationVisibilityList: TalentPdfAllowance[]): Promise<SimpleServerResponse> {
    return KodeService.saveKodeEvaluationVisibility(evaluationVisibilityList).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [SET_EVALUATION_DETAILS_SENT] (params: KodeEvaluationDetailsSentType): Promise<SimpleServerResponse> {
    return KodeService.setEvaluationDetailsSend(params).then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [GET_WAITING_FOR_KODE_DETAILS] (): Promise<Array<KodeEvaluationTalentData>> {
    return KodeService.getWaitingForKodeDetails().then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Mutation
  public [MU_SET_META_PROFILES] (newProfiles: Array<MetaProfile>): void {
    this.metaProfiles = newProfiles
  }
}
export default KodeState
