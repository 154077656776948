






























































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import breakPoints from '@/common/breakpoints.ts'
import { Action, Mutation } from 'vuex-class'
import FooterMenu from '@/components/menus/FooterMenu.vue'
import MetaMenu from '@/components/menus/meta-menu/MetaMenu.vue'
import { MU_DELETE_USER_TOKEN, USER_LOG_OUT } from '@/store/user/mutation.type'
import { GET_IS_ALIVE, REFRESH } from '@/store/user/actions.type'
import SubmitSuccess from '@/components/forms/SubmitSuccess.vue'
import Unescape from '@/common/unescape'
import bootstrapBreakPoints from '@/common/breakpointsBootstrap'
import CookieLaw from 'vue-cookie-law'
import { GET_I18N_LANGUAGES } from '@/store/employee/actions.type'
import { I18n } from '@/store/employee/types'
@Component({
  components: { CookieLaw, SubmitSuccess, FooterMenu }
})
export default class App extends Vue {
  $refs!: {
    metaMenu: MetaMenu;
  }

  @Action(REFRESH)
  public refresh: () => Promise<void>

  @Action(GET_IS_ALIVE)
  public getIsAlive: () => Promise<void>

  @Action(GET_I18N_LANGUAGES)
  public getLanguages: () => Promise<Array<I18n>>

  @Mutation('setShowDesktopVersion')
  public setShowDesktopVersion: (isDesktop: boolean) => void

  @Mutation('setCurrentScreenWidth')
  public setCurrentScreenWidth: (width: number) => void

  @Mutation('setContentLoading')
  public setContentLoading: (isLoading: boolean) => void

  @Mutation('setPageLoading')
  public setPageLoading: (isLoading: boolean) => void

  @Mutation(MU_DELETE_USER_TOKEN)
  public deleteUserToken: () => void

  @Mutation(USER_LOG_OUT)
  public userLogout: () => void

  showAlert = false
  alertTitle = ''
  alertInfo = ''
  alertError = false
  alertReload = false

  isAlive = true
  loadPage = true
  loadContent = true

  setLayoutVersion (): void {
    const currentScreenWidth = document.body.clientWidth

    if (currentScreenWidth >= breakPoints.tablet) {
      this.setShowDesktopVersion(true)
    } else {
      this.setShowDesktopVersion(false)
    }

    this.setCurrentScreenWidth(currentScreenWidth)

    // adjust width of col-12 form input fields to col-6
    const formGroups = document.getElementsByClassName('form-group')
    for (let i = 0; i < formGroups.length; i++) {
      const label = formGroups.item(i)?.getElementsByClassName('col-form-label col-md-2 col-sm-12')
      if (label !== undefined && label.length > 0) {
        const formInputWrapper = ((label.item(0) as HTMLElement).nextSibling as HTMLElement)
        if (currentScreenWidth >= bootstrapBreakPoints.md) {
          formInputWrapper.style.paddingLeft = '14px'
        } else {
          formInputWrapper.style.removeProperty('padding-left')
        }
      }
    }
  }

  computeMinHeightOfContent (): void {
    const footerHeight = document.getElementsByTagName('footer').item(0)?.clientHeight || 0

    const metaMenuExpanded = !!document.getElementById('meta-menu-expansion')
    const metaMenuHeight = metaMenuExpanded ? document.getElementById('meta-menu-expansion')?.clientHeight : 0 || 0

    const content = document.getElementById('content')
    const topPositionOfContent = content?.getBoundingClientRect().top || 0

    if (content) {
      content.style.minHeight = 'calc((100vh - ' + topPositionOfContent + 'px - ' + footerHeight + 'px + ' + metaMenuHeight + 'px) - 3rem)'
    }
  }

  // use this to trigger toasts:
  // this.$root.$emit('toast', 'Body', 'Header')
  showPublicToast (message: string, header: string, variant = 'dark-grey-1'): void {
    this.$bvToast.toast(message, {
      toaster: 'b-toaster-bottom-right',
      title: header,
      solid: true,
      append: false,
      noFade: true,
      delay: 1000,
      variant: variant
    })
  }

  dismissAlert (): void {
    this.showAlert = false
    this.alertTitle = ''
    this.alertInfo = ''
    this.alertError = false
    this.alertReload = false
  }

  scrollToContent (): void {
    const content = document.getElementById('content')
    if (content !== null) {
      content.scrollIntoView()
    }
  }

  beforeMount (): void {
    this.setLayoutVersion()
  }

  created (): void {
    this.setContentLoading(true)
    this.getIsAlive().then(() => {
      this.isAlive = true

      if (process.env.NODE_ENV !== 'development') {
        this.getLanguages()
      }
    }).catch(() => {
      this.isAlive = false
    }).finally(() => {
      this.loadPage = false
      this.setPageLoading(true)
    })
  }

  mounted (): void {
    this.computeMinHeightOfContent()
    this.$nextTick(
      function () {
        window.addEventListener('resize', () => {
          this.setLayoutVersion()
          this.computeMinHeightOfContent()
        })

        window.addEventListener('alive-status-changed', (e: CustomEventInit) => {
          this.isAlive = JSON.parse(e.detail)
        })

        window.addEventListener('load-status-changed', (e: CustomEventInit) => {
          this.loadPage = JSON.parse(e.detail)
        })

        this.unescapeShy()

        this.loadContent = false
        this.setContentLoading(false)
      }
    )
    this.$root.$on('toast', (message: string, header: string) => {
      this.showPublicToast(message, header)
    })

    this.$root.$on('load', () => {
      this.loadContent = true
      this.setContentLoading(true)
    })

    this.$root.$on('end-load', () => {
      this.loadContent = false
      this.setContentLoading(false)
      this.setPageLoading(false)
    })

    this.$root.$on('alert', (headline: string, info: string, error: boolean, reload?: boolean) => {
      this.showAlert = true
      this.alertTitle = headline
      this.alertInfo = info
      this.alertError = error
      this.alertReload = !!reload
      window.scrollTo(0, 0)
    })

    this.$root.$on('dismiss-alert', () => {
      this.dismissAlert()
    })

    this.$root.$on('scroll-to-content', () => {
      this.scrollToContent()
    })

    this.$router.afterEach(() => {
      if (this.$refs.metaMenu) this.$refs.metaMenu.closeMenu()
      this.dismissAlert()
    })

    window.addEventListener('load', () => {
      this.refresh()
    }, false)
  }

  updated (): void {
    this.setLayoutVersion()
    this.computeMinHeightOfContent()
    this.$nextTick(this.unescapeShy)
  }

  beforeDestroy (): void {
    window.removeEventListener('resize', () => {
      this.setLayoutVersion()
    })
    Vue.prototype.$loading = false
  }

  unescapeShy (): void {
    const root = document.getElementById('content')
    if (root !== null) {
      Unescape.setSoftHyphensInLabels(root)
    }
  }
}
