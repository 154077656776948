import { VuexModule, Module, Action } from 'vuex-module-decorators'
import {
  ACTIVATE_EVENT, BOOKMARK_EVENT, DEACTIVATE_EVENT,
  DELETE_EVENT,
  GET_ALL_EVENTS,
  GET_ALL_EVENTS_BY_COMPETENCES, GET_ALL_TOPICS, GET_BOOKMARK_EVENTS,
  SAVE_EVENT
} from '@/store/event/actions.type'
import { EventService } from '@/common/api.service'
import { SimpleServerResponse } from '@/store/types'
import { Competences, Event } from '@/store/event/types'

@Module
class EventModule extends VuexModule {
  @Action({ rawError: true })
  public [GET_ALL_EVENTS] (): Promise<Array<Event>> {
    return EventService.getAll().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_ALL_EVENTS_BY_COMPETENCES] (competences: Competences): Promise<Array<Event>> {
    return EventService.getByCompetences(competences).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [SAVE_EVENT] (event: Event): Promise<SimpleServerResponse> {
    return EventService.save(event).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [ACTIVATE_EVENT] (id: string): Promise<SimpleServerResponse> {
    return EventService.activate(id).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [DEACTIVATE_EVENT] (id: string): Promise<SimpleServerResponse> {
    return EventService.deactivate(id).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [DELETE_EVENT] (id: string): Promise<SimpleServerResponse> {
    return EventService.delete(id).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [BOOKMARK_EVENT] (event: Event): Promise<SimpleServerResponse> {
    return EventService.bookmark(event.id, event.bookmark).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_BOOKMARK_EVENTS] (): Promise<Array<Event>> {
    return EventService.getBookmarked().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_ALL_TOPICS] (): Promise<Array<string>> {
    return EventService.getAllTopics().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }
}
export default EventModule
