import {
  CompetenceProfileType,
  FileDto,
  Qualification,
  SimpleServerResponse,
  TalentProfileViewType
} from '@/store/types'
import { VuexModule, Module, Action } from 'vuex-module-decorators'
import {
  SEARCH_TALENT_BY_NAME,
  GET_STUDENT_COMPETENCE_PROFILE,
  SET_STUDENT_COMPETENCE_PROFILE,
  GET_EMPLOYEE_BASE_DATA,
  SET_EMPLOYEE_BASE_DATA,
  GET_TASKS,
  UPLOAD_AND_TALENT_SUGGESTION,
  GET_READ_OUT_COMPETENCES_FOR_TALENT,
  UPLOAD_PDF_AND_GET_COMPETENCES,
  SEND_MESSAGE,
  GET_RECIPIENT_GROUPS,
  GET_SENT_MESSAGES,
  GET_MODULES,
  CHANGE_MODULE_AVAILABILITY,
  GET_I18N_LANGUAGE,
  SAVE_I18N_LANGUAGES,
  GET_I18N_LANGUAGES,
  GROUP_SYNONYM,
  EDIT_SYNONYM,
  GET_OPEN_ACTIVATION_REQUESTS_TALENTS,
  GET_OPEN_ACTIVATION_REQUESTS_COMPANIES
} from '@/store/employee/actions.type'
import { EmployeeService, I18NService } from '@/common/api.service'
import { EmployeeProfile, I18n, Modul, TalentFile, TargetFile, Tasks } from '@/store/employee/types'
import { Messages } from '@/store/user/types'
import { AxiosResponse } from 'axios'
import { CompanyProfile } from '@/store/company/types'

@Module
class EmployeeState extends VuexModule {
  @Action({ rawError: true })
  public [SEARCH_TALENT_BY_NAME] (params: string): Promise<SimpleServerResponse> {
    return EmployeeService.searchTalentByName(params).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_STUDENT_COMPETENCE_PROFILE] (params: string): Promise<SimpleServerResponse> {
    return EmployeeService.getStudentCompetenceProfile(params).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [SET_STUDENT_COMPETENCE_PROFILE] (params: CompetenceProfileType): Promise<SimpleServerResponse> {
    return EmployeeService.setStudentCompetenceProfile(params).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_EMPLOYEE_BASE_DATA] (): Promise<EmployeeProfile> {
    return EmployeeService.getEmployeeBaseData()
  }

  @Action({ rawError: true })
  public [SET_EMPLOYEE_BASE_DATA] (params: EmployeeProfile): Promise<SimpleServerResponse> {
    return EmployeeService.setEmployeeBaseData(params).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_TASKS] (): Promise<Array<Tasks>> {
    return EmployeeService.getTasks().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [UPLOAD_AND_TALENT_SUGGESTION] (file: FileDto): Promise<SimpleServerResponse> {
    return EmployeeService.uploadAndSuggestTalents(file).then(data => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_READ_OUT_COMPETENCES_FOR_TALENT] (params: TargetFile): Promise<SimpleServerResponse> {
    return EmployeeService.getReadOutCompetencesForTalent(params).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [UPLOAD_PDF_AND_GET_COMPETENCES] (params: TalentFile): Promise<SimpleServerResponse> {
    return EmployeeService.uploadPDFAndGetCompetences(params).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [SEND_MESSAGE] (content: Messages): Promise<SimpleServerResponse> {
    return EmployeeService.sendMessage(content).then(data => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_RECIPIENT_GROUPS] (): Promise<SimpleServerResponse> {
    return EmployeeService.getRecipientGroups().then(data => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_SENT_MESSAGES] (): Promise<Array<Messages>> {
    return EmployeeService.getSentMessages().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_MODULES] (): Promise<Array<Modul>> {
    return EmployeeService.getModules().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [CHANGE_MODULE_AVAILABILITY] (content: Modul[]): Promise<SimpleServerResponse> {
    return EmployeeService.changeModuleAvailability(content).then(data => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_I18N_LANGUAGES] (): Promise<Array<I18n>> {
    return I18NService.loadLanguages().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_I18N_LANGUAGE] (lang: string): Promise<I18n> {
    return I18NService.loadLanguage(lang).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [SAVE_I18N_LANGUAGES] (langs: Array<I18n>): Promise<AxiosResponse<SimpleServerResponse>> {
    return I18NService.saveLanguages(langs).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GROUP_SYNONYM] (synonym: Qualification): Promise<Qualification> {
    return EmployeeService.groupSynonym(synonym).then(data => {
      return data
    }).catch(error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [EDIT_SYNONYM] (synonym: Qualification): Promise<Qualification> {
    return EmployeeService.editSynonym(synonym).then(data => {
      return data
    }).catch(error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_OPEN_ACTIVATION_REQUESTS_TALENTS] (): Promise<Array<TalentProfileViewType>> {
    return EmployeeService.getOpenActivationRequestsTalents().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_OPEN_ACTIVATION_REQUESTS_COMPANIES] (): Promise<Array<CompanyProfile>> {
    return EmployeeService.getOpenActivationRequestsCompanies().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }
}
export default EmployeeState
