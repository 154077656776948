const TEXT = 'text'
const TEXTAREA = 'textarea'
const EMAIL = 'email'
const PASSWORD = 'password'
const SELECT = 'select'
const MULTISELECT = 'multiselect'
const AUTOCOMPLETE = 'autocomplete'
const DATE = 'date'
const DATETIME = 'datetime'
const NUMBER = 'number'
const PHONE = 'phone'
const INPUTSELECT = 'inputSelect'
const PHOTO = 'photo'
const VIDEO = 'video'
const RADIO = 'radio'
const CHECKBOX = 'checkbox'
const CHECKBOXGROUP = 'checkboxGroup'
const SET = 'SET'
const DUMMY = 'dummy'

export default {
  TEXT, TEXTAREA, EMAIL, MULTISELECT, AUTOCOMPLETE, INPUTSELECT, PASSWORD, PHONE, SELECT, DATE, PHOTO, VIDEO, RADIO, CHECKBOX, CHECKBOXGROUP, DUMMY, NUMBER, DATETIME, SET
}
