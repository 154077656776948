import { VuexModule, Module, Action } from 'vuex-module-decorators'
import {
  DELETE_EVENT_SEARCH_AGENT, DELETE_JOB_SEARCH_AGENT,
  GET_ALL_EVENT_SEARCH_AGENTS,
  GET_ALL_JOB_SEARCH_AGENTS,
  SAVE_EVENT_SEARCH_AGENT, SAVE_JOB_SEARCH_AGENT
} from '@/store/search/actions'
import { Agents, EventSearchAgentType, JobSearchAgentType } from '@/store/search/types'
import { SimpleServerResponse } from '@/store/types'
import { SearchService } from '@/common/api.service'

@Module
class SearchState extends VuexModule {
  @Action({ rawError: true })
  public [GET_ALL_EVENT_SEARCH_AGENTS] (): Promise<Agents> {
    return SearchService.getEventSearchAgents()
  }

  @Action({ rawError: true })
  public [SAVE_EVENT_SEARCH_AGENT] (searchAgent: EventSearchAgentType): Promise<string> {
    return SearchService.saveEventSearchAgent(searchAgent)
  }

  @Action({ rawError: true })
  public [DELETE_EVENT_SEARCH_AGENT] (agentId: string): Promise<SimpleServerResponse> {
    return SearchService.deleteEventSearchAgent(agentId)
  }

  @Action({ rawError: true })
  public [GET_ALL_JOB_SEARCH_AGENTS] (): Promise<Agents> {
    return SearchService.getJobSearchAgents()
  }

  @Action({ rawError: true })
  public [SAVE_JOB_SEARCH_AGENT] (searchAgent: JobSearchAgentType): Promise<string> {
    return SearchService.saveJobSearchAgents(searchAgent)
  }

  @Action({ rawError: true })
  public [DELETE_JOB_SEARCH_AGENT] (agentId: string): Promise<SimpleServerResponse> {
    return SearchService.deleteJobSearchAgent(agentId)
  }
}
export default SearchState
