export const setSoftHyphensInLabels = (rootElement: HTMLElement): void => {
  const labelCollection = rootElement.querySelectorAll('label, span')

  for (let i = 0; i < labelCollection.length; i++) {
    const item = labelCollection.item(i)

    if (item !== null) {
      const label = item.innerHTML
      item.innerHTML = label.replace(/#shy#/g, '&shy;')
    }
  }
}

export default { setSoftHyphensInLabels }
