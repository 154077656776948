





































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
@Component
/*
The modal dialog for the website declarations (terms of service, data protection, etc).
@group OTHER
 */
export default class DeclarationModal extends Vue {
  // the title of the modal
  @Prop({ required: true })
  title: string

  // if the modal can be closed on a click on the backdrop
  @Prop()
  backdrop: boolean

  // if the esc key closes the modal
  @Prop()
  esc: boolean

  // if a close button is shown in the modal header
  @Prop()
  headerClose: boolean

  // the text for the modal
  @Prop()
  text: string

  // the method for the button click
  @Prop()
  buttonClick: string

  // the button text
  @Prop()
  buttonText: string

  // text for the checkbox
  @Prop()
  checkboxText: string

  // if the checkbox is visible
  @Prop()
  checkboxVisibility: boolean

  // unique identifier
  @Prop({ required: true })
  id: string

  checkboxStatus = false
}
