export const GET_DASHBOARD_WELCOME = 'action/dashboard/welcome/get'
export const GET_DASHBOARD_PROFILE_PICTURE = 'action/dashboard/profile-picture/get'
export const GET_DASHBOARD_EVENTS = 'action/dashboard/events/get'
export const GET_DASHBOARD_NEWS_NEW_OWN_EVENTS = 'action/dashboard/news/new-own-events/get'
export const GET_DASHBOARD_COMPETENCES = 'action/dashboard/competences/get'
export const GET_DASHBOARD_KODE_STATUS = 'action/dashboard/kode-status/get'
export const GET_DASHBOARD_COMPANY_PROGRESS = 'action/dashboard/company-progress/get'
export const GET_DASHBOARD_JOB_ADS = 'action/dashboard/job-ads/get'
export const GET_DASHBOARD_MENTORING_PROGRESS = 'action/dashboard/mentoring-progress/get'
export const GET_DASHBOARD_NEWS_NEW_JOB_ADS = 'action/dashboard/new-job-ads/get'
export const GET_DASHBOARD_NEWS_NEW_COMPETENCE_METERINGS = 'action/dashboard/news/new-competence-meterings/get'
export const GET_DASHBOARD_NEW_MESSAGES = 'action/dashboard/news/messages/get'
export const GET_DASHBOARD_KODE_APPLICATIONS = 'action/dashboard/kode/applications/get'
export const GET_DASHBOARD_OPEN_INQUIRIES = 'action/dashboard/open-inquiries/get'
export const GET_DASHBOARD_OWN_OPEN_MENTOR_REQUESTS = 'action/dashboard/own/open-mentor-requests/get'
export const GET_DASHBOARD_OWN_OPEN_INQUIRIES = 'action/dashboard/own/open-inquiries/get'
export const GET_DASHBOARD_OPEN_ACTIVATION_REQUESTS = 'action/dashboard/open-activation-requests/get'
