import Vue from 'vue'
import i18n from '@/i18n'
import VueRouter, { RouteConfig } from 'vue-router'
import { EventSearchResult } from '@/store/search/types'
import store from '@/store/index'
import UserState from '@/store/user/user.module'
import { getModule } from 'vuex-module-decorators'
import { MenuEntry } from '@/store/types'
import {
  ADMIN_PANEL,
  COMPANY_OWN_PROFILE_READ,
  DASHBOARD_EVENTS,
  DASHBOARD_WELCOME,
  EMPLOYEE_READ_OWN_PROFILE,
  INQUIRY_READ_BY_COMPANY,
  INQUIRY_READ_BY_TALENT,
  JOB_AD_OWN_MATCHING,
  JOB_AD_OWN_READ,
  JOB_AD_OWN_WRITE,
  JOB_AD_READ,
  JOB_AD_WRITE,
  JOB_PROFILE_READ,
  KODE_EVALUATION,
  KODE_TEST_READ,
  MANAGE_EVENT,
  MENTOR_OWN_WRITE,
  MENTOR_READ,
  MENTOR_REQUEST,
  MESSAGE_READ,
  MESSAGE_WRITE,
  SEARCH_FOR_TALENTS_BY_NAME,
  STUDENT_KODE_MATCHING,
  STUDENT_OWN_APPLICATION_DATA_WRITE,
  STUDENT_OWN_MATCHING,
  STUDENT_OWN_PROFILE_READ,
  STUDENT_VIEW,
  STUDENT_OWN_VIEW,
  TARGET_COMPETENCE_PROFILE_EDIT,
  MODULE_MANAGEMENT,
  BOOKMARK_EVENT,
  I18N,
  SYNONYM_GROUP,
  REPORT,
  PROFILE_VALIDATION
} from '@/common/rights'

Vue.use(VueRouter)

function loadView (view: string) {
  // eslint-disable-next-line
  return (): Promise<any> => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

function getLocaleRegex (): string {
  let reg = ''
  i18n.availableLocales.forEach((locale, index) => {
    reg = `${reg}${locale}${index !== i18n.availableLocales.length - 1 ? '|' : ''}`
  })
  return `(${reg})`
}

const UserStore = getModule(UserState, store)

// Attention: using props in routes are not possible
// the problem lies in the internationalization (props are lost on the redirect)
const routes: Array<RouteConfig> = [
  {
    path: `/:locale${getLocaleRegex()}?`,
    components: { layout: loadView('layouts/LayoutWithoutSubmenu') },
    children: [
      {
        path: '',
        name: 'home',
        components: { content: loadView('Home') },
        meta: { homeOnlyWhenUnauthorized: true }
      },
      {
        path: 'register',
        name: 'registration',
        components: { content: loadView('security/Registration') },
        meta: { unauthorizedOnly: true }
      },
      {
        path: 'public/auth/verify/:id/:nonce',
        name: 'confirm-registration',
        components: { content: loadView('security/ConfirmRegistration') },
        meta: { unauthorizedOnly: true }
      },
      {
        path: 'login',
        name: 'login',
        components: { content: loadView('security/Login') },
        meta: { unauthorizedOnly: true }
      },
      {
        path: 'request-reset-password',
        name: 'request-reset-password',
        components: { content: loadView('security/RequestResetPassword') },
        meta: { unauthorizedOnly: true },
        props: true
      },
      {
        path: 'public/auth/resetPassword/:id/:nonce',
        name: 'reset-password',
        components: { content: loadView('security/ResetPassword') },
        meta: { unauthorizedOnly: true }
      },
      {
        path: '403-forbidden',
        name: 'no-rights',
        components: { content: loadView('security/NoRights') }
      },
      {
        path: 'data-protection',
        name: 'footer-menu-data-protection',
        components: { content: loadView('footerMenu/DataProtection') }
      },
      {
        path: 'terms-of-service',
        name: 'footer-menu-terms-of-service',
        components: { content: loadView('footerMenu/TermsOfService') }
      },
      {
        path: 'contact-form',
        name: 'contact-form',
        components: { content: loadView('footerMenu/ContactFormPage') }
      },
      {
        path: 'imprint',
        name: 'footer-menu-imprint',
        components: { content: loadView('footerMenu/Imprint') }
      },
      {
        path: 'faq',
        name: 'footer-menu-faq',
        components: { content: loadView('footerMenu/FAQ') }
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        components: { content: loadView('Dashboard') },
        meta: { rights: [DASHBOARD_WELCOME, DASHBOARD_EVENTS] }
      },
      {
        path: 'settings',
        name: 'settings',
        components: { content: loadView('Settings') },
        meta: { loggedInUserOnly: true }
      },
      {
        path: 'kode-evaluation-session/:id',
        name: 'hszg-kode-evaluation-session',
        components: { layout: loadView('layouts/LayoutWithoutSubmenu'), content: loadView('staff/KodeEvaluationSessionEditCreate') },
        meta: { rights: [KODE_EVALUATION] }
      },
      {
        path: 'job/:id',
        name: 'job-editor',
        components: { content: loadView('company/JobAdEditor') },
        meta: { rights: [JOB_AD_WRITE, JOB_AD_OWN_WRITE] }
      },
      {
        path: 'talent-inquiries',
        name: 'company-talent-inquiries',
        components: { content: loadView('company/TalentInquiries') },
        meta: { rights: [INQUIRY_READ_BY_COMPANY] }
      },
      {
        path: 'matching-results/job/:id',
        name: 'company-matching-result-detail',
        components: { content: loadView('company/MatchingResultsDetail') },
        meta: { rights: [JOB_AD_OWN_MATCHING] }
      },
      {
        path: 'inquiries-from-companies/job-ad/:id',
        name: 'inquiry-job-ad',
        components: { content: loadView('JobAd') },
        meta: { rights: [JOB_AD_READ] },
        props: {
          content: (route: { params: { isApplication: boolean; inquiryId: string; options: string[] } }): { isApplicationProp: boolean; inquiryId: string; inquiryButtonsProp: string[] } => ({
            isApplicationProp: route.params.isApplication,
            inquiryButtonsProp: route.params.options,
            inquiryId: route.params.inquiryId
          })
        }
      },
      {
        path: 'sent-applications/job-ad/:id',
        name: 'sent-applications-job-ad',
        components: { content: loadView('JobAd') },
        meta: { rights: [JOB_AD_READ] },
        props: {
          content: (route: { params: { isApplication: boolean; inquiryId: string; options: string[] } }): { isApplicationProp: boolean; inquiryId: string; inquiryButtonsProp: string[] } => ({
            isApplicationProp: route.params.isApplication,
            inquiryButtonsProp: route.params.options,
            inquiryId: route.params.inquiryId
          })
        }
      },
      {
        path: 'public/download-zip/:nonce/:fileId',
        name: 'hszg-download-csv',
        components: { content: loadView('staff/DownloadCsv') }
      }
    ]
  },
  {
    path: `/:locale${getLocaleRegex()}?`,
    components: { layout: loadView('layouts/LayoutWithSubmenu') },
    meta: { loggedInUserOnly: true },
    children: [
      {
        path: 'talent-profile/:id',
        name: 'talent-profile',
        components: { content: loadView('student/TalentProfile') },
        meta: { rights: [STUDENT_VIEW] }
      },
      {
        path: 'talent-profile/:id/:jobAdId',
        name: 'talent-profile-jobad',
        components: { content: loadView('student/TalentProfile') },
        meta: { rights: [STUDENT_VIEW] }
      },
      {
        path: 'talent-profile-preview',
        name: 'talent-profile-preview',
        components: { content: loadView('student/TalentProfile') },
        meta: { rights: [STUDENT_OWN_VIEW] }
      },
      {
        path: 'company-profile-preview',
        name: 'company-profile-preview',
        components: { content: loadView('company/CompanyProfilePublic') },
        meta: { rights: [COMPANY_OWN_PROFILE_READ] }
      },
      {
        path: 'profile-editor',
        name: 'student-profile-editor',
        components: { content: loadView('student/ProfileEditor') },
        meta: { rights: [STUDENT_OWN_PROFILE_READ, STUDENT_OWN_APPLICATION_DATA_WRITE] }
      },
      {
        path: 'application-editor',
        name: 'student-application-editor',
        components: { content: loadView('student/ApplicationDataEditor') },
        meta: { rights: [STUDENT_OWN_PROFILE_READ, STUDENT_OWN_APPLICATION_DATA_WRITE] }
      },
      {
        path: 'cv-data-editor',
        name: 'student-cv-data-editor',
        components: { content: loadView('student/CvDataEditor') },
        meta: { rights: [STUDENT_OWN_PROFILE_READ, STUDENT_OWN_APPLICATION_DATA_WRITE] }
      },
      {
        path: 'qualifications-editor',
        name: 'student-qualifications-editor',
        components: { content: loadView('student/QualificationsEditor') },
        meta: { rights: [STUDENT_OWN_PROFILE_READ, STUDENT_OWN_APPLICATION_DATA_WRITE] }
      },
      {
        path: 'mentor-profile-editor',
        name: 'mentor-profile',
        components: { content: loadView('MentorProfile') },
        meta: { rights: [MENTOR_OWN_WRITE] }
      },
      {
        path: 'kode-metering',
        name: 'kode-metering',
        components: { content: loadView('student/KodeMetering') },
        meta: { rights: [KODE_TEST_READ] }
      },
      /*
      {
        path: 'job-search',
        name: 'student-job-search',
        components: { content: loadView('student/JobSearch') },
        meta: { rights: ['STUDENT', 'ALUMNI'] }
      },
       */
      {
        path: 'job-finder',
        name: 'student-job-finder',
        components: { content: loadView('student/JobFinder') },
        meta: { rights: [STUDENT_KODE_MATCHING] }
      },
      {
        path: 'dream-job',
        name: 'student-dream-job',
        components: { content: loadView('student/JobSearch') },
        meta: { rights: [STUDENT_KODE_MATCHING] }
      },
      {
        path: 'mentoring',
        name: 'student-mentoring',
        components: { content: loadView('student/Mentoring') },
        meta: { rights: [MENTOR_OWN_WRITE, MENTOR_READ, MENTOR_REQUEST] }
      },
      {
        path: 'inquiries-from-companies',
        name: 'student-company-inquiries',
        components: { content: loadView('student/CompanyInquiries') },
        meta: { rights: [INQUIRY_READ_BY_TALENT] }
      },
      {
        path: 'sent-applications',
        name: 'student-sent-applications',
        components: { content: loadView('student/SentApplications') },
        meta: { rights: [INQUIRY_READ_BY_TALENT] }
      },
      {
        path: 'jobs-to-job-family',
        name: 'student-jobs-to-job-family',
        components: { content: loadView('student/JobsToSelectedJobFamily') },
        meta: { rights: [STUDENT_OWN_MATCHING] }
      },
      {
        path: 'job-search-agent',
        name: 'job-search-agent',
        components: { content: loadView('student/JobSearchAgentPage') },
        meta: { rights: [STUDENT_OWN_MATCHING] } // TODO: set actual right from backend
      },
      {
        path: 'job-view/:id',
        name: 'job-ad',
        components: { content: loadView('JobAd') },
        meta: { rights: [JOB_AD_READ, JOB_AD_OWN_READ] },
        props: {
          content: (route: { params: { isApplication: boolean; inquiryId: string; options: string[] } }): { isApplicationProp: boolean; inquiryId: string; inquiryButtonsProp: string[] } => ({
            isApplicationProp: route.params.isApplication,
            inquiryButtonsProp: route.params.options,
            inquiryId: route.params.inquiryId
          })
        }
      },
      {
        path: 'job-view/:id/:preview',
        name: 'job-ad-preview',
        components: { content: loadView('JobAd') },
        meta: { rights: [JOB_AD_OWN_READ] }
      },
      {
        path: 'events-overview',
        name: 'student-events-overview',
        components: { content: loadView('student/EventsOverview') },
        meta: { rights: [BOOKMARK_EVENT] }
      },
      {
        path: 'event/:id',
        name: 'student-event-detail',
        components: { content: loadView('student/EventDetail') },
        props: {
          content: (route: { params: { id: string; event: EventSearchResult } }): { id: string; event: EventSearchResult } => ({
            id: route.params.id,
            event: route.params.event
          })
        }
      },
      {
        path: 'event-search-agent',
        name: 'event-search-agent',
        components: { content: loadView('EventSearchAgentPage') }
      },
      {
        path: 'competence-development-plan',
        name: 'student-development-plan',
        components: { content: loadView('student/Documents') },
        meta: { rights: [STUDENT_KODE_MATCHING] } // TODO: set the corresponding right
      },
      {
        path: 'company-profile-editor',
        name: 'company-profile-editor',
        components: { content: loadView('company/ProfileEditor') },
        meta: { rights: [COMPANY_OWN_PROFILE_READ] }
      },
      {
        path: 'job-editor',
        name: 'company-job-editor',
        components: { content: loadView('company/JobAdEditor') },
        meta: { rights: [JOB_AD_OWN_WRITE] }
      },
      {
        path: 'job-ad-overview',
        name: 'company-job-ad-overview',
        components: { content: loadView('company/JobAdOverview') },
        meta: { rights: [JOB_AD_OWN_READ] }
      },
      {
        path: 'job-ad-applications',
        name: 'company-job-ad-applications',
        components: { content: loadView('company/JobAdApplications') },
        meta: { rights: [INQUIRY_READ_BY_COMPANY] }
      },
      {
        path: 'candidate-search',
        name: 'company-candidate-search',
        components: { content: loadView('company/CandidateSearch') },
        meta: { rights: [JOB_AD_OWN_MATCHING] }
      },
      /*
      {
        path: 'talent-inquiries/job/:id',
        name: 'company-talent-inquiries',
        components: { content: loadView('company/TalentInquiries') },
        meta: { rights: [INQUIRY_READ_BY_COMPANY] }
      },
       */
      {
        path: 'hszg-profile-editor',
        name: 'hszg-profile-editor',
        components: { content: loadView('staff/Profile') },
        meta: { rights: [EMPLOYEE_READ_OWN_PROFILE] }
      },
      {
        path: 'kode-evaluation-dashboard',
        name: 'hszg-kode-evaluation-dashboard',
        components: { content: loadView('staff/KodeEvaluationDashboard') },
        meta: { rights: [KODE_EVALUATION] }
      },
      {
        path: 'kode-evaluation-single-person',
        name: 'hszg-kode-evaluation-single-person',
        components: { content: loadView('staff/KodeScore') },
        meta: { rights: [SEARCH_FOR_TALENTS_BY_NAME] }
      },
      {
        path: 'kode-evaluation-sessions',
        name: 'hszg-kode-evaluation-sessions',
        components: { content: loadView('staff/KodeEvaluationSessionOverview') },
        meta: { rights: [KODE_EVALUATION] }
      },
      {
        path: 'kode-evaluation-session-create',
        name: 'hszg-kode-evaluation-session-create',
        components: { content: loadView('staff/KodeEvaluationSessionEditCreate') },
        meta: { rights: [KODE_EVALUATION] }
      },
      // {
      //   path: 'kode-evaluation-batch',
      //   name: 'hszg-kode-evaluation-batch',
      //   components: { content: loadView('staff/KodeEvaluationBatch') },
      //   meta: { rights: [KODE_EVALUATION] }
      // },
      {
        path: 'kode-evaluation-details-sent',
        name: 'hszg-kode-evaluation-details-sent',
        components: { content: loadView('staff/KodeEvaluationDetailsSent') },
        meta: { rights: ['KODE_MARK_LOGIN_DETAILS_SENT'] }
      },
      {
        path: 'meta-target-profiles',
        name: 'hszg-meta-target-profiles',
        components: { content: loadView('staff/MetaTargetProfiles') },
        meta: { rights: [TARGET_COMPETENCE_PROFILE_EDIT] }
      },
      {
        path: 'target-profiles',
        name: 'hszg-target-profiles',
        components: { content: loadView('staff/TargetProfiles') },
        meta: { rights: [JOB_PROFILE_READ] }
      },
      {
        path: 'evaluation',
        name: 'hszg-evaluation',
        components: { content: loadView('staff/Evaluation') },
        meta: { rights: [REPORT] }
      },
      {
        path: 'profile-activations',
        name: 'hszg-profile-activations',
        components: { content: loadView('staff/ProfileActivations') },
        meta: { rights: [PROFILE_VALIDATION] }
      },
      {
        path: 'panel',
        name: 'hszg-content-administration',
        components: { content: loadView('staff/ContentAdministration') },
        meta: { rights: [ADMIN_PANEL] }
      },
      {
        path: 'qualification-administration',
        name: 'hszg-qualifications-administration',
        components: { content: loadView('staff/QualificationsAdministration') },
        meta: { rights: [SYNONYM_GROUP] }
      },
      {
        path: 'panel/:name',
        name: 'hszg-panel',
        components: { content: loadView('staff/ContentAdministration') },
        meta: { rights: [ADMIN_PANEL] }
      },
      {
        path: 'panel/:name/:id',
        name: 'hszg-panel',
        components: { content: loadView('staff/ContentAdministration') },
        meta: { rights: [ADMIN_PANEL] }
      },
      {
        path: 'panel/:name/:id/:childType',
        name: 'hszg-panel',
        components: { content: loadView('staff/ContentAdministration') },
        meta: { rights: [ADMIN_PANEL] }
      },
      {
        path: 'calendar-administration',
        name: 'hszg-calendar-administration',
        components: { content: loadView('staff/CalendarAdministration') },
        meta: { rights: [MANAGE_EVENT] }
      },
      {
        path: 'messaging',
        name: 'hszg-messaging',
        components: { content: loadView('staff/Messaging') },
        meta: { rights: [MESSAGE_READ] }
      },
      {
        path: 'messaging-send-messages',
        name: 'hszg-messaging-send-messages',
        components: { content: loadView('staff/MessagingSendMessages') },
        meta: { rights: [MESSAGE_WRITE] }
      },
      {
        path: 'messaging-sent-messages',
        name: 'hszg-messaging-sent-messages',
        components: { content: loadView('staff/MessagingSentMessages') },
        meta: { rights: [MESSAGE_WRITE] }
      },
      {
        path: 'messaging-received-messages',
        name: 'hszg-messaging-received-messages',
        components: { content: loadView('staff/MessagingReceivedMessages') },
        meta: { rights: [MESSAGE_READ] }
      },
      {
        path: 'mentoring-administration',
        name: 'hszg-mentoring-administration',
        components: { content: loadView('staff/MentoringAdministration') },
        meta: { rights: [ADMIN_PANEL] }
      },
      {
        path: 'role-assignment-mentoring',
        name: 'hszg-role-assignment-mentoring',
        components: { content: loadView('staff/MentoringRoleAssignment') },
        meta: { rights: [ADMIN_PANEL] }
      },
      {
        path: 'rights-management',
        name: 'hszg-rights-management',
        components: { content: loadView('staff/RightsManagement') },
        meta: { rights: [ADMIN_PANEL] }
      },
      {
        path: 'role-assignment',
        name: 'hszg-role-assignment',
        components: { content: loadView('staff/RoleAssignment') },
        meta: { rights: [ADMIN_PANEL] }
      },
      {
        path: 'module-management',
        name: 'hszg-module-management',
        components: { content: loadView('staff/ModuleManagement') },
        meta: { rights: [MODULE_MANAGEMENT] }
      },
      {
        path: 'sprachen-management',
        name: 'hszg-language-management',
        components: { content: loadView('staff/LanguageManagement') },
        meta: { rights: [I18N] }
      }
    ]
  },
  {
    path: `/:locale${getLocaleRegex()}?`,
    components: { layout: loadView('layouts/LayoutWithoutSubmenu') },
    children: [
      {
        path: '*',
        name: '404',
        components: { content: loadView('security/PageNotFound') }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

router.beforeEach((to, from, next) => {
  // if (['/', '/login'].includes(to.path)) {
  //   I18NService.loadLanguages().then(() => {
  //     next()
  //   })
  // }

  // set locale if it is given in the path
  if (i18n.availableLocales.some(lang => lang === to.path.substring(1, to.path.indexOf('/', 1)))) {
    i18n.locale = to.path.substring(1, to.path.indexOf('/', 1))
  }

  // add locale to path if locale is set and it misses in the path
  // happens on every menu navigation if a locale (apart from the default) is set
  if (i18n.locale !== process.env.VUE_APP_I18N_FALLBACK_LOCALE && to.path.substr(1, i18n.locale.length + 1) !== i18n.locale + '/') {
    next({ path: '/' + i18n.locale + to.path, query: to.query })
  }

  store.commit('setPageLoading', true)

  if (to?.meta?.rights?.length) {
    const hasRight = !!to.meta.rights?.filter((r: string) => UserStore.userGetter?.rights.includes(r)).length
    if (hasRight) {
      next()
    } else {
      next({ name: 'no-rights' })
    }
  }

  if (to.matched.some(record => record.meta.homeOnlyWhenUnauthorized)) {
    if (!UserStore.isLoggedIn) {
      next()
    } else {
      next({ path: '/dashboard' })
    }
  } else {
    if (to.matched.some(record => record.meta.unauthorizedOnly)) {
      if (!UserStore.isLoggedIn) {
        next()
      } else {
        next(false)
      }
    } else {
      if (to.matched.some(record => record.meta.loggedInUserOnly)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!UserStore.isLoggedIn) {
          next({
            path: '/login'
          })
        } else {
          next()
        }
      } else {
        next() // make sure to always call next()!
      }
    }
  }
})

function findInArray (entries: Array<MenuEntry>, routeName: string | undefined): number[] {
  if (typeof routeName === 'undefined') {
    return [-1]
  }

  const index = entries.findIndex((entry: MenuEntry) => {
    return entry.route === routeName
  })

  if (index !== -1) {
    return [index]
  } else {
    let index2 = [-1]
    for (let i = 0; i < entries.length; i++) {
      if (entries[i].children.length > 0) {
        index2 = findInArray(entries[i].children, routeName)
        if (index2[0] !== -1) {
          return [i, ...index2]
        }
      }
    }
    return index2
  }
}

router.afterEach((to) => {
  // sets the active menu entry after every route change (including browser navigation like back and typing the url by hand)
  const routeName = to.matched[to.matched.length - 1].name
  const menuEntries = store.getters.getMenuEntries
  store.commit('setCurrentPage', findInArray(menuEntries, routeName))
})

export const allRoutes = routes

export default router
