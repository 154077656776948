import { BaseData, ApplicationData, Language, StudentProfileSettings } from '@/store/student/types'
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import {
  GET_APPLICATION_DATA_EDIT,
  GET_BASE_DATA_EDIT,
  GET_MATCHING_JOB_FAMILIES,
  REQUEST_MENTOR_APPOINTMENT,
  SET_APPLICATION_DATA,
  SET_BASE_DATA,
  GET_OWN_LANGUAGES,
  SET_OWN_LANGUAGES,
  GET_OWN_INTERESTS,
  SET_OWN_INTERESTS,
  GET_OWN_STUDENT_COMPETENCE_PROFILE,
  GET_OWN_QUALIFICATIONS,
  SET_OWN_QUALIFICATIONS,
  GET_CV_DATA,
  SET_CV_DATA,
  DELETE_CV_DATA,
  GET_JOB_ADS_TO_JOB_FAMILY,
  GET_TALENT_VIEW, GET_TALENT_PROFILE_PREVIEW,
  GET_PROFILE_STUDENT_SETTINGS,
  SET_PROFILE_STUDENT_SETTINGS,
  INQUIRE_COMPANY, INQUIRE_COMPANY_CANCEL
} from '@/store/student/actions'
import { StudentService } from '@/common/api.service'
import { MatchingJobFamily } from '@/store/user/types'
import { GenericRequest, Qualification, SimpleServerResponse, TalentProfileViewType } from '@/store/types'
import { MU_SET_PHOTO_FILENAME } from '@/store/student/mutations'
import { parseDateToLocalDate } from '@/common/globals'
import {
  APPLY_KODE_TEST,
  GET_PROFILE_KODE_STATUS,
  SET_PROFILE_KODE_STATUS
} from '@/store/user/actions.type'
import { CvElement } from '@/store/commonFormInput/types'
import { JobFinderType, KodeStatusDTO } from '@/store/kode/types'
import { InquiryDto } from '@/store/company/types'

@Module
class StudentState extends VuexModule {
  photoFileName: string | undefined = undefined

  get studentPhotoFileNameGetter (): string | undefined {
    return this.photoFileName
  }

  @Action
  public [GET_BASE_DATA_EDIT] (): Promise<SimpleServerResponse> {
    return StudentService.getBaseData().then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action
  public [SET_BASE_DATA] (params: BaseData): Promise<SimpleServerResponse> {
    return StudentService.setBaseData(params).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action
  public [GET_APPLICATION_DATA_EDIT] (): Promise<SimpleServerResponse> {
    return StudentService.getApplicationData().then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [SET_APPLICATION_DATA] (params: ApplicationData): Promise<SimpleServerResponse> {
    return StudentService.setApplicationData({ ...params, dateOfBirth: parseDateToLocalDate(params.dateOfBirth) }).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_CV_DATA] (): Promise<Array<CvElement>> {
    return StudentService.getCvData()
  }

  @Action({ rawError: true })
  public [SET_CV_DATA] (cvData: CvElement): Promise<CvElement> {
    return StudentService.saveCvData(cvData)
  }

  @Action({ rawError: true })
  public [DELETE_CV_DATA] (id: string): Promise<SimpleServerResponse> {
    return StudentService.deleteCvData(id)
  }

  @Action({ rawError: true })
  public [GET_OWN_LANGUAGES] (): Promise<SimpleServerResponse> {
    return StudentService.getOwnLanguages().then(data => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [SET_OWN_LANGUAGES] (params: Language[]): Promise<SimpleServerResponse> {
    return StudentService.setOwnLanguages(params).then(data => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_OWN_INTERESTS] (): Promise<SimpleServerResponse> {
    return StudentService.getOwnInterests().then(data => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [SET_OWN_INTERESTS] (params: string[]): Promise<SimpleServerResponse> {
    return StudentService.setOwnInterests(params).then(data => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_OWN_STUDENT_COMPETENCE_PROFILE] (role: string): Promise<SimpleServerResponse> {
    return StudentService.getOwnStudentCompetenceProfile().then(data => {
      this.context.commit(MU_SET_PHOTO_FILENAME, undefined)
      return data.data
    }).catch(error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_OWN_QUALIFICATIONS] (): Promise<Array<Qualification>> {
    return StudentService.getOwnQualifications().then(data => {
      return data
    }).catch(error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [SET_OWN_QUALIFICATIONS] (params: Qualification[]): Promise<Qualification[]> {
    return StudentService.setOwnQualifications(params)
  }

  @Action
  public [REQUEST_MENTOR_APPOINTMENT] (mentorId: string): string {
    return StudentService.requestMentorAppointment(mentorId)
  }

  @Action
  public [GET_MATCHING_JOB_FAMILIES] (): MatchingJobFamily[] {
    return StudentService.getMatchingJobFamilies()
  }

  @Action({ rawError: true })
  public [GET_JOB_ADS_TO_JOB_FAMILY] (): Promise<Array<JobFinderType>> {
    return StudentService.getJobAdsToSelectedJobFamily()
  }

  @Action({ rawError: true })
  public [GET_PROFILE_KODE_STATUS] (): Promise<KodeStatusDTO> {
    return StudentService.getProfileKodeStatus()
  }

  @Action
  public [SET_PROFILE_KODE_STATUS] (): Promise<SimpleServerResponse> {
    return StudentService.setProfileKodeStatus().then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action
  public [APPLY_KODE_TEST] (): Promise<SimpleServerResponse> {
    return StudentService.applyKodeTest().then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_TALENT_VIEW] (id: string): Promise<TalentProfileViewType> {
    return StudentService.getTalentView(id).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_TALENT_PROFILE_PREVIEW] (): Promise<TalentProfileViewType> {
    return StudentService.getTalentProfilePreview()
  }

  @Action
  public [GET_PROFILE_STUDENT_SETTINGS] (): Promise<SimpleServerResponse> {
    return StudentService.getProfileStudentSettings().then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action
  public [SET_PROFILE_STUDENT_SETTINGS] (params: StudentProfileSettings): Promise<SimpleServerResponse> {
    return StudentService.setProfileStudentSettings(params).then(data => {
      return data.data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [INQUIRE_COMPANY] (data: InquiryDto): Promise<InquiryDto> {
    return StudentService.inquiryCompany(data)
  }

  @Action({ rawError: true })
  public [INQUIRE_COMPANY_CANCEL] (data: GenericRequest): Promise<SimpleServerResponse> {
    return StudentService.cancelInquiryByTalent(data)
  }

  @Mutation
  public [MU_SET_PHOTO_FILENAME] (filename: string | undefined): void {
    this.photoFileName = filename
  }
}
export default StudentState
