import { render, staticRenderFns } from "./MetaMenuChangeLanguage.vue?vue&type=template&id=1c2e8182&scoped=true&"
import script from "./MetaMenuChangeLanguage.vue?vue&type=script&lang=ts&"
export * from "./MetaMenuChangeLanguage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c2e8182",
  null
  
)

export default component.exports