































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import BaseInput from '@/components/common/BaseInput.vue'
import { State } from 'vuex-class'
import { KeyValue } from '@/store/commonFormInput/types'
@Component({
  components: { BaseInput }
})
/*
This input element allows user input, which filters a given list of options.
The user can now select an predefinded option.
@group INPUT ELEMENTS
 */
export default class AutocompleteInput extends Vue {
  $refs: {
    input: HTMLFormElement;
    options: HTMLElement;
    dropdown: HTMLElement;
  }

  @State('currentScreenWidth') screenWidth: number

  // unique identifier
  @Prop({ required: true })
  id: string

  // the aria placeholder for accessibility
  @Prop()
  ariaPlaceholder: string

  // the options for the autocomplete input
  @Prop({ required: true })
  options: Array<KeyValue>

  // the model for the input data
  @Prop()
  value: string

  // if the input is disabled
  @Prop()
  disabled: boolean

  // the current state of the input field
  @Prop({ required: false })
  state: boolean | null;

  // if the input is deletable (adds an delete icon)
  @Prop({ default: false })
  deletable: boolean

  // if the delete option is disabled
  @Prop({ default: false })
  deleteDisabled: boolean

  @Watch('options')
  onOptionsChange (newOptions: Array<{ value: string; disabled?: boolean }>): void {
    this.internalOptions = newOptions
    this.internalOptions = this.loadFilteredOptions()
  }

  @Watch('value')
  onValueChange (newValue: string): void {
    this.internalModel = { value: newValue, text: newValue }
    const match = this.getMatch()
    if (match) {
      this.internalModel = match
    }
    this.internalOptions = this.loadFilteredOptions()
  }

  internalModel: KeyValue = { value: this.value, text: this.value }
  focused = false
  optionsShowing = false
  internalOptions = this.options
  maxHeight = '246'
  focusedOption = -1
  dropUp = false
  wrapper: HTMLElement | null | undefined

  get dropUpMargin (): number {
    if (this.$refs.input && this.wrapper && this.screenWidth) {
      const wrapperHeight = this.wrapper.getBoundingClientRect().height
      const inputField = this.$refs.input.$el.firstChild.firstChild.nextSibling.firstChild
      const inputHeight = inputField.getBoundingClientRect().height
      const bottomWrapper = this.wrapper.getBoundingClientRect().top + wrapperHeight
      const bottomInput = inputField.getBoundingClientRect().top + inputHeight
      const adjustToInputBottom = bottomInput - bottomWrapper
      if (this.dropUp) {
        return adjustToInputBottom - inputHeight - this.dropdownHeight()
      } else {
        return adjustToInputBottom
      }
    } else {
      return 0
    }
  }

  get minWidth (): string {
    if (this.$refs.input && this.screenWidth) {
      const inputField = this.$refs.input.$el.firstChild.firstChild.nextSibling.firstChild
      return inputField.clientWidth + 'px'
    } else {
      return '100px'
    }
  }

  get left (): string {
    if (this.$refs.input && this.screenWidth && this.wrapper) {
      const inputField = this.$refs.input.$el.firstChild.firstChild.nextSibling.firstChild
      return inputField.getBoundingClientRect().left - this.wrapper.getBoundingClientRect().left + 'px'
    } else {
      return '0'
    }
  }

  get focusedOptionInRange (): boolean {
    return (this.focusedOption >= 0 && this.focusedOption <= this.internalOptions.length)
  }

  loadFilteredOptions (): Array<KeyValue> {
    return this.options.filter(option => (option.text ? option.text.toString().toLowerCase() : '').indexOf(this.internalModel.value ? this.internalModel.value.toString().toLowerCase() : '') !== -1)
  }

  getMatch (): KeyValue | undefined {
    return this.options.find(
      option => {
        const optionValue: string = option.value ? option.value.toString().toLowerCase() : ''
        const modelValue = this.internalModel.value ? this.internalModel.value.toString().toLowerCase().trim() : ''
        return optionValue === modelValue
      }
    )
  }

  dropdownHeight (): number {
    let height = 0
    if (this.$refs.options) {
      height = this.$refs.options.clientHeight
      if (this.$refs.options.clientHeight > parseInt(this.maxHeight)) height = parseInt(this.maxHeight)
    }
    return height
  }

  mounted (): void {
    // if (this.$refs.input) this.minWidth = this.$refs.input.$el.clientWidth + 1
    // inital filtering of options
    this.internalOptions = this.loadFilteredOptions()
    this.wrapper = document.getElementById('group-' + this.id)?.parentElement?.parentElement?.parentElement?.parentElement
    const match = this.getMatch()
    if (match) {
      this.internalModel = match
    }
  }

  handleInput (input: string): void {
    this.internalModel = { value: input }
    this.$root.$emit('dismiss-alert')
    const match = this.getMatch()
    if (match) {
      this.internalModel = match
    }
    this.$emit('input', input)
    this.filterOptions()
  }

  selectOption (option: KeyValue): void {
    this.handleInput(option.value ? option.value.toString() : '')
    this.optionsShowing = false
  }

  filterOptions (): void {
    this.internalOptions = this.loadFilteredOptions()
    if (!this.focusedOptionInRange) this.setFocusOption(-1)
    if (this.internalOptions.length) {
      this.optionsShowing = true
      if (this.internalOptions.length < this.options.length && this.focusedOption === -1) this.setFocusOption(0)
    }
  }

  // does it need to "drop" up
  updateDropdownPosition (event: Event): void {
    const element = event.target as HTMLElement
    const rect = element.getBoundingClientRect()

    this.dropUp = window.innerHeight - rect.bottom < this.dropdownHeight()
  }

  // handling of keyboard usage
  keyPress (event: KeyboardEvent): void {
    if (this.internalOptions.length) {
      // keyCode is deprecated but here .key and .keyCode are used to support as many clients as possible
      // noinspection JSDeprecatedSymbols
      const key = event.key || event.keyCode
      const len = this.internalOptions.length

      if (key === 'ArrowUp' || key === '38') {
        if (this.focusedOption > 0 && this.focusedOption <= len - 1) this.focusedOption--
        else if (this.focusedOption === -1) {
          if (this.optionsShowing) this.focusedOption = len - 1
          else this.optionsShowing = true
        } else if (this.focusedOption === 0) this.focusedOption = -1
      }
      if (key === 'ArrowDown' || key === '40') {
        if (this.focusedOption >= 0 && this.focusedOption < len - 1) this.focusedOption++
        else if (this.focusedOption === -1) {
          if (this.optionsShowing) this.focusedOption = 0
          else this.optionsShowing = true
        } else if (this.focusedOption === len - 1) this.focusedOption = -1
      }
      if (key === 'Enter' || key === '13' || key === 'ArrowRight' || key === '39') {
        if (this.optionsShowing) {
          if (this.focusedOptionInRange) this.selectOption(this.internalOptions[this.focusedOption])
          else this.selectOption(this.internalOptions[0])
        }
        this.focusedOption = -1
        this.optionsShowing = false
      }
      if (key === 'Escape' || key === 'Esc' || key === '27') {
        this.focusedOption = -1
        if (!this.optionsShowing) this.$refs.input.blur()
        this.optionsShowing = false
      }

      this.setFocusOption()
    }
  }

  setFocusOption (index?: number, mouse?: boolean): void {
    if (index !== undefined) this.focusedOption = index

    if (this.$refs.options && this.$refs.options.children) {
      const arr = Array.prototype.slice.call(this.$refs.options.children)

      for (const child of arr) {
        child.style.background = ''
      }

      if (this.focusedOptionInRange && arr[this.focusedOption]) {
        // set background style for focused element (normal is green, disabled is grey
        const disabled = arr[this.focusedOption]._prevClass.includes('disabled')
        arr[this.focusedOption].style.background = disabled ? this.$colors.lightGrey1 : this.$colors.hszgGreenSecondary

        // scroll to element if needed (only keyboard input)
        if (!mouse) {
          const element = arr[this.focusedOption]
          if (element.offsetTop >= (parseInt(this.maxHeight) - element.clientHeight)) this.$refs.dropdown.scrollTop = element.offsetTop
          if (element.offsetTop < this.$refs.dropdown.scrollTop) this.$refs.dropdown.scrollTop = element.offsetTop
        }
      }
    }
  }
}
