export const UPDATE_LOGGED_IN_USER = 'updateLoggedInUser'
export const SET_AUTH = 'setAuthHeader'
export const UPDATE_STUDENT_STATE = 'action/user/studentState/update'
export const LOGIN = 'action/user/login'
export const LOGOUT = 'logout'
export const DELETE_ACCOUNT = 'action/user/delete'

export const REFRESH = 'refresh'
export const AUTH_WITH_REFRESH = 'auth/refresh'

export const REGISTER = 'action/user/register'
export const CONFIRM_REGISTRATION = 'action/user/confirmRegistration'

export const GET_USER_INFO = 'action/user/information/get'

export const REQUEST_RESET_PASSWORD = 'action/user/password/requestReset'
export const RESET_PASSWORD = 'action/user/password/reset'

export const GET_CONTACT = 'action/user/contact/get'
export const GET_JOB_FAMILIES = 'action/user/job/families/get'

export const GET_CURRENT_DECLARATION_CONTENT = 'action/user/currentDeclarationContent/get'
export const ACCEPT_DATA_PROTECTION_DECLARATION = 'action/user/acceptDataProtectionDeclaration/put'

export const GET_CURRENT_TERMS_OF_SERVICE_CONTENT = 'action/user/currentTermsOfServiceContent/get'
export const ACCEPT_TERMS_OF_SERVICE = 'action/user/acceptTermsOfService/put'

export const GET_ACCOUNT_SETTINGS = 'action/user/getAccountSettings/get'
export const SET_ACCOUNT_SETTINGS = 'action/user/setAccountSettings/put'

export const SET_PASSWORD = 'action/user/setPassword/post'

export const GET_PROFILE_KODE_STATUS = 'action/user/kode/profile/status/get'
export const SET_PROFILE_KODE_STATUS = 'action/user/kode/profile/status/set'
export const APPLY_KODE_TEST = 'action/user/kode/test/apply'

export const GET_ALL_SYNONYMS = 'action/user/synonyms/get'

export const LOAD_IMAGE = 'action/user/image/get'
export const GET_PHOTO = 'action/user/photo/get'
export const SAVE_PHOTO = 'action/user/photo/save'
export const DELETE_PHOTO = 'action/user/photo/delete'

export const GET_IS_ALIVE = 'action/user/isAlive/get'

export const GET_ALL_FAQ = 'action/user/faq/getAll'

export const SEND_CONTACT_FORM = 'action/contactForm/send'

export const GET_MESSAGES = 'action/messaging/getMessages'

export const GET_CURRENT_IMPRINT_CONTENT = 'action/user/currentImprintContent/get'

export const SET_ACTIVATION_STATUS_OWN_PROFILE = 'action/user/ownProfile/activate'
