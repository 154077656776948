export const ACCOUNT_ROLES_WRITE = 'ACCOUNT_ROLES_WRITE'
export const KODE_EVALUATION = 'KODE_EVALUATION'
export const ROLE_RIGHTS_READ = 'ROLE_RIGHTS_READ'
export const ROLE_RIGHTS_WRITE = 'ROLE_RIGHTS_WRITE'
export const SEARCH_FOR_TALENTS_BY_NAME = 'SEARCH_FOR_TALENTS_BY_NAME'
export const STUDENT_APPLICATION_DATA_READ = 'STUDENT_APPLICATION_DATA_READ'
export const STUDENT_APPLICATION_DATA_WRITE = 'STUDENT_APPLICATION_DATA_WRITE'
export const STUDENT_COMPETENCE_PROFILE_READ = 'STUDENT_COMPETENCE_PROFILE_READ'
export const STUDENT_COMPETENCE_PROFILE_WRITE = 'STUDENT_COMPETENCE_PROFILE_WRITE'
export const STUDENT_COMPETENCE_PROFILE_CREATE = 'STUDENT_COMPETENCE_PROFILE_CREATE'
export const STUDENT_COMPETENCE_PROFILE_UPDATE = 'STUDENT_COMPETENCE_PROFILE_UPDATE'
export const STUDENT_KODE_MATCHING = 'STUDENT_KODE_MATCHING'
export const STUDENT_OWN_PROFILE_READ = 'STUDENT_OWN_PROFILE_READ'
export const STUDENT_OWN_APPLICATION_DATA_WRITE = 'STUDENT_OWN_APPLICATION_DATA_WRITE'
export const TARGET_COMPETENCE_PROFILE_READ = 'TARGET_COMPETENCE_PROFILE_READ'
export const TARGET_COMPETENCE_PROFILE_EDIT = 'TARGET_COMPETENCE_PROFILE_EDIT'
export const JOB_PROFILE_READ = 'JOB_PROFILE_READ'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const KODE_TEST_READ = 'KODE_TEST_READ'
export const KODE_TEST_WRITE = 'KODE_TEST_WRITE'
export const SAVE_POLICIES = 'SAVE_POLICIES'
export const EMPLOYEE_READ_OWN_PROFILE = 'EMPLOYEE_READ_OWN_PROFILE'
export const EMPLOYEE_WRITE_OWN_PROFILE = 'EMPLOYEE_WRITE_OWN_PROFILE'
export const STUDENT_OWN_QUALIFICATION_WRITE = 'STUDENT_OWN_QUALIFICATION_WRITE'
export const STUDENT_OWN_QUALIFICATION_READ = 'STUDENT_OWN_QUALIFICATION_READ'
export const STUDENT_OWN_MATCHING = 'STUDENT_OWN_MATCHING'
export const JOB_AD_OWN_PUBLISH = 'JOB_AD_OWN_PUBLISH'
export const JOB_AD_OWN_READ = 'JOB_AD_OWN_READ'
export const JOB_AD_OWN_WRITE = 'JOB_AD_OWN_WRITE'
export const JOB_AD_OWN_MATCHING = 'JOB_AD_OWN_MATCHING'
export const SYNONYM_WRITE = 'SYNONYM_WRITE'
export const SYNONYMS_READ = 'SYNONYMS_READ'
export const JOB_AD_WRITE = 'JOB_AD_WRITE'
export const JOB_AD_READ = 'JOB_AD_READ'
export const CREATE_EVENT = 'CREATE_EVENT'
export const MANAGE_EVENT = 'MANAGE_EVENT'
export const BOOKMARK_EVENT = 'BOOKMARK_EVENT'
export const MENTOR_READ = 'MENTOR_READ'
export const MENTOR_OWN_WRITE = 'MENTOR_OWN_WRITE'
export const MENTOR_REQUEST = 'MENTOR_REQUEST'
export const INQUIRE_COMPANY = 'INQUIRE_COMPANY'
export const INQUIRE_TALENT = 'INQUIRE_TALENT'
export const INQUIRY_ANSWER = 'INQUIRY_ANSWER'
export const INQUIRY_READ_BY_COMPANY = 'INQUIRY_READ_BY_COMPANY'
export const INQUIRY_READ_BY_TALENT = 'INQUIRY_READ_BY_TALENT'
export const STUDENT_VIEW = 'STUDENT_VIEW'
export const STUDENT_OWN_VIEW = 'STUDENT_OWN_VIEW'
export const FAQ_WRITE = 'FAQ_WRITE'
export const MESSAGE_READ = 'MESSAGE_READ'
export const MESSAGE_WRITE = 'MESSAGE_WRITE'
export const DASHBOARD_WELCOME = 'DASHBOARD_WELCOME'
export const STUDENT_OWN_TARGET_JOB_AND_COMPETENCES_WRITE = 'STUDENT_OWN_TARGET_JOB_AND_COMPETENCES_WRITE'
export const DASHBOARD_ADMINISTRATION_AND_TODOS = 'DASHBOARD_ADMINISTRATION_AND_TODOS'
export const DASHBOARD_TODOS = 'DASHBOARD_TODOS'
export const DASHBOARD_EVENTS = 'DASHBOARD_EVENTS'
export const DASHBOARD_NEWS_NEW_EVENTS = 'DASHBOARD_NEWS_NEW_EVENTS'
export const DASHBOARD_NEWS_NEW_JOBADS = 'DASHBOARD_NEWS_NEW_JOBADS'
export const DASHBOARD_NEWS_NEW_MESSAGES = 'DASHBOARD_NEWS_NEW_MESSAGES'
export const DASHBOARD_NEWS_NEW_COMPETENCE_METERINGS = 'DASHBOARD_NEWS_NEW_COMPETENCE_METERINGS'
export const DASHBOARD_PROGRESS_COMPETENCE_METERING = 'DASHBOARD_PROGRESS_COMPETENCE_METERING'
export const DASHBOARD_PROGRESS_COMPETENCE_ADVANCEMENT = 'DASHBOARD_PROGRESS_COMPETENCE_ADVANCEMENT'
export const DASHBOARD_PROGRESS_COMPANY = 'DASHBOARD_PROGRESS_COMPANY'
export const DASHBOARD_PROGRESS_MENTORING = 'DASHBOARD_PROGRESS_MENTORING'
export const COMPANY_OWN_PROFILE_READ = 'COMPANY_OWN_PROFILE_READ'
export const DASHBOARD_PROGRESS_JOB_STATISTICS = 'DASHBOARD_PROGRESS_JOB_STATISTICS'
export const KODE_MARK_LOGIN_DETAILS_SENT = 'KODE_MARK_LOGIN_DETAILS_SENT'
export const ADMIN_PANEL = 'ADMIN_PANEL'
export const MODULE_MANAGEMENT = 'MODULE_MANAGEMENT'
export const I18N = 'I18N'
export const SYNONYM_GROUP = 'SYNONYM_GROUP'
export const SYNONYM_EDIT = 'SYNONYM_EDIT'
export const PROFILE_VALIDATION = 'PROFILE_VALIDATION'
export const KODE_EVALUATION_PREPARE = 'KODE_EVALUATION_PREPARE'
export const COMPANY_PROFILE_READ = 'COMPANY_PROFILE_READ'
export const RECEIVE_CONTACT_FORM_MESSAGES = 'RECEIVE_CONTACT_FORM_MESSAGES'
export const RECEIVE_EMPLOYEE_MAILS = 'RECEIVE_EMPLOYEE_MAILS'
export const COMPANY_OWN_PROFILE_WRITE = 'COMPANY_OWN_PROFILE_WRITE'
export const REPORT = 'REPORT'

export default {
  ACCOUNT_ROLES_WRITE,
  KODE_EVALUATION,
  ROLE_RIGHTS_READ,
  ROLE_RIGHTS_WRITE,
  SEARCH_FOR_TALENTS_BY_NAME,
  STUDENT_APPLICATION_DATA_READ,
  STUDENT_APPLICATION_DATA_WRITE,
  STUDENT_COMPETENCE_PROFILE_READ,
  STUDENT_COMPETENCE_PROFILE_WRITE,
  STUDENT_COMPETENCE_PROFILE_CREATE,
  STUDENT_COMPETENCE_PROFILE_UPDATE,
  STUDENT_KODE_MATCHING,
  STUDENT_OWN_PROFILE_READ,
  STUDENT_OWN_APPLICATION_DATA_WRITE,
  TARGET_COMPETENCE_PROFILE_READ,
  TARGET_COMPETENCE_PROFILE_EDIT,
  JOB_PROFILE_READ,
  CHANGE_PASSWORD,
  KODE_TEST_READ,
  KODE_TEST_WRITE,
  SAVE_POLICIES,
  EMPLOYEE_READ_OWN_PROFILE,
  EMPLOYEE_WRITE_OWN_PROFILE,
  STUDENT_OWN_QUALIFICATION_READ,
  STUDENT_OWN_QUALIFICATION_WRITE,
  STUDENT_OWN_TARGET_JOB_AND_COMPETENCES_WRITE,
  STUDENT_OWN_MATCHING,
  JOB_AD_OWN_PUBLISH,
  JOB_AD_OWN_READ,
  JOB_AD_OWN_WRITE,
  JOB_AD_OWN_MATCHING,
  SYNONYM_WRITE,
  SYNONYMS_READ,
  JOB_AD_WRITE,
  JOB_AD_READ,
  CREATE_EVENT,
  MANAGE_EVENT,
  MENTOR_READ,
  MENTOR_OWN_WRITE,
  MENTOR_REQUEST,
  BOOKMARK_EVENT,
  INQUIRE_COMPANY,
  INQUIRE_TALENT,
  INQUIRY_ANSWER,
  INQUIRY_READ_BY_COMPANY,
  INQUIRY_READ_BY_TALENT,
  STUDENT_VIEW,
  STUDENT_OWN_VIEW,
  FAQ_WRITE,
  MESSAGE_READ,
  MESSAGE_WRITE,
  DASHBOARD_ADMINISTRATION_AND_TODOS,
  DASHBOARD_TODOS,
  DASHBOARD_WELCOME,
  DASHBOARD_EVENTS,
  DASHBOARD_NEWS_NEW_EVENTS,
  DASHBOARD_NEWS_NEW_JOBADS,
  DASHBOARD_NEWS_NEW_MESSAGES,
  DASHBOARD_NEWS_NEW_COMPETENCE_METERINGS,
  DASHBOARD_PROGRESS_COMPETENCE_METERING,
  DASHBOARD_PROGRESS_COMPETENCE_ADVANCEMENT,
  DASHBOARD_PROGRESS_COMPANY,
  DASHBOARD_PROGRESS_MENTORING,
  COMPANY_OWN_PROFILE_READ,
  DASHBOARD_PROGRESS_JOB_STATISTICS,
  KODE_MARK_LOGIN_DETAILS_SENT,
  ADMIN_PANEL,
  MODULE_MANAGEMENT,
  I18N,
  SYNONYM_GROUP,
  SYNONYM_EDIT,
  KODE_EVALUATION_PREPARE,
  COMPANY_PROFILE_READ,
  PROFILE_VALIDATION,
  RECEIVE_CONTACT_FORM_MESSAGES,
  RECEIVE_EMPLOYEE_MAILS,
  COMPANY_OWN_PROFILE_WRITE,
  REPORT
}
