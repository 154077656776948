import store from '@/store'

export const hasRight = (...rights: string[]): boolean => {
  if (!store.getters.userGetter && rights.length > 0 && store.getters.userGetter.rights.length > 0) {
    return false
  }
  return rights.every((v: string) => store.getters.userGetter.rights.includes(v))
}

export default {
  hasRight
}
