










































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import ClickableIcon from './ClickableIcon.vue'
import { emailRegex } from '@/common/regex'
@Component({
  components: { ClickableIcon }
})
/*
An input element especially for email addresses
@group INPUT ELEMENTS
 */
export default class EmailInput extends Vue {
  // the model used for the data
  @Prop({ default: '' })
  value: string

  // if the input is required
  @Prop({ default: false })
  required: boolean

  // if the input element should be focused
  @Prop({ default: false })
  autofocus: boolean

  // if a trim is applied to the input
  @Prop({ default: false })
  trim: boolean

  // the aria placeholder for accessibility
  @Prop({ default: '' })
  ariaPlaceholder: string

  // unique identifier
  @Prop({ default: 'email' })
  id: string

  // if the input is disabled
  @Prop({ default: false })
  disabled: boolean

  // if an edit button is shown to and used to access the email input
  @Prop({ default: false })
  edit: boolean

  icon = 'visibility'
  internalModel = ''
  infoText: string | null = null
  state: boolean | null = null
  inputElement: HTMLInputElement | null

  email = emailRegex()

  editEmail = !this.disabled

  @Watch('value')
  onValueChange (newValue: string): void {
    this.internalModel = newValue
    this.validate(this.internalModel)
  }

  @Watch('disabled')
  onDisabledChange (newValue: boolean): void {
    this.editEmail = !newValue
  }

  get invalidMessageRequired (): string {
    return this.$i18n.t('warning.provide-required-input').toString()
  }

  get invalidMessageWrongPattern (): string {
    return this.$i18n.t('warning.invalid-email').toString()
  }

  changeEmail (): void {
    // use this later to change email
    this.editEmail = !this.editEmail
  }

  validate (input: string): void {
    this.$emit('input', input)
    if (!input) {
      this.state = null
    } else {
      this.state = RegExp(this.email).test(input)
      this.$root.$emit('dismiss-alert')
    }
  }

  mounted (): void {
    this.infoText = this.$i18n.t('info.show-password').toString()
    this.internalModel = this.value
    this.inputElement = document.getElementById(this.id) as HTMLInputElement
  }
}
