

































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Getter, State } from 'vuex-class'
import { MenuEntry } from '@/store/types'
@Component
/*
@group MENUS
The breadcrumps are shown below the main menu and help the user navigate the site.
This component is used once in the HeaderAndMainMenu page.
*/
export default class Breadcrumps extends Vue {
  @Getter('getMenuEntries') mainMenuEntries: MenuEntry[]
  @Getter('isLoggedIn') isLoggedIn: boolean
  @State('currentPage') currentPage: number[]

  // to set the menu entries for the breadcrumps (deprecated and currently not used)
  @Prop() entries: MenuEntry[]

  @Watch('entries')
  onDateChanged (newValues: MenuEntry[]): void {
    if (newValues) {
      this.crumps = newValues
    }
  }

  private crumps: MenuEntry[]

  get currentRouteName (): string | null | undefined {
    return this.$route.name
  }

  get breadcrumps (): Array<MenuEntry> {
    if (!this.entries) {
      this.crumps = []
      const steps = this.currentPage.length
      let subArray = this.mainMenuEntries
      for (let i = 0; i < steps; i++) {
        if (this.currentPage[i] !== -1) {
          subArray = this.getMenuEntry(this.currentPage[i], subArray)
        }
      }

      if (this.crumps.length === 0 && this.currentRouteName !== undefined && this.currentRouteName !== null && this.currentRouteName !== 'home') {
        const menuEntry: MenuEntry = {
          name: 'breadcrumps-outside-of-menu.' + this.currentRouteName,
          route: '#',
          disabledIndicator: null,
          children: []
        }
        this.crumps.push(menuEntry)
      }
      return this.crumps
    } else {
      return this.entries
    }
  }

  getMenuEntry (index: number, entries: MenuEntry[]): MenuEntry[] {
    if (entries.length > index) {
      this.crumps.push(entries[index])
      return entries[index].children
    } else {
      return []
    }
  }
}
