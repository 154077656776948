import { ApplicationData, BaseData, Language, Mentor, StudentProfileSettings } from '@/store/student/types'
import {
  AccountSettings,
  ChangePassword,
  ConfirmRegistrationCredentials, FAQDto,
  JobFamily,
  LoginCredentials,
  MatchingJobFamily, Messages,
  ResetPasswordCredentials,
  UserServerObject
} from '@/store/user/types'
import { AxiosResponse } from 'axios'
import { MU_DELETE_USER_TOKEN, USER_LOG_OUT } from '@/store/user/mutation.type'
import router from '@/router'
import Vue from 'vue'
import { REFRESH, SET_AUTH } from '@/store/user/actions.type'
import store from '@/store/index'
import {
  CompetenceProfileType,
  FileDto,
  FileRequest,
  GenericRequest,
  Qualification,
  SimpleServerResponse,
  TalentProfileViewType
} from '@/store/types'
import { CompanyProfile } from '@/store/company/types'
import { InquiryDto } from '@/store/inquiry/types'
import {
  CompetenceImprovement,
  CompetenceType,
  JobFinderType, KodeEvaluationDetailsSentType,
  KodeEvaluationSession, KodeEvaluationTalentData, KodeStatusDTO,
  MetaProfile,
  TalentPdfAllowance
} from '@/store/kode/types'
import { EmployeeProfile, I18n, Modul, TalentFile, TargetFile, Tasks } from '@/store/employee/types'
import { Competences, Event } from '@/store/event/types'
import { CompleteJobOffer, CourseOfStudy, JobAdType, JobFamilyJobAds, MatchingResult } from '@/store/jobAd/types'
import { MenteeData, MentorData, MentorInquiry } from '@/store/mentor/types'
import { Embedded } from '@/store/adminPanel/types'
import { ContactFormContent, CvElement } from '@/store/commonFormInput/types'
import {
  DashboardEventsType,
  DashboardMentoringType,
  DashboardNewContent,
  DashboardOpenActivationRequestsType,
  DashboardOpenInquiriesType,
  DashboardOwnOpenInquiriesType,
  DashboardOwnOpenMentorRequestsType,
  DashboardWelcomeType
} from '@/store/dashboard/types'
import { Agents, EventSearchAgentType, JobSearchAgentType } from '@/store/search/types'
import i18n from '@/i18n'

export const BASE_URL = process.env.VUE_APP_ROOT_API

const ApiService = {
  init (): void {
    Vue.axios.defaults.baseURL = process.env.VUE_APP_ROOT_API
    // 'https://hszg.kontext-e.de/api/'

    Vue.axios.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        // handle error
        if (error.message === 'CANCELLED') {
          return Promise.reject(error.message)
        }

        if (!error.response) {
          store.commit(USER_LOG_OUT)
          store.commit(MU_DELETE_USER_TOKEN)
          router.push({ path: '/' })
          window.dispatchEvent(new CustomEvent('alive-status-changed', { detail: false }))
          return
        }

        if (error.response && error.response.status === 400 && error.response.data.message.includes('Authorization')) {
          return store.dispatch(SET_AUTH).then(() => {
            return Vue.axios.request(error.config)
          })
        }
        if (error.response && error.response.status === 401) {
          if (error.request.responseURL.includes('public')) {
            return Promise.reject(error.response)
          }
          if (error.request.responseURL.includes('refresh')) {
            store.commit(USER_LOG_OUT)
            store.commit(MU_DELETE_USER_TOKEN)
            store.commit('setCurrentPage', [-1])
            router.push({ path: '/login' })
            return Promise.reject(error)
          } else {
            return store.dispatch(REFRESH).then((data) => {
              error.config.headers.Authorization = `Bearer ${data.bearer}`
              return Vue.axios.request(error.config)
            })
          }
        }

        if (error.response && error.response.status === 500) {
          error.response.data.requestStatus = '500'
          error.response.data.message = 'internal-error-message'
        }
        /*
        if (error.response && ![401, 404, 409].includes(error.response.status)) {
          store.commit(MUTATE_TOAST, {
            show: true,
            header: 'Fehler',
            content: error.response.data,
            status: error.response.status
          })
        } else if (error.response && [409].includes(error.response.status)) {
          store.commit(MUTATE_MODAL, {
            show: true,
            header: 'Achtung!',
            content: error.response.data,
            status: error.response.status
          })
        }
        */

        return Promise.reject(error.response)
      })
  },

  setHeader (): void {
    Vue.axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
    Vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    // Vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:8080'
    Vue.axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE, OPTIONS'
  },

  setFormHeader (): void {
    Vue.axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
  },

  setAuth (token: string| undefined): void {
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`
  },

  setRefreshToken (token: string): void{
    Vue.axios.defaults.headers.common.refreshToken = `Refresh ${token}`
  },

  deleteAuth (): void {
    Vue.axios.defaults.headers.common.Authorization = ''
  },

  query (resource: string, params: object): Promise<AxiosResponse> {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`)
    })
  },

  get (resource: string, params: object | undefined, query = ''): Promise<AxiosResponse> {
    return Vue.axios.get(`${resource}${query ? '?' + query : ''}`, params)
  },

  post (resource: string, params: object | undefined, query = '', config: object | undefined): Promise<AxiosResponse> {
    return Vue.axios.post(`${resource}?${query}`, params, config)
  },

  update (resource: string, slug: string, params: object): Promise<AxiosResponse> {
    return Vue.axios.put(`${resource}/${slug}`, params)
  },

  put (resource: string, params: object | undefined): Promise<AxiosResponse> {
    return Vue.axios.put(`${resource}`, params)
  },

  delete (resource: string, params: object | undefined, query = ''): Promise<AxiosResponse> {
    return Vue.axios.delete(`${resource}?${query}`, { data: params })
  }
}

export default ApiService

export const I18NService = {
  loadLanguages (): Promise<Array<I18n>> {
    return ApiService.get('/public/i18n/all', undefined, undefined).then(data => {
      data.data.content.map((lang: {lang: string; messages: string}) => { if (lang.messages) i18n.setLocaleMessage(lang.lang, JSON.parse(lang.messages.replace('\n', ' '))) })
      return data.data.content
    })
  },
  loadLanguage (lang: string): Promise<I18n> {
    return ApiService.get(`/public/i18n/${lang}`, undefined, undefined).then(data => {
      return data.data.content
    })
  },
  saveLanguages (langs: Array<I18n>): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post('/i18n/all', langs, undefined, undefined).then(data => {
      return data
    })
  }
}

export const UserService = {
  register (params: object): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'public/auth/register', params, undefined, undefined)
  },
  confirmRegistration (params: ConfirmRegistrationCredentials): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.get(
      `public/auth/verify/${params.id}/${params.nonce}`, undefined, undefined
    )
  },
  login (params: LoginCredentials): Promise<UserServerObject> {
    return ApiService.post('public/auth/login', params, undefined, undefined
    ).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data
    }).catch(error => {
      throw error.data
    })
  },
  requestPasswordReset (username: string): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.put(`public/auth/resetPassword/${username}/`, undefined)
  },
  resetPassword (params: ResetPasswordCredentials): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      `public/auth/resetPassword/${params.registrationCredentials.id}/${params.registrationCredentials.nonce}`,
      params.loginCredentials,
      undefined,
      undefined)
  },
  refresh (refreshToken: string): Promise<AxiosResponse<UserServerObject>> {
    ApiService.setRefreshToken(refreshToken)
    return ApiService.post('auth/refresh', {}, undefined, undefined)
  },
  logout (): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.get('auth/logout', undefined, undefined)
  },
  getProfilePhoto (role: string): Promise<FileDto> {
    return ApiService.get(
      'profile/photo-load', undefined, `role=${role}`
    ).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  },
  getImage (request: FileRequest): Promise<FileDto> {
    const uri = request.role === 'MENTOR' ? `/mentor/image/${request.id}` : `/profile/image/${request.role}/${request.id}`
    return ApiService.get(
      uri, undefined)
      .then((response: AxiosResponse<SimpleServerResponse>) => {
        return response.data.content
      }).catch(error => {
        throw error.data
      })
  },
  saveProfilePhoto (requestDto: FileRequest): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'profile/photo-upload', requestDto.file, `role=${requestDto.role}`, undefined
    )
  },
  deleteProfilePhoto (role: string): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'profile/photo-delete', undefined, `role=${role}`, undefined
    )
  },
  getCurrentDeclarationContent (locale: string): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.get(
      `public/auth/dpd/${locale}/get-current-declaration-content`, undefined, undefined
    )
  },
  acceptDataProtectionDeclaration (): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.put(
      'dpd/accept', undefined
    )
  },
  getCurrentTermsOfServiceContent (locale: string): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.get(
      `public/auth/tos/${locale}/get-current-terms-of-service-content`, undefined, undefined
    )
  },
  acceptTermsOfService (): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.put(
      'tos/accept', undefined
    )
  },
  getContact (contactId: string): object {
    const contacts = [
      {
        id: '0',
        title: 'Dipl.-Kfm (FH) M.A.',
        firstName: 'David',
        lastName: 'Sauer',
        email: 'D.Sauer@hszg.de',
        locations: [
          {
            title: 'Zentrum für Wissenstransfer und Bildung',
            address: {
              title: 'Standort Görlitz',
              lines: [
                'Parkstraße 2', 'Gebäude G VII, Raum 206'
              ]
            },
            phone: '03581 374-4311'
          },
          {
            title: 'Zentrum für Wissenstransfer und Bildung',
            address: {
              title: 'Standort Zittau',
              lines: [
                'Schwenninger Weg 1', 'Gebäude Z VII, Raum 409'
              ]
            },
            phone: '03583 612-4311'
          }
        ]
      }
    ]
    return contacts.filter(contact => contact.id === contactId)[0]
  },
  getJobFamilies (): JobFamily[] {
    return [
      { id: '0', title: 'Projektleiter', description: 'Projektleiter sind für die operative Planung und Steuerung des Projektes verantwortlich. Je nach Projektart sind sie in diesem Zusammenhang für das Erreichen von Sach-, Termin-, Kosten- bzw. Ausbildungszielen im Rahmen des Projekts zuständig. Im Bereich der Planung legen sie Ziele sowie benötigte Ressourcen für deren Erreichung fest.' },
      { id: '1', title: 'Personalreferent', description: 'Personalreferent ist eine berufliche Tätigkeit im Bereich der Personalverwaltung und Personalentwicklung.\n\nZu den typischen Aufgaben von Personalreferenten gehören die Auswahl und Einstellung neuer Mitarbeiter, die Betreuung des vorhandenen Personals und die Beratung von Führungskräften. Personalreferenten formulieren Arbeitsverträge und Arbeitszeugnisse, kalkulieren Personalkosten und verhandeln mit den betrieblichen Interessenvertretungen. Zwecks Personalentwicklung planen sie Schulungen, Seminare und andere Weiterbildungsmaßnahmen. ' },
      { id: '2', title: 'Einkäufer', description: 'Als Einkäufer werden die Mitarbeiter eines Unternehmens bezeichnet, die für den Einkauf von Waren, Lebensmitteln, Material und Dienstleistungen zuständig sind. Zusammengefasst werden diese Mitarbeiter*innen in der Einkaufsabteilung. Die Berufsbezeichnung ist nicht gesetzlich geschützt.' },
      { id: '3', title: 'Lorem', description: 'Ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.' },
      { id: '4', title: 'Ipsum dolor', description: 'At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.' },
      { id: '5', title: 'Sit amet', description: 'Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ' },
      { id: '6', title: 'Consetetur', description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. ' }
    ]
  },
  getAccountSettings (): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.get(
      'account-settings/get-account-settings', undefined, undefined
    )
  },
  setAccountSettings (params: AccountSettings): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.put(
      'account-settings/set-account-settings', params
    )
  },
  setPassword (params: ChangePassword): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'auth/setPassword', params, undefined, undefined
    )
  },
  getAllSynonyms (): Promise<Array<Qualification>> {
    return ApiService.get(
      '/synonym', undefined, undefined
    ).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  },
  getIsAlive (): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.get(
      '/public/alive', undefined, undefined
    )
  },
  sendContactForm (params: ContactFormContent): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'public/contact', params, undefined, undefined
    )
  },
  getAllFAQ (locale: string): Promise<Array<FAQDto>> {
    return ApiService.get(
      `public/faq/${locale}/get-all`, undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },
  getMessages (): Promise<Array<Messages>> {
    return ApiService.get(
      'messages/get', undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },
  deleteAccount (): Promise<SimpleServerResponse> {
    return ApiService.delete('/account', undefined).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  },
  getCurrentImprintContent (locale: string): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.get(
      `public/auth/imprint/${locale}/get-current-imprint-content`, undefined, undefined
    )
  },

  setActivationStatusForOwnProfile (requestDto: GenericRequest): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'profile/activate',
      undefined,
      `activate=${requestDto.query[0]}&profile_type=${requestDto.query[1]}`,
      undefined
    )
  }
}

export const StudentService = {
  getBaseData (): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.get(
      'profile/student', undefined, undefined)
  },

  setBaseData (params: BaseData): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'profile/student', params, undefined, undefined)
  },

  getApplicationData (): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.get(
      'profile/student/application', undefined, undefined)
  },

  setApplicationData (params: ApplicationData): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'profile/student/application', params, undefined, undefined)
  },

  getCvData (): Promise<Array<CvElement>> {
    return ApiService.get(
      'profile/student/cv', undefined, undefined
    ).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  },

  saveCvData (cvData: CvElement): Promise<CvElement> {
    return ApiService.post(
      'profile/student/cv', cvData, undefined, undefined
    ).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  },

  deleteCvData (id: string): Promise<SimpleServerResponse> {
    return ApiService.delete(
      'profile/student/cv', undefined, `id=${id}`
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  },

  getOwnLanguages (): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.get(
      'profile/languages-own', undefined, undefined)
  },

  setOwnLanguages (params: Language[]): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'profile/languages-own', { languages: params }, undefined, undefined)
  },

  getOwnInterests (): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.get(
      'profile/interests-own', undefined, undefined)
  },

  setOwnInterests (params: string[]): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'profile/interests-own', { interests: params }, undefined, undefined)
  },

  getOwnStudentCompetenceProfile (): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.get(
      'kode/student-competence-profile-own', undefined, undefined)
  },

  getOwnQualifications (): Promise<Array<Qualification>> {
    return ApiService.get('qualification/profile-own', undefined, undefined).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  },

  setOwnQualifications (params: Qualification[]): Promise<Qualification[]> {
    return ApiService.post(
      'qualification/profile-own', params, undefined, undefined
    ).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  },

  getProfileKodeStatus (): Promise<KodeStatusDTO> {
    return ApiService.get(
      'kode/profile/status', undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  setProfileKodeStatus (): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'kode/profile/status', {}, undefined, undefined)
  },

  applyKodeTest (): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'kode/profile/apply', {}, undefined, undefined)
  },

  getMatchingMentors (): Mentor[] {
    return [
      { id: '0', firstName: 'Maria', lastName: 'Kaufmann', competences: ['EIGENVERANTWORTUNG', 'TEAMFAEHIGKEIT', 'PLANUNGSFAEHIGKEIT'] },
      { id: '1', firstName: 'Thomas ', lastName: 'Schmidt', competences: ['LERNFAEHIGKEIT', 'SOZIALES_ENGAGEMENT', 'TEAMFAEHIGKEIT', 'KOMMUNIKATIONSFAEHIGKEIT'] },
      { id: '2', firstName: 'Julia ', lastName: 'Tanne', competences: ['LERNFAEHIGKEIT', 'Initiative', 'PLANUNGSFAEHIGKEIT', 'BEURTEILUNGSVERMOEGEN'] },
      { id: '3', firstName: 'Paul', lastName: 'Breit', competences: ['Initiative', 'SOZIALES_ENGAGEMENT', 'BEURTEILUNGSVERMOEGEN'] }
    ]
  },
  requestMentorAppointment (mentorId: string): string {
    return mentorId
  },
  getMatchingJobFamilies (): MatchingJobFamily[] {
    return [
      { id: '0', title: 'Projektleiter', description: 'Projektleiter sind für die operative Planung und Steuerung des Projektes verantwortlich. Je nach Projektart sind sie in diesem Zusammenhang für das Erreichen von Sach-, Termin-, Kosten- bzw. Ausbildungszielen im Rahmen des Projekts zuständig. Im Bereich der Planung legen sie Ziele sowie benötigte Ressourcen für deren Erreichung fest.', accuracy: 90 },
      { id: '1', title: 'Personalreferent', description: 'Personalreferent ist eine berufliche Tätigkeit im Bereich der Personalverwaltung und Personalentwicklung.\n\nZu den typischen Aufgaben von Personalreferenten gehören die Auswahl und Einstellung neuer Mitarbeiter, die Betreuung des vorhandenen Personals und die Beratung von Führungskräften. Personalreferenten formulieren Arbeitsverträge und Arbeitszeugnisse, kalkulieren Personalkosten und verhandeln mit den betrieblichen Interessenvertretungen. Zwecks Personalentwicklung planen sie Schulungen, Seminare und andere Weiterbildungsmaßnahmen. ', accuracy: 83 },
      { id: '2', title: 'Einkäufer', description: 'Als Einkäufer werden die Mitarbeiter eines Unternehmens bezeichnet, die für den Einkauf von Waren, Lebensmitteln, Material und Dienstleistungen zuständig sind. Zusammengefasst werden diese Mitarbeiter*innen in der Einkaufsabteilung. Die Berufsbezeichnung ist nicht gesetzlich geschützt.', accuracy: 78 }
    ]
  },
  getJobAdsToSelectedJobFamily (): Promise<JobFinderType[]> {
    return ApiService.get('match/talent', undefined, undefined).then((response: AxiosResponse<SimpleServerResponse>) => {
      const responseObject = response.data.content as Record<string, any>
      const matchedJobAds = responseObject.matchedJobAds as Array<Record<string, any>>
      return (matchedJobAds.map(entry => {
        return {
          average: entry.averageMatchingResult,
          jobTitle: entry.jobAdTitle,
          studentCompetenceProfile: responseObject.studentProfileId,
          targetCompetenceProfile: entry.jobAdId,
          competenceProfileType: responseObject.jobFamily,
          qualificationMatchingResult: entry.qualificationMatchingResult,
          competenceMatchingResult: entry.competenceMatchingResult,
          inquiryId: entry.inquiryDTO.inquiryId,
          inquiryStatus: entry.inquiryDTO.inquiryStatus,
          companyId: entry.inquiryDTO.companyId
        } as JobFinderType
      }))
    }).catch(error => {
      throw error.data
    })
  },

  getTalentView (studentId: string): Promise<TalentProfileViewType> {
    return ApiService.get(
      '/talent-view',
      undefined,
      `studentId=${studentId}`
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  inquiryCompany (requestDto: InquiryDto): Promise<InquiryDto> {
    return ApiService.post(
      'inquiry/to-company', requestDto, undefined, undefined
    ).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  },

  cancelInquiryByTalent (requestDto: GenericRequest): Promise<SimpleServerResponse> {
    return ApiService.delete(
      'inquiry/cancel-by-talent', undefined, `inquiryId=${requestDto.query[0]}`
    ).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data
    }).catch(error => {
      throw error.data
    })
  },

  getTalentProfilePreview (): Promise<TalentProfileViewType> {
    return ApiService.get(
      '/talent-view-own',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  getProfileStudentSettings (): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.get(
      'student/get-settings', undefined, undefined
    )
  },

  setProfileStudentSettings (params: StudentProfileSettings): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.put(
      'student/set-settings', params
    )
  }
}

export const CompanyService = {
  getProfile (): Promise<CompanyProfile> {
    return ApiService.get(
      'profile/company', undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  saveOwnProfile (profile: CompanyProfile): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'profile/company-own', profile, undefined, undefined
    )
  },

  inquiryTalent (requestDto: InquiryDto): Promise<InquiryDto> {
    return ApiService.post(
      'inquiry/to-talent', requestDto, undefined, undefined
    ).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  },

  cancelInquiry (requestDto: GenericRequest): Promise<SimpleServerResponse> {
    return ApiService.delete(
      'inquiry/cancel-by-company', undefined, `inquiryId=${requestDto.query[0]}`
    ).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data
    }).catch(error => {
      throw error.data
    })
  }
}

export const EmployeeService = {
  searchTalentByName (params: string): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.get(
      'profile/search-talent-by-name', undefined, `search=${params}`)
  },

  getStudentCompetenceProfile (params: string): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.get(
      'kode/student-competence-profile', undefined, `profileStudentId=${params}`)
  },

  setStudentCompetenceProfile (params: CompetenceProfileType): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'kode/student-competence-profile', params, undefined, undefined)
  },

  getEmployeeBaseData (): Promise<EmployeeProfile> {
    return ApiService.get(
      'profile/employee', undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  setEmployeeBaseData (params: EmployeeProfile): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'profile/employee', params, undefined, undefined)
  },

  getTasks (): Promise<Array<Tasks>> {
    return ApiService.get(
      'public/task/get-all', undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  uploadAndSuggestTalents (params: FileDto): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'pdf/upload-and-talent-suggestion', params, undefined, undefined
    )
  },

  getReadOutCompetencesForTalent (params: TargetFile): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.get(
      'pdf/select-talent-and-get-competences', undefined, `profileStudentId=${params.profileId}&filename=${params.filename}`
    )
  },

  uploadPDFAndGetCompetences (params: TalentFile): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'pdf/upload-and-get-competences', params.fileDTO, `profileStudentId=${params.profileId}`, undefined
    )
  },

  sendMessage (params: Messages): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'messages/save', params, undefined, undefined
    )
  },

  getRecipientGroups (): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.get(
      'messages/get-recipient-groups', undefined, undefined
    )
  },

  getSentMessages (): Promise<Array<Messages>> {
    return ApiService.get(
      'messages/get-all-saved', undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  getModules (): Promise<Array<Modul>> {
    return ApiService.get(
      'module/get-all', undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  changeModuleAvailability (params: Modul[]): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'module/change-availability', params, undefined, undefined
    )
  },

  groupSynonym (synonym: Qualification): Promise<Qualification> {
    return ApiService.post(
      'synonym/group',
      synonym,
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error
    })
  },

  editSynonym (synonym: Qualification): Promise<Qualification> {
    return ApiService.post(
      'synonym/edit',
      synonym,
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error
    })
  },

  getOpenActivationRequestsTalents (): Promise<Array<TalentProfileViewType>> {
    return ApiService.get(
      'profile/open-activation-requests/talent', undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  getOpenActivationRequestsCompanies (): Promise<Array<CompanyProfile>> {
    return ApiService.get(
      'profile/open-activation-requests/company', undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  }

}

export const AdminService = {
  getOverview (): Promise<Embedded | undefined> {
    return ApiService.get('/panel', undefined, undefined).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data._embedded
    }).catch(error => {
      throw error.data
    })
  }
}

export const JobAdService = {
  saveOwnJobAd (params: JobAdType): Promise<JobAdType> {
    return ApiService.post(
      'job-ad/own',
      params, undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  saveJobAd (params: JobAdType): Promise<JobAdType> {
    return ApiService.post(
      'job-ad/any',
      params, undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  publishOwnJobAd (jobAd: JobAdType): Promise<SimpleServerResponse> {
    return ApiService.post(
      'job-ad/publish',
      undefined,
      `jobAdId=${jobAd.jobAdId}&publish=${jobAd.isPublic}`,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  getOwnJobAds (): Promise<Array<JobAdType>> {
    return ApiService.get(
      'job-ad/own',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error
    })
  },

  getAnyJobAds (): Promise<Array<JobAdType>> {
    return ApiService.get(
      'job-ad',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  getOwnJobAd (id: string | undefined): Promise<JobAdType> {
    return ApiService.get(
      'job-ad/own-by-id',
      undefined,
      `jobAdId=${id}`
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  getAnyJobAd (id: string | undefined): Promise<JobAdType> {
    return ApiService.get(
      'job-ad',
      undefined,
      `jobAdId=${id}`
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  deleteOwnJobAd (id: string | undefined): Promise<SimpleServerResponse> {
    return ApiService.delete(
      `job-ad/own/${id}`, undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  },

  deleteAnyJobAd (id: string | undefined): Promise<SimpleServerResponse> {
    return ApiService.delete(
      `job-ad/any/${id}`, undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  },

  getJobAdMatchingResults (id: string): Promise<MatchingResult> {
    return ApiService.get(
      'match/job-ad',
      undefined,
      `jobAdId=${id}`
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  getMatchingResultEstimation (jobAd: JobAdType): Promise<number> {
    return ApiService.post(
      'match/job-ad/count',
      jobAd, undefined, { cancelToken: jobAd.cancelTokenSource?.token }
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error
    })
  },

  getJobAdById (params: { id: string; preview: boolean }): Promise<CompleteJobOffer> {
    return ApiService.get(
      params.preview ? 'job-ad/view-own' : 'job-ad/view-as-talent',
      undefined,
      `jobAdId=${params.id}`
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  getTalentViewByJobAd (request: GenericRequest): Promise<TalentProfileViewType> {
    return ApiService.get(
      'job-ad/view-talent',
      undefined,
      `jobAdId=${request.query[0]}&studentId=${request.query[1]}`
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  getAllJobAds (): Promise<Array<CompleteJobOffer>> {
    return ApiService.get(
      'job-ad/view-all',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  saveJobAdsToJobFamilies (jobAd: CompleteJobOffer): Promise<SimpleServerResponse> {
    return ApiService.post(
      'job-ad/add-job-families-to-job-ad', jobAd, undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  },

  getAllJobAdsForFamily (metaProfile: MetaProfile[]): Promise<Array<JobFamilyJobAds>> {
    return ApiService.post(
      'job-ad/ads-for-family',
      metaProfile,
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  getPorterValues (): Promise<Array<string>> {
    return ApiService.get(
      'porter-values', undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  getJobTypes (): Promise<Array<string>> {
    return ApiService.get(
      'job-types', undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  }
}

export const KodeService = {
  getAllCompetences (): Promise<Array<CompetenceType>> {
    return ApiService.get(
      'kode/competences', undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  getAllMetaProfiles (): Promise<Array<MetaProfile>> {
    return ApiService.get(
      'kode/target-competences-profiles', undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  saveMetaProfile (profile: MetaProfile): Promise<SimpleServerResponse> {
    return ApiService.post(
      'kode/target-competence-profile', profile, undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  },

  deleteMetaProfile (id: string): Promise<SimpleServerResponse> {
    return ApiService.delete(
      'kode/target-competence-profile', undefined, `id=${id}`
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  },

  getJobFinderResults (): Promise<Array<JobFinderType>> {
    return ApiService.get(
      'kode/job-finder', undefined, undefined
    ).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  },

  getKodeBridgeResult (targetCompetenceProfileId: string): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.get(
      'kode/bridge-result', undefined, `targetCompetenceProfileId=${targetCompetenceProfileId}`)
  },

  getCompetencesToImproveOwn (): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.get(
      'kode/student-competences-to-improve-own', undefined, undefined)
  },

  saveCompetencesToImproveOwn (improvement: CompetenceImprovement): Promise<AxiosResponse<SimpleServerResponse>> {
    return ApiService.post(
      'kode/student-competences-to-improve-own', improvement, undefined, undefined)
  },

  getKodeEvaluationSessions (): Promise<Array<KodeEvaluationSession>> {
    return ApiService.get(
      'kode/evaluation-sessions', undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      return error.data
    })
  },

  getKodeEvaluationSession (id: string): Promise<KodeEvaluationSession> {
    return ApiService.get(
      'kode/evaluation-session', undefined, `id=${id}`
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      return error.data
    })
  },

  saveKodeEvaluationSession (session: KodeEvaluationSession): Promise<string> {
    return ApiService.post(
      'kode/evaluation-session', session, undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  deleteKodeEvaluationSession (sessionId: string): Promise<SimpleServerResponse> {
    return ApiService.delete(
      'kode/evaluation-session', undefined, `id=${sessionId}`
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  },

  getKodeEvaluationVisibility (talentIdList: string[]): Promise<Array<TalentPdfAllowance>> {
    return ApiService.post(
      'kode/evaluations-visibility', talentIdList, undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  saveKodeEvaluationVisibility (evaluationVisibilityList: TalentPdfAllowance[]): Promise<SimpleServerResponse> {
    return ApiService.post(
      'kode/evaluations-visibility-update', evaluationVisibilityList, undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  },

  setEvaluationDetailsSend (params: KodeEvaluationDetailsSentType): Promise<SimpleServerResponse> {
    return ApiService.post(
      'kode/profile/evaluation-details-sent',
      undefined,
      `profileStudentId=${params.profileStudentId}&sent=${params.sent}`,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data
    }).catch(error => {
      throw error
    })
  },

  getWaitingForKodeDetails (): Promise<Array<KodeEvaluationTalentData>> {
    return ApiService.get(
      'kode/profile/waiting-for-kode-details', undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error
    })
  }
}

export const CourseOfStudyService = {
  getCoursesOfStudy (): Promise<Array<CourseOfStudy>> {
    return ApiService.get('public/course-of-study', undefined, undefined).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  }
}

export const EventService = {
  getAll (): Promise<Array<Event>> {
    return ApiService.get('event/all', undefined, undefined).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },
  getByCompetences (competences: Competences): Promise<Array<Event>> {
    return ApiService.post('event/all-by-competences', competences, undefined, undefined).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },
  save (event: Event): Promise<SimpleServerResponse> {
    return ApiService.post('event', event, undefined, undefined).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  },
  activate (id: string): Promise<SimpleServerResponse> {
    return ApiService.post('event/activate', undefined, `id=${id}`, undefined).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  },
  deactivate (id: string): Promise<SimpleServerResponse> {
    return ApiService.post('event/deactivate', undefined, `id=${id}`, undefined).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  },
  delete (id: string): Promise<SimpleServerResponse> {
    return ApiService.delete('event', undefined, `id=${id}`).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  },
  getBookmarked (): Promise<Array<Event>> {
    return ApiService.get('event/bookmark', undefined, undefined).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },
  bookmark (id: string | undefined, bookmark: boolean | undefined): Promise<SimpleServerResponse> {
    const promise = bookmark ? ApiService.post('event/bookmark', undefined, `id=${id}`, undefined) : ApiService.delete('event/bookmark', undefined, `id=${id}`)
    return promise.then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data
    }).catch(error => {
      throw error.data
    })
  },
  getAllTopics (): Promise<Array<string>> {
    return ApiService.get(
      'event/topics', undefined, undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  }
}

export const MentorService = {
  getMentorBaseData (): Promise<MentorData> {
    return ApiService.get('mentor/own', undefined, undefined).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  },
  setMentorBaseData (params: MentorData): Promise<SimpleServerResponse> {
    return ApiService.post('mentor/own', params, undefined, undefined).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  },
  getAllMentors (): Promise<Array<MentorData>> {
    return ApiService.get('mentor/all', undefined, undefined).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  },
  getOwnMentors (): Promise<Array<MentorData>> {
    return ApiService.get('/mentor/all-own-mentors', undefined, undefined).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  },
  getOwnReceivedOpenMentorInquiries (): Promise<Array<MentorInquiry>> {
    return ApiService.get('/mentor/own-received-open-inquiries', undefined, undefined).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  },
  getOwnSendOpenMentorInquiries (): Promise<Array<MentorInquiry>> {
    return ApiService.get('/mentor/own-sent-open-inquiries', undefined, undefined).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  },
  sendMentorRequest (params: MentorInquiry): Promise<SimpleServerResponse> {
    return ApiService.post('/mentor/request', params, undefined, undefined).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  },
  acceptMentorRequest (requestId: string): Promise<SimpleServerResponse> {
    return ApiService.post('/mentor/request/accept', undefined, `requestId=${requestId}`, undefined).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  },
  declineMentorRequest (requestId: string): Promise<SimpleServerResponse> {
    return ApiService.post('/mentor/request/decline', undefined, `requestId=${requestId}`, undefined).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  },
  cancelMentorRequest (requestId: string): Promise<SimpleServerResponse> {
    return ApiService.post('/mentor/request/cancel', undefined, `requestId=${requestId}`, undefined).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  },
  getOwnMentees (): Promise<Array<MenteeData>> {
    return ApiService.get('/mentor/all-own-mentees', undefined, undefined).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch(error => {
      throw error.data
    })
  }
}

export const InquiryService = {
  acceptDeclineInquiry (request: GenericRequest): Promise<SimpleServerResponse> {
    return ApiService.post(
      'inquiry/accept-or-decline',
      undefined,
      `accept=${request.query[0]}&inquiryId=${request.query[1]}`,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch(error => {
      throw error.data
    })
  },

  getInquiriesForTalent (status: string): Promise<InquiryDto[]> {
    return ApiService.get(
      'inquiry/list-for-own-talent',
      undefined,
      `status=${status}`
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  getInquiriesForCompany (request: GenericRequest): Promise<InquiryDto[]> {
    return ApiService.get(
      'inquiry/list-for-own-company',
      undefined,
      `status=${request.query[0]}`
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch((error) => {
      throw error.data
    })
  }
}

export const DashboardService = {
  getDashboardWelcome (): Promise<DashboardWelcomeType> {
    return ApiService.get(
      'dashboard',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  getDashboardProfilePicture (profileType: string): Promise<FileDto> {
    return ApiService.get(
      'dashboard/profile-pic',
      undefined,
      `profileType=${profileType}`
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  getDashboardEvents (): Promise<DashboardEventsType> {
    return ApiService.get(
      'dashboard/events',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  getDashboardNewsNewOwnEvents (): Promise<Array<Event>> {
    return ApiService.get(
      'dashboard/events-new-own',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  getDashboardCompetences (): Promise<CompetenceImprovement> {
    return ApiService.get(
      'dashboard/competences',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  getDashboardKodeStatus (): Promise<string> {
    return ApiService.get(
      'dashboard/kode-status',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.message
    }).catch((error) => {
      throw error.data
    })
  },

  getDashboardCompanyProgress (): Promise<CompanyProfile> {
    return ApiService.get(
      'dashboard/company-progress',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  getDashboardJobAds (): Promise<Array<JobAdType>> {
    return ApiService.get(
      'dashboard/job-ads',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  getDashboardMentoringProgress (): Promise<DashboardMentoringType> {
    return ApiService.get(
      'dashboard/mentoring',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  getDashboardNewJobAds (): Promise<DashboardNewContent> {
    return ApiService.get(
      'dashboard/new-job-ads',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  getDashboardNewsNewCompetenceMeterings (): Promise<DashboardNewContent> {
    return ApiService.get(
      'dashboard/new-competence-profiles',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  getDashboardNewMessages (): Promise<Array<Messages>> {
    return ApiService.get('dashboard/new-messages',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  getDashboardKodeApplications (): Promise<DashboardNewContent> {
    return ApiService.get('dashboard/kode-metering-applications',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  getDashboardOpenInquiries (): Promise<DashboardOpenInquiriesType> {
    return ApiService.get('dashboard/open-inquiries',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  getDashboardOwnOpenMentorRequests (): Promise<DashboardOwnOpenMentorRequestsType> {
    return ApiService.get('dashboard/own-open-mentor-requests',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  getDashboardOwnOpenInquiries (): Promise<DashboardOwnOpenInquiriesType> {
    return ApiService.get('dashboard/own-open-inquiries',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  getDashboardOpenActivationRequests (): Promise<DashboardOpenActivationRequestsType> {
    return ApiService.get('dashboard/open-activation-requests',
      undefined,
      undefined
    ).then((data: AxiosResponse<SimpleServerResponse>) => {
      return data.data.content
    }).catch((error) => {
      throw error.data
    })
  }
}

export const SearchService = {
  getEventSearchAgents (): Promise<Agents> {
    return ApiService.get(
      'search-agent/events',
      undefined,
      undefined
    ).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  saveEventSearchAgent (searchAgent: EventSearchAgentType): Promise<string> {
    return ApiService.post(
      'search-agent/events',
      searchAgent,
      undefined,
      undefined
    ).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  deleteEventSearchAgent (agentId: string): Promise<SimpleServerResponse> {
    return ApiService.delete(
      'search-agent/events',
      undefined,
      `agentId=${agentId}`
    ).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data
    }).catch((error) => {
      throw error.data
    })
  },

  getJobSearchAgents (): Promise<Agents> {
    return ApiService.get(
      'search-agent/jobs',
      undefined,
      undefined
    ).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  saveJobSearchAgents (searchAgent: JobSearchAgentType): Promise<string> {
    return ApiService.post(
      'search-agent/jobs',
      searchAgent,
      undefined,
      undefined
    ).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data.content
    }).catch((error) => {
      throw error.data
    })
  },

  deleteJobSearchAgent (agentId: string): Promise<SimpleServerResponse> {
    return ApiService.delete(
      'search-agent/jobs',
      undefined,
      `agentId=${agentId}`
    ).then((response: AxiosResponse<SimpleServerResponse>) => {
      return response.data
    }).catch((error) => {
      throw error.data
    })
  }
}

export const EvaluationService = {
  requestCSVExport (): Promise<SimpleServerResponse> {
    return ApiService.get('report/db-tables', undefined, undefined).then(
      (data: AxiosResponse<SimpleServerResponse>) => {
        return data.data
      }
    ).catch(error => {
      throw error
    })
  },

  downloadTablesCSV (dto: ConfirmRegistrationCredentials): Promise<SimpleServerResponse> {
    return ApiService.get(
      `public/download-zip/${dto.nonce}/${dto.id}`,
      { responseType: 'blob' },
      undefined
    ).then((data: AxiosResponse<Blob>) => {
      let fileName = 'database.zip'
      if (data.headers['Content-Disposition']) {
        fileName = data.headers['Content-Disposition'].split('filename=')[1]
      }

      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
        window.navigator.msSaveOrOpenBlob(new Blob([data.data], { type: 'application/zip' }),
          fileName)
      } else {
        const url = window.URL.createObjectURL(new Blob([data.data], { type: 'application/zip' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
      }

      const response: SimpleServerResponse = {
        requestStatus: data.status.toString(),
        message: data.statusText
      }
      return response
    }).catch(error => {
      throw error.data
    })
  }
}
