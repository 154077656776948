





















































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import MainMenuDesktop from '@/components/menus/main-menu/MainMenuDesktop.vue'
import ClickableIcon from '@/components/common/ClickableIcon.vue'
import breakPoints from '@/common/breakpoints'
import MainMenuMobile from '@/components/menus/main-menu/MainMenuMobile.vue'
import { Getter, State } from 'vuex-class'
import Breadcrumps from '@/components/menus/Breadcrumps.vue'

@Component({
  components: { Breadcrumps, MainMenuMobile, MainMenuDesktop, ClickableIcon }
})
/*
@group MENUS
Used in the page template (app.vue).
It includes the main menu and decidces which version (desktop or mobile) is used.
*/
export default class HeaderAndMainMenu extends Vue {
  @Getter('isStudentLoggedIn') isStudentLoggedIn: boolean
  @Getter('isCompanyLoggedIn') isCompanyLoggedIn: boolean
  @Getter('isAdminLoggedIn') isAdminLoggedIn: boolean
  @Getter('isLoggedIn') isLoggedIn: boolean
  @State('currentScreenWidth') currentScreenWidth: number

  // filled with the alive-status of the backend
  @Prop()
  isAlive: boolean

  logoSet = [
    require('@/assets/Hochschule-Zittau-Goerlitz-Logo-gruen/Logo_gruen_klein/HZG_Logo_20_RGB.png') + ' 236w',
    require('@/assets/Hochschule-Zittau-Goerlitz-Logo-gruen/Logo_gruen_mittel/HZG_Logo_50_RGB.png') + ' 591w',
    require('@/assets/Hochschule-Zittau-Goerlitz-Logo-gruen/Logo_gruen_gross/HZG_Logo_100_RGB.png') + ' 1181w'
  ];

  claimSet = [
    require('@/assets/Claim/CLAIM.png') + ' 2606w'
  ];

  logoWidth = 0

  showDesktopVariantOfMenu = false

  get sizesArray () {
    return [`${this.logoWidth}px`]
  }

  setLogoWidthAndDesktopVariant (): void {
    const logoCol = document.getElementById('logo-col')

    if (logoCol !== null) {
      const width = logoCol.getBoundingClientRect().width
      const calculatedWidth = width / 2.5
      let minWidthImage = 200
      if (this.currentScreenWidth < 300) {
        minWidthImage = this.currentScreenWidth / 2
      }
      this.logoWidth = Math.min(Math.max(calculatedWidth, minWidthImage), 300)
    }

    if (this.currentScreenWidth > breakPoints.tablet) {
      this.showDesktopVariantOfMenu = true
    } else {
      this.showDesktopVariantOfMenu = false
      this.updateFontSizeOfMenuControl()
    }
  }

  imageLoaded (): void {
    this.updateFontSizeOfMenuControl()
    this.setRowPadding()
  }

  updateFontSizeOfMenuControl (): void {
    const mobileMenuControl = document.getElementById('mobile-menu-control')
    const logoCol = document.getElementById('logo-col')
    if (mobileMenuControl !== null && mobileMenuControl.children[0] !== null && logoCol != null && logoCol.children[0] !== null && window !== null) {
      const computedSize = logoCol.children[0].getBoundingClientRect().height / 1.5
      const numberPattern = /\d+/g
      const match = window.getComputedStyle(document.body).getPropertyValue('font-size').match(numberPattern)
      if (match !== null) {
        const bodyFontSize = parseFloat(match[0])
        const size = (Math.max(computedSize, bodyFontSize))
        mobileMenuControl.style.fontSize = size.toString() + 'px'
        mobileMenuControl.children[0].setAttribute('style', 'height: ' + size.toString() + 'px')
      }
    }
  }

  setRowPadding (): void {
    const headerRow = document.getElementById('header-row')
    if (headerRow !== null) {
      const padding = headerRow.offsetHeight / 4
      headerRow.style.paddingTop = padding.toString() + 'px'
      headerRow.style.paddingBottom = padding.toString() + 'px'
    }
  }

  mounted (): void {
    this.setLogoWidthAndDesktopVariant()
    this.$nextTick(
      function () {
        window.addEventListener('resize', () => {
          this.setLogoWidthAndDesktopVariant()
        })
      }
    )
  }

  beforeDestroy (): void {
    window.removeEventListener('resize', () => {
      this.setLogoWidthAndDesktopVariant()
    })
  }
}
