export const GET_BASE_DATA_EDIT = 'action/student/baseData/get/edit'
export const SET_BASE_DATA = 'action/student/baseData/set'
export const GET_APPLICATION_DATA_EDIT = 'action/student/applicationData/get/edit'
export const SET_APPLICATION_DATA = 'action/student/applicationData/set'
export const REQUEST_MENTOR_APPOINTMENT = 'action/student/mentoring/appointment/request'
export const GET_MATCHING_JOB_FAMILIES = 'action/student/job/families/matching/get'
export const GET_JOB_ADS_TO_JOB_FAMILY = 'action/student/job/jobAds/matching/get'
export const GET_CV_DATA = 'action/student/cv-data/get'
export const SET_CV_DATA = 'action/student/cv-data/set'
export const DELETE_CV_DATA = 'action/student/cv-data/delete'
export const GET_OWN_LANGUAGES = 'action/student/cv-data/languages/get'
export const SET_OWN_LANGUAGES = 'action/student/cv-data/languages/set'
export const GET_OWN_INTERESTS = 'action/student/cv-data/interests/get'
export const SET_OWN_INTERESTS = 'action/student/cv-data/interests/set'
export const GET_OWN_STUDENT_COMPETENCE_PROFILE = 'action/student/getOwnCompetenceProfile'
export const GET_OWN_QUALIFICATIONS = 'action/student/qualifications/get'
export const SET_OWN_QUALIFICATIONS = 'action/student/qualifications/set'
export const GET_TALENT_VIEW = 'action/student/view/get'
export const GET_TALENT_PROFILE_PREVIEW = 'action/student/profile/preview'
export const GET_PROFILE_STUDENT_SETTINGS = 'action/student/settings/get'
export const SET_PROFILE_STUDENT_SETTINGS = 'action/student/settings/set'
export const INQUIRE_COMPANY = 'action/student/inquiry/company/post'
export const INQUIRE_COMPANY_CANCEL = 'action/student/inquiry/company/delete'
