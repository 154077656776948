const uploadFiles = (fieldName: string, files: File[], maxSize: number) => {
  return Array.from(files).map((imageFile) => {
    // check if user actually selected a file
    const size = imageFile.size / maxSize / maxSize
    if (!imageFile.type.match('image.*')) {
      // check whether the upload is an image
      // console.log('Please choose an image file')
    } else if (size > 1) {
      // check whether the size is greater than the size limit
      // console.log(`Your file is too big! Please select an image under ${maxSize / 1024}MB`)
    } else {
      // Append file into FormData & turn file into image URL
      return URL.createObjectURL(imageFile)
    }
  })
}

function checkFileSize (file: File, maxSizeInMB: number): string | undefined {
  // check if user actually selected a file
  const size = file.size
  // check whether the size is greater than the size limit
  if (size > maxSizeInMB * 1024 * 1024) {
    return undefined
  } else {
    // Append file into FormData & turn file into image URL
    return URL.createObjectURL(file)
  }
}

export default { checkFileSize, uploadFiles }
