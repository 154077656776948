import { MenuEntry } from '@/store/types'
import MainMenuDisabled from '@/store/common/MainMenuDisabled'

const mainMenuEntries: Array<MenuEntry> = [
  {
    name: 'main-menu.dashboard',
    route: 'dashboard',
    disabledIndicator: null,
    children: []
  },
  {
    name: 'main-menu.profile',
    route: '#',
    disabledIndicator: null,
    children: [
      {
        name: 'main-menu-student.base-data',
        route: 'student-profile-editor',
        disabledIndicator: null,
        children: []
      },
      {
        name: 'main-menu-student.application-data',
        route: 'student-application-editor',
        disabledIndicator: null,
        children: []
      },
      {
        name: 'main-menu-student.cv-data',
        route: 'student-cv-data-editor',
        disabledIndicator: null,
        children: []
      },
      {
        name: 'main-menu-student.qualifications',
        route: 'student-qualifications-editor',
        disabledIndicator: null,
        children: []
      },
      {
        name: 'main-menu-company.profile',
        route: 'company-profile-editor',
        disabledIndicator: null,
        children: []
      },
      {
        name: 'main-menu-hszg.profile',
        route: 'hszg-profile-editor',
        disabledIndicator: null,
        children: []
      },
      {
        name: 'main-menu.mentor-profile',
        route: 'mentor-profile',
        disabledIndicator: null,
        children: []
      },
      {
        name: 'main-menu.settings',
        route: 'settings',
        disabledIndicator: null,
        children: []
      },
      {
        name: 'main-menu-hszg.messaging',
        route: 'hszg-messaging',
        disabledIndicator: null,
        children: [
          {
            name: 'main-menu-hszg.messaging-send-messages',
            route: 'hszg-messaging-send-messages',
            disabledIndicator: null,
            meta: { rights: ['MESSAGE_WRITE'] },
            children: []
          },
          {
            name: 'main-menu-hszg.messaging-sent-messages',
            route: 'hszg-messaging-sent-messages',
            disabledIndicator: null,
            meta: { rights: ['MESSAGE_WRITE'] },
            children: []
          },
          {
            name: 'main-menu-hszg.messaging-received-messages',
            route: 'hszg-messaging-received-messages',
            disabledIndicator: null,
            children: []
          }
        ]
      }
    ]
  },
  {
    name: 'main-menu-student.competences',
    route: 'kode-metering',
    disabledIndicator: null,
    children: []
  },
  {
    name: 'main-menu-student.development-goal',
    route: 'student-job-finder',
    disabledIndicator: null,
    children: [
      {
        name: 'main-menu-student.job-finder',
        route: 'student-job-finder',
        disabledIndicator: null,
        children: []
      },
      {
        name: 'main-menu-student.dream-job',
        route: 'student-dream-job',
        disabledIndicator: null,
        children: []
      }
    ]
  },
  /*
  {
    name: 'main-menu-student.inquiries-applications',
    route: '#',
    children: [
      {
        name: 'main-menu-student.inquiries',
        route: 'student-company-inquiries',
        children: []
      },
      {
        name: 'main-menu-student.releases',
        route: '#',
        children: []
      },
      {
        name: 'main-menu-student.my-applications',
        route: '#',
        children: []
      }
    ]
  },
  {
    name: 'main-menu-student.events',
    route: '#',
    children: [
      {
        name: 'main-menu-student.overview',
        route: 'student-events-overview',
        children: []
      },
      {
        name: 'main-menu-student.my-events',
        route: '#',
        children: []
      },
      {
        name: 'main-menu-student.event-search',
        route: 'student-event-search',
        children: []
      }
    ]
  } */
  {
    name: 'main-menu-company.job-ads',
    route: '#',
    disabledIndicator: null,
    children: [
      {
        name: 'main-menu-company.new-job-ad',
        route: 'company-job-editor',
        disabledIndicator: null,
        children: []
      },
      {
        name: 'main-menu-company.job-ad-overview',
        route: 'company-job-ad-overview',
        disabledIndicator: null,
        children: []
      },
      {
        name: 'main-menu-company.job-ad-applications',
        route: 'company-job-ad-applications',
        disabledIndicator: null,
        children: []
      }
    ]
  },
  {
    name: 'main-menu-company.talent-matching',
    route: '#',
    disabledIndicator: null,
    children: [
      {
        name: 'main-menu-company.candidate-search',
        route: 'company-candidate-search',
        disabledIndicator: null,
        children: [
          {
            name: 'main-menu-company.company-matching-result-detail',
            route: 'company-matching-result-detail',
            disabledIndicator: MainMenuDisabled.DO_NOT_SHOW_IN_MENU,
            children: []
          }
        ]
      },
      {
        name: 'main-menu-company.candidate-overview',
        route: 'company-talent-inquiries',
        disabledIndicator: null,
        children: []
      }
    ]
  },
  {
    name: 'main-menu-student.support',
    route: '#',
    disabledIndicator: null,
    children: [
      {
        name: 'main-menu-student.development-plan',
        route: 'student-development-plan',
        disabledIndicator: null,
        children: []
      },
      {
        name: 'main-menu-student.mentoring',
        route: 'student-mentoring',
        disabledIndicator: null,
        children: []
      },
      {
        name: 'main-menu-student.jobs-to-job-family',
        route: 'student-jobs-to-job-family',
        disabledIndicator: null,
        children: []
      },
      {
        name: 'main-menu-student.company-inquiries',
        route: 'student-company-inquiries',
        disabledIndicator: null,
        children: [
          {
            name: 'main-menu-student.inquiry-job-ad',
            route: 'inquiry-job-ad',
            disabledIndicator: MainMenuDisabled.DO_NOT_SHOW_IN_MENU,
            children: []
          }
        ]
      },
      {
        name: 'main-menu-student.sent-applications',
        route: 'student-sent-applications',
        disabledIndicator: null,
        children: [
          {
            name: 'main-menu-student.inquiry-job-ad',
            route: 'sent-applications-job-ad',
            disabledIndicator: MainMenuDisabled.DO_NOT_SHOW_IN_MENU,
            children: []
          }
        ]
      },
      {
        name: 'main-menu-student.events',
        route: 'student-events-overview',
        disabledIndicator: null,
        children: []
      }
    ]
  },
  {
    name: 'main-menu-hszg.kode',
    route: '#',
    disabledIndicator: null,
    children: [
      {
        name: 'main-menu-hszg.kode-score-administration',
        route: 'hszg-kode-evaluation-dashboard',
        disabledIndicator: null,
        children: [
          {
            name: 'main-menu-hszg.kode-evaluation-single-person',
            route: 'hszg-kode-evaluation-single-person',
            disabledIndicator: null,
            children: []
          },
          {
            name: 'main-menu-hszg.kode-evaluation-sessions',
            route: 'hszg-kode-evaluation-sessions',
            disabledIndicator: null,
            children: [
              {
                name: 'main-menu-hszg.hszg-kode-evaluation-session',
                route: 'hszg-kode-evaluation-session',
                disabledIndicator: MainMenuDisabled.DO_NOT_SHOW_IN_MENU,
                children: []
              }
            ]
          },
          {
            name: 'main-menu-hszg.kode-evaluation-session-create',
            route: 'hszg-kode-evaluation-session-create',
            disabledIndicator: null,
            children: []
          },
          {
            name: 'main-menu-hszg.kode-evaluation-details-sent',
            route: 'hszg-kode-evaluation-details-sent',
            disabledIndicator: null,
            children: []
          }
        ]
      },
      {
        name: 'main-menu-hszg.meta-target-profiles-administration',
        route: 'hszg-meta-target-profiles',
        disabledIndicator: null,
        children: []
      },
      {
        name: 'main-menu-hszg.target-profiles-administration',
        route: 'hszg-target-profiles',
        disabledIndicator: null,
        children: []
      }
    ]
  },
  {
    name: 'main-menu-hszg.evaluation',
    route: 'hszg-evaluation',
    disabledIndicator: null,
    children: []
  },
  {
    name: 'main-menu-hszg.content',
    route: '#',
    disabledIndicator: null,
    children: [
      {
        name: 'main-menu-hszg.profile-activations',
        route: 'hszg-profile-activations',
        disabledIndicator: null,
        children: []
      },
      {
        name: 'main-menu-hszg.content-administration',
        route: 'hszg-content-administration',
        disabledIndicator: null,
        children: []
      },
      {
        name: 'main-menu-hszg.qualifications-administration',
        route: 'hszg-qualifications-administration',
        disabledIndicator: null,
        children: []
      },
      // {
      //   name: 'main-menu-hszg.calendar-administration',
      //   route: 'hszg-calendar-administration',
      //   disabledIndicator: MainMenuDisabled.DO_NOT_SHOW_IN_MENU,
      //   children: []
      // },
      {
        name: 'main-menu-hszg.module-management',
        route: 'hszg-module-management',
        disabledIndicator: null,
        children: []
      },
      {
        name: 'main-menu-hszg.language-management',
        route: 'hszg-language-management',
        disabledIndicator: null,
        children: []
      }
    ]
  }
  // {
  //   name: 'main-menu-hszg.mentoring',
  //   route: '#',
  //   disabledIndicator: MainMenuDisabled.DO_NOT_SHOW_IN_MENU,
  //   children: [
  //     {
  //       name: 'main-menu-hszg.mentoring-administration',
  //       route: 'hszg-mentoring-administration',
  //       disabledIndicator: null,
  //       children: []
  //     },
  //     {
  //       name: 'main-menu-hszg.role-assignment-mentoring',
  //       route: 'hszg-role-assignment-mentoring',
  //       disabledIndicator: null,
  //       children: []
  //     }
  //   ]
  // }
  // {
  //   name: 'main-menu-hszg.admin',
  //   route: '#',
  //   disabledIndicator: 'admin',
  //   children: [
  //     {
  //       name: 'main-menu-hszg.rights-management',
  //       route: 'hszg-rights-management',
  //       disabledIndicator: null,
  //       children: []
  //     },
  //     {
  //       name: 'main-menu-hszg.role-assignment',
  //       route: 'hszg-role-assignment',
  //       disabledIndicator: null,
  //       children: []
  //     },
  //     {
  //       name: 'main-menu-hszg.module-management',
  //       route: 'hszg-module-management',
  //       disabledIndicator: null,
  //       children: []
  //     }
  //   ]
  // }
]

export default mainMenuEntries
