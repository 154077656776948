export const USER_LOG_IN = 'userLogIn'
export const USER_LOG_OUT = 'userLogOut'

export const MU_GET_USER_INFO = 'mutation/user/information/get'
export const MU_UPDATE_STUDENT_STATE = 'mutation/user/studentState/get'

export const MU_SET_USER_TOKEN = 'mutation/user/token/set'
export const MU_DELETE_USER_TOKEN = 'mutation/user/token/delete'

export const MU_USER_SET_PHOTO_FILENAME = 'mutation/user/photoFilename'
