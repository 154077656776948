


































import { Component, Vue } from 'vue-property-decorator'
import ClickableIcon from '@/components/common/ClickableIcon.vue'
@Component({
  components: { ClickableIcon }
})
/*
@group MENUS
The search module of the meta menu - currently it links to the global hszg.de search
*/
export default class MetaMenuSearch extends Vue {
}
