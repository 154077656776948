export const GET_ALL_META_PROFILES = 'action/kode/metaProfiles/get'
export const GET_ALL_COMPETENCES = 'action/kode/competences/get/all'
export const TRANSFORM_COMPETENCE_CORRIDORS_FOR_FRONTEND = 'action/kode/competenceCorridors/transformFrontend'
export const TRANSFORM_COMPETENCE_CORRIDORS_FOR_BACKEND = 'action/kode/competenceCorridors/transformBackend'
export const SAVE_META_PROFILE = 'action/kode/metaProfile/save'
export const DELETE_META_PROFILE = 'action/kode/metaProfile/delete'
export const GET_JOB_FINDER_RESULTS = 'action/kode/jobfinder/get'
export const GET_KODE_BRIDGE_RESULT = 'action/kode/bridge/result/get'
export const GET_COMPETENCES_TO_IMPROVE_OWN = 'action/kode/competences/improve/own/get'
export const SAVE_COMPETENCES_TO_IMPROVE_OWN = 'action/kode/competences/improve/own/save'
export const GET_ALL_KODE_EVALUATION_SESSION = 'action/kode/evaluationSession/all/get'
export const GET_KODE_EVALUATION_SESSION = 'action/kode/evaluationSession/get'
export const SAVE_KODE_EVALUATION_SESSION = 'action/kode/evaluationSession/save'
export const DELETE_KODE_EVALUATION_SESSION = 'action/kode/evaluationSession/delete'
export const GET_KODE_EVALUATION_VISIBILITY = 'action/kode/evaluationVisibility/get'
export const SAVE_KODE_EVALUATION_VISIBILITY = 'action/kode/evaluationVisibility/save'
export const SET_EVALUATION_DETAILS_SENT = 'action/kode/evaluationDetailsSend/set'
export const GET_WAITING_FOR_KODE_DETAILS = 'action/kode/waitingForKodeDetails/get'
