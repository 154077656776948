import { SimpleServerResponse } from '@/store/types'
import { VuexModule, Module, Action } from 'vuex-module-decorators'
import { MentorService } from '@/common/api.service'
import {
  GET_MENTOR_BASE_DATA,
  SET_MENTOR_BASE_DATA,
  GET_ALL_MENTORS,
  GET_OWN_MENTORS,
  GET_OWN_RECEIVED_OPEN_MENTOR_INQUIRIES,
  GET_OWN_SENT_OPEN_MENTOR_INQUIRIES,
  SEND_MENTOR_REQUEST,
  ACCEPT_MENTOR_REQUEST,
  DECLINE_MENTOR_REQUEST,
  CANCEL_MENTOR_REQUEST,
  GET_OWN_MENTEES
} from '@/store/mentor/actions.type'
import { MenteeData, MentorData, MentorInquiry } from '@/store/mentor/types'

@Module
class MentorState extends VuexModule {
  @Action({ rawError: true })
  public [GET_MENTOR_BASE_DATA] (): Promise<MentorData> {
    return MentorService.getMentorBaseData().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [SET_MENTOR_BASE_DATA] (params: MentorData): Promise<SimpleServerResponse> {
    return MentorService.setMentorBaseData(params).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_ALL_MENTORS] (): Promise<Array<MentorData>> {
    return MentorService.getAllMentors().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_OWN_MENTORS] (): Promise<Array<MentorData>> {
    return MentorService.getOwnMentors().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_OWN_RECEIVED_OPEN_MENTOR_INQUIRIES] (): Promise<Array<MentorInquiry>> {
    return MentorService.getOwnReceivedOpenMentorInquiries().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_OWN_SENT_OPEN_MENTOR_INQUIRIES] (): Promise<Array<MentorInquiry>> {
    return MentorService.getOwnSendOpenMentorInquiries().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [SEND_MENTOR_REQUEST] (params: MentorInquiry): Promise<SimpleServerResponse> {
    return MentorService.sendMentorRequest(params).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [ACCEPT_MENTOR_REQUEST] (requestId: string): Promise<SimpleServerResponse> {
    return MentorService.acceptMentorRequest(requestId).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [DECLINE_MENTOR_REQUEST] (requestId: string): Promise<SimpleServerResponse> {
    return MentorService.declineMentorRequest(requestId).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [CANCEL_MENTOR_REQUEST] (requestId: string): Promise<SimpleServerResponse> {
    return MentorService.cancelMentorRequest(requestId).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_OWN_MENTEES] (): Promise<Array<MenteeData>> {
    return MentorService.getOwnMentees().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }
}
export default MentorState
