import axios, { CancelTokenSource } from 'axios'
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import {
  GET_JOB_TYPES,
  GET_PORTER_VALUES,
  GET_COURSES_OF_STUDY,
  SAVE_JOB_AD,
  PUBLISH_JOB_AD,
  GET_JOB_ADS,
  DELETE_JOB_AD,
  GET_JOB_AD,
  GET_JOB_AD_MATCHING_RESULTS,
  GET_MATCHING_RESULTS_ESTIMATION,
  GET_JOB_AD_BY_ID,
  GET_TALENT_VIEW_BY_JOB_AD,
  GET_ALL_JOB_ADS,
  GET_ALL_JOB_ADS_FOR_FAMILY,
  SAVE_JOB_FAMILIES_TO_JOB_AD
} from '@/store/jobAd/actions'
import {
  MU_SET_JOB_TYPES,
  MU_SET_CANCELTOKENSOURCE, MU_SET_PORTER_VALUES
} from '@/store/jobAd/mutations'
import { GenericRequest, SimpleServerResponse, TalentProfileViewType } from '@/store/types'
import { CourseOfStudyService, JobAdService } from '@/common/api.service'
import {
  CourseOfStudy,
  JobAdType,
  JobAdSpecs,
  CompleteJobOffer,
  MatchingResult,
  JobFamilyJobAds
} from '@/store/jobAd/types'
import { MetaProfile } from '@/store/kode/types'

@Module
class JobAdState extends VuexModule {
  jobTypes: string[] | undefined = undefined
  porterValues: string[] | undefined = undefined
  coursesOfStudy: CourseOfStudy[] | undefined = undefined
  languages: string[] | undefined
  lastCancelTokenSource?: CancelTokenSource

  @Action({ rawError: true })
  public [GET_JOB_TYPES] (): Promise<Array<string>> {
    if (this.jobTypes === undefined) {
      return JobAdService.getJobTypes().then(data => {
        this.context.commit(MU_SET_JOB_TYPES, data)
        return data
      })
    } else {
      return Promise.resolve(this.jobTypes)
    }
  }

  @Action({ rawError: true })
  public [GET_PORTER_VALUES] (): Promise<Array<string>> {
    if (this.porterValues === undefined) {
      return JobAdService.getPorterValues().then(data => {
        this.context.commit(MU_SET_PORTER_VALUES, data)
        return data
      })
    } else {
      return Promise.resolve(this.porterValues)
    }
  }

  @Action({ rawError: true })
  public [GET_COURSES_OF_STUDY] (): Promise<CourseOfStudy[]> {
    return CourseOfStudyService.getCoursesOfStudy()
  }

  @Action({ rawError: true })
  public [SAVE_JOB_AD] (jobAd: JobAdType): Promise<JobAdType> {
    if (jobAd.isOwn) {
      return JobAdService.saveOwnJobAd(jobAd)
    } else {
      return JobAdService.saveJobAd(jobAd)
    }
  }

  @Action({ rawError: true })
  public [PUBLISH_JOB_AD] (jobAd: JobAdType): Promise<SimpleServerResponse> {
    jobAd.isPublic = !!jobAd.publish
    if (jobAd.isOwn) {
      return JobAdService.publishOwnJobAd(jobAd)
    } else {
      throw new Error('keine Berechtigung')
    }
  }

  @Action({ rawError: true })
  public [GET_JOB_ADS] (getOwn: boolean): Promise<Array<JobAdType>> {
    if (getOwn) {
      return JobAdService.getOwnJobAds()
    } else {
      return JobAdService.getAnyJobAds()
    }
  }

  @Action({ rawError: true })
  public [GET_JOB_AD] (specs: JobAdSpecs): Promise<JobAdType> {
    if (specs.isOwn) {
      return JobAdService.getOwnJobAd(specs.id)
    } else {
      return JobAdService.getAnyJobAd(specs.id)
    }
  }

  @Action({ rawError: true })
  public [DELETE_JOB_AD] (specs: JobAdSpecs): Promise<SimpleServerResponse> {
    if (specs.isOwn) {
      return JobAdService.deleteOwnJobAd(specs.id)
    } else {
      return JobAdService.deleteAnyJobAd(specs.id)
    }
  }

  @Action({ rawError: true })
  public [GET_JOB_AD_MATCHING_RESULTS] (jobAdId: string): Promise<MatchingResult> {
    return JobAdService.getJobAdMatchingResults(jobAdId)
  }

  @Action({ rawError: true })
  public [GET_MATCHING_RESULTS_ESTIMATION] (jobAd: JobAdType): Promise<number> {
    if (this.lastCancelTokenSource) {
      this.lastCancelTokenSource.cancel('CANCELLED')
    }

    const newToken = axios.CancelToken.source()
    this.context.commit(MU_SET_CANCELTOKENSOURCE, newToken)
    return JobAdService.getMatchingResultEstimation({ ...jobAd, cancelTokenSource: newToken }).then(data => {
      return data
    }).catch(error => {
      throw error
    })
  }

  @Action({ rawError: true })
  public [GET_JOB_AD_BY_ID] (params: { id: string; preview: boolean }): Promise<CompleteJobOffer> {
    return JobAdService.getJobAdById(params).then(data => {
      return data
    }).catch(error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_TALENT_VIEW_BY_JOB_AD] (request: GenericRequest): Promise<TalentProfileViewType> {
    return JobAdService.getTalentViewByJobAd(request).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_ALL_JOB_ADS] (): Promise<Array<CompleteJobOffer>> {
    return JobAdService.getAllJobAds().then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [SAVE_JOB_FAMILIES_TO_JOB_AD] (jobAd: CompleteJobOffer): Promise<SimpleServerResponse> {
    return JobAdService.saveJobAdsToJobFamilies(jobAd).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Action({ rawError: true })
  public [GET_ALL_JOB_ADS_FOR_FAMILY] (metaProfile: MetaProfile[]): Promise<Array<JobFamilyJobAds>> {
    return JobAdService.getAllJobAdsForFamily(metaProfile).then(data => {
      return data
    }, error => {
      throw error.data
    })
  }

  @Mutation
  public [MU_SET_JOB_TYPES] (data: string[]): void {
    this.jobTypes = data
  }

  @Mutation
  public [MU_SET_PORTER_VALUES] (data: string[]): void {
    this.porterValues = data
  }

  @Mutation
  public [MU_SET_CANCELTOKENSOURCE] (source: CancelTokenSource): void {
    this.lastCancelTokenSource = source
  }
}

export default JobAdState
