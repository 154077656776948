




















































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import ClickableIcon from './ClickableIcon.vue'
import { passwordRegex } from '@/common/regex'
@Component({
  components: { ClickableIcon }
})
/*
An input element especially for passwords
@group INPUT ELEMENTS
 */
export default class PasswordInput extends Vue {
  // the model used for the data
  @Prop({ default: '' })
  value: string

  // if the input is required
  @Prop({ default: false })
  required: boolean

  // if a trim is applied to the input
  @Prop({ default: false })
  trim: boolean

  // The state for the input field. null equals no state set, true is a valid state, false is an invalid state
  @Prop({ default: null })
  state: boolean | null

  // unique identifier
  @Prop({ default: 'password' })
  id: string

  // the aria placeholder for accessibility
  @Prop()
  ariaPlaceholder: string

  // if the password strength should be shown
  @Prop({ default: false })
  showStrength: boolean

  // if a password pattern should be checked
  @Prop({ default: true })
  checkPattern: boolean

  // the message for an invalid input
  @Prop({ default: '' })
  invalidMessage: string

  type = 'password'
  icon = 'visibility'
  internalModel = ''
  infoText = ''
  validationState: boolean | null = null
  inputElement: HTMLInputElement
  passwordStrengthInWords = ''
  invalidMessageRequired = ''

  password = passwordRegex()

  toggleType (): void {
    if (this.type === 'password') {
      this.type = 'text'
      this.icon = 'visibility_off'
      this.infoText = this.$i18n.t('info.hide-password').toString()
    } else {
      this.type = 'password'
      this.icon = 'visibility'
      this.infoText = this.$i18n.t('info.show-password').toString()
    }
  }

  passCheck (): void {
    const password = this.internalModel

    const hasEightCharacters = password.length >= 8
    const hasTwelveCharacters = password.length >= 12
    const containsCapitalLetter = password.match(/[A-Z]/g)
    const containsLowerLetter = password.match(/[a-z]/g)
    const containsDigit = password.match(/\d/g)
    const containsSpecialCharacter = password.match(/[\W_]/g)

    let score = 0

    if (hasEightCharacters && containsLowerLetter && containsCapitalLetter && containsDigit) {
      score = 1

      if (containsSpecialCharacter || hasTwelveCharacters) {
        score = 2
      }

      if (containsSpecialCharacter && hasTwelveCharacters) {
        score = 3
      }
    }

    this.setColor(score)
  }

  setColor (strength: number): void {
    const classes = ['weak', 'fair', 'good', 'strong']

    const bar = document.getElementById(this.id + '-strength-bar')
    if (bar !== null) {
      bar.classList.remove(...classes)

      if (this.internalModel.length === 0) {
        this.passwordStrengthInWords = ''
      } else {
        bar.classList.add(classes[strength])
        this.passwordStrengthInWords = 'password-strength.' + classes[strength]
      }
    }
  }

  validate (): void {
    if (this.inputElement === null) {
      this.validationState = null
    } else {
      this.validationState = this.inputElement.checkValidity()
    }

    if (this.showStrength) {
      this.passCheck()
    }
  }

  @Watch('value')
  onValueChange (newValue: string): void {
    this.internalModel = newValue
    this.validationState = null
  }

  mounted (): void {
    this.infoText = this.$i18n.t('info.show-password').toString()
    this.internalModel = this.value
    this.inputElement = document.getElementById(this.id + '-input') as HTMLInputElement
    this.invalidMessageRequired = this.$i18n.t('warning.provide-required-input').toString()
  }
}
