




































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
@Component
/*
This modal can be called every time a confirmation for an action is needed.
 */
export default class ConfirmationModal extends Vue {
  // title for the modal dialog
  @Prop({ required: true })
  title: string

  // a custom alert if needed
  @Prop()
  alert: string

  // the size of the modal
  @Prop({ default: 'md' })
  size: string

  // unique identifier
  @Prop({ required: true })
  id: string

  // the i18n key for the confirm caption
  @Prop({ default: 'actions.ok' })
  confirmCaption: string

  // the variant for the confirm button
  @Prop({ default: 'primary' })
  confirmVariant: string

  // the i18n key for the cancel caption
  @Prop({ default: 'actions.cancel' })
  cancelCaption: string
}
