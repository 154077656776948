export const GET_JOB_TYPES = 'action/jobAd/jobTypes/get'
export const GET_COURSES_OF_STUDY = 'action/jobAd/coursesOfStudy/get/all'
export const GET_PORTER_VALUES = 'action/jobAd/porterValues/get'

export const SAVE_JOB_AD = 'action/jobAd/save'
export const PUBLISH_JOB_AD = 'action/jobAd/publish'
export const GET_JOB_ADS = 'action/jobAd/get/all'
export const GET_JOB_AD = 'action/jobAd/get'
export const DELETE_JOB_AD = 'action/jobAd/delete'

export const GET_JOB_AD_MATCHING_RESULTS = 'action/jobAd/get/matchingResults'
export const GET_MATCHING_RESULTS_ESTIMATION = 'action/jobAd/matchingResults/estimate'

export const GET_JOB_AD_BY_ID = 'action/jobAd/byId/get'
export const GET_TALENT_VIEW_BY_JOB_AD = 'action/jobAd/byId/talentview/get'

export const GET_ALL_JOB_ADS = 'action/jobAd/getAll'
export const SAVE_JOB_FAMILIES_TO_JOB_AD = 'action/jobAd/save/jobFamiliesToJobAd'
export const GET_ALL_JOB_ADS_FOR_FAMILY = 'action/jobAd/get/all/jobAdsForFamily'
