export const GET_MENTOR_BASE_DATA = 'action/mentor/mentorBaseData/get'
export const SET_MENTOR_BASE_DATA = 'action/mentor/mentorBaseData/set'
export const GET_ALL_MENTORS = 'action/all-mentors/get'
export const GET_OWN_MENTORS = 'action/mentor/allOwnMentors/get'
export const GET_OWN_RECEIVED_OPEN_MENTOR_INQUIRIES = 'action/mentor/ownReceivedOpenMentor/Inquiries/get'
export const GET_OWN_SENT_OPEN_MENTOR_INQUIRIES = 'action/mentor/ownSentOpenMentor/Inquiries/get'
export const SEND_MENTOR_REQUEST = 'action/mentor/request/send'
export const ACCEPT_MENTOR_REQUEST = 'action/mentor/request/accept'
export const DECLINE_MENTOR_REQUEST = 'action/mentor/request/decline'
export const CANCEL_MENTOR_REQUEST = 'action/mentor/request/cancel'
export const GET_OWN_MENTEES = 'action/mentees/own/get'
