













import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
/*
Used in the global alert in App.vue it shows the user feedback after a form submit.
@group FORMS
 */
export default class SubmitSuccess extends Vue {
  // h4 headline
  @Prop({ required: true })
  headline: string

  // optional info under the headline
  @Prop({ required: false })
  info: string
}
