



























































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { SimpleServerResponse, User } from '@/store/types'
import {
  ACCEPT_DATA_PROTECTION_DECLARATION, ACCEPT_TERMS_OF_SERVICE,
  GET_CURRENT_DECLARATION_CONTENT,
  GET_CURRENT_TERMS_OF_SERVICE_CONTENT,
  LOGIN
} from '@/store/user/actions.type'
import ClickableIcon from '@/components/common/ClickableIcon.vue'
import { LoginCredentials, UserServerObject } from '@/store/user/types'
import SubmitSuccess from '@/components/forms/SubmitSuccess.vue'
import DeclarationModal from '@/components/common/DeclarationModal.vue'
import { Form } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'
import BaseForm from '@/components/forms/BaseForm.vue'
import { parseUserServerObjectToUser } from '@/common/globals'

@Component({
  components: { BaseForm, ClickableIcon, SubmitSuccess, DeclarationModal }
})
/*
@group MENUS
The login form for the meta menu (includes link to registration, forgotten password and login form)
*/
export default class MetaMenuLogin extends Vue {
  @Action('updateLoggedInUser')
  public UPDATE_LOGGED_IN_USER: (user: User) => Promise<string>

  @Action(LOGIN)
  public LOGIN: (params: LoginCredentials) => Promise<UserServerObject>

  @Action(GET_CURRENT_DECLARATION_CONTENT)
  public getCurrentDeclarationContent: (locale: string) => Promise<SimpleServerResponse>

  @Action(ACCEPT_DATA_PROTECTION_DECLARATION)
  public acceptDataProtectionDeclaration: (params: UserServerObject['bearer']) => Promise<SimpleServerResponse>

  @Action(GET_CURRENT_TERMS_OF_SERVICE_CONTENT)
  public getCurrentTermsOfServiceContent: (locale: string) => Promise<SimpleServerResponse>

  @Action(ACCEPT_TERMS_OF_SERVICE)
  public acceptTermsOfService: (params: UserServerObject['bearer']) => Promise<SimpleServerResponse>

  termsOfService = ''
  declaration = ''
  usernameState: boolean | null = null
  feedbackHeadline = ''
  feedbackInfo = ''
  gotResponse = false

  loginCredentials: LoginCredentials = { username: '', password: '' }

  get fields (): Form[] {
    return [
      {
        id: 'email',
        key: 'username',
        type: InputTypes.EMAIL,
        label: this.$i18n.t('register.email').toString(),
        required: true,
        autofocus: true
      },
      {
        id: 'password',
        key: 'password',
        type: InputTypes.PASSWORD,
        label: this.$i18n.t('login.password').toString(),
        trim: true,
        required: true,
        checkPattern: false
      }
    ]
  }

  get correctDeclaration (): string {
    this.getCurrentDeclarationContent(this.$root.$i18n.locale).then(data => {
      this.declaration = data.message
    }, error => {
      this.$root.$emit('alert', this.$i18n.t('warning.error!'), this.$i18n.t('login.' + error.message), true)
    })
    return this.declaration
  }

  get correctTermsOfService (): string {
    this.getCurrentTermsOfServiceContent(this.$root.$i18n.locale).then(data => {
      this.termsOfService = data.message
    }, error => {
      this.$root.$emit('alert', this.$i18n.t('warning.error!'), this.$i18n.t('login.' + error.message), true)
    })
    return this.termsOfService
  }

  declarationSubmit (event: Event): void {
    this.acceptDataProtectionDeclaration(localStorage.getItem('bearer') !== null ? JSON.parse(localStorage.getItem('bearer') as string) : undefined).then(() => this.login(event))
    this.$root.$emit('bv::hide::modal', 'declaration-modal-DPDMeta')
  }

  termsOfServiceSubmit (event: Event): void {
    this.acceptTermsOfService(localStorage.getItem('bearer') !== null ? JSON.parse(localStorage.getItem('bearer') as string) : undefined).then(() => this.login(event))
    this.$root.$emit('bv::hide::modal', 'declaration-modal-TOSMeta')
  }

  login (event: Event): void {
    event.preventDefault()
    this.LOGIN(this.loginCredentials).then(userServerObject => {
      if (userServerObject.requestStatus === 'NO_DATA_PROTECTION_LOGIN') {
        this.$root.$emit('bv::show::modal', 'declaration-modal-DPDMeta')
      } else if (userServerObject.requestStatus === 'NO_TERMS_OF_SERVICE_LOGIN') {
        this.$root.$emit('bv::show::modal', 'declaration-modal-TOSMeta')
      } else {
        this.UPDATE_LOGGED_IN_USER(parseUserServerObjectToUser(userServerObject)).then(firstPage => {
          this.$emit('close')
          this.$router.push({ name: firstPage })
        })
      }
    }, error => {
      if (error.message === 'REGISTERED') {
        this.feedbackHeadline = this.$i18n.t('warning.error!').toString()
        this.feedbackInfo = this.$i18n.t('login.' + error.message).toString()
        this.gotResponse = true
      } else {
        this.$root.$emit('alert', this.$i18n.t('warning.error!'), this.$i18n.t('login.' + error.message), true)
      }
      this.loginCredentials = { username: '', password: '' }
      this.usernameState = null
    })
  }

  resendMail (): void {
    // TODO call endpoint
    this.closeInfo()
  }

  closeInfo (): void {
    this.gotResponse = false
  }

  gotoSite (site: string, register: boolean): void {
    this.$emit('close')
    let props: {}

    if (register) {
      props = { role: '' }
    } else {
      props = { username: this.loginCredentials.username }
    }
    this.$router.push({ name: site, query: props })
  }

  close (): void {
    this.$emit('close')
  }
}
