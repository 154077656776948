





































































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Getter, State } from 'vuex-class'
import { MenuEntry, UserProgressState } from '@/store/types'
import MainMenuDisabled from '@/store/common/MainMenuDisabled'
import { hasRight } from '@/utils/rights'

@Component
/*
@group MENUS
The mobile variant of the main menu (used if the screen resolution is too small)
*/
export default class MainMenuMobile extends Vue {
  @Getter('getMenuEntries')
  public mainMenuEntriesGetter: () => MenuEntry[]

  @Getter('userStateGetter') userStateGetter: () => UserProgressState

  @State('currentPage') currentPage: number[]

  activeChain: HTMLElement[] = []
  activeIndices: number[] = []
  levelIdBase = 'main-menu-entry-'

  // eslint-disable-next-line
  get menuEntries (): any {
    return this.mainMenuEntriesGetter
  }

  // eslint-disable-next-line
  get userState (): any {
    return this.userStateGetter
  }

  get doNotShow (): string {
    return MainMenuDisabled.DO_NOT_SHOW_IN_MENU
  }

  hasRight (rights: string): boolean {
    return hasRight(rights)
  }

  toggleCollapse (id: string): void {
    this.$root.$emit('bv::toggle::collapse', id)
  }

  isAnyChildVisible (children: MenuEntry[]): boolean {
    return children.filter(child => child.disabledIndicator !== this.doNotShow).length >= children.length
  }

  checkActiveIndicesLevel1 (index0: number, index1: number): boolean {
    return this.currentPage.length >= 2 &&
    index0 === this.currentPage[0] &&
      index1 === this.currentPage[1]
  }

  checkActiveIndicesLevel2 (index0: number, index1: number, index2: number): boolean {
    return this.currentPage.length >= 3 &&
    index0 === this.currentPage[0] &&
      index1 === this.currentPage[1] &&
      index2 === this.currentPage[2]
  }

  checkActiveIndicesLevel3 (index0: number, index1: number, index2: number, index3: number): boolean {
    return this.currentPage.length >= 4 &&
      index0 === this.currentPage[0] &&
      index1 === this.currentPage[1] &&
      index2 === this.currentPage[2] &&
      index3 === this.currentPage[3]
  }

  setActiveLevel (el: HTMLElement, level: number, toggle: boolean, navigate: boolean, index: number, route: string): void {
    let close = false
    if (this.activeChain.length - 1 >= level) {
      const count = this.activeChain.length
      const deleted = this.activeChain.splice(level, level + (count - level))
      this.activeIndices.splice(level, level + (count - level))

      deleted.forEach(oldElement => {
        if (oldElement.id === el.id && toggle) {
          close = true
        }

        // close deprecated panels
        const splitParts = oldElement.id.split('-')
        const length = splitParts.length
        this.toggleCollapse('level' + splitParts[0] + '-collapse-' + splitParts[length - 1])

        const collapseIndicators = oldElement.getElementsByClassName('collapse-indicator')
        if (collapseIndicators !== null) {
          const item = collapseIndicators.item(0)

          if (item !== null) {
            item.innerHTML = 'arrow_drop_down'
          }
        }
      })
    }

    if (!close) {
      this.activeChain.push(el)
      this.activeIndices.push(index)

      // open new accordion
      const splitParts = el.id.split('-')
      const length = splitParts.length
      this.toggleCollapse('level' + (level).toString() + '-collapse-' + splitParts[length - 1])

      const collapseIndicators = el.getElementsByClassName('collapse-indicator')
      if (collapseIndicators !== null) {
        const item = collapseIndicators.item(0)

        if (item !== null) {
          item.innerHTML = 'arrow_drop_up'
        }
      }
    }

    if (navigate) {
      this.activeIndices = []
      this.toggleCollapse('main-menu-mobile')
      this.$router.push({ name: route }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    }
  }
}
