













































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Getter, State } from 'vuex-class'
import { MenuEntry, UserProgressState } from '@/store/types'
@Component({
  name: 'MainMenuDesktop'
})
// @vuese
// The desktop variant of the main menu (used if the screen resolution is big enough)
// @group MENUS
export default class MainMenuDesktop extends Vue {
  @Getter('getMenuEntries') mainMenuEntriesGetter: () => MenuEntry[]

  @State('currentPage') currentPage: number[]
  @Getter('userStateGetter') userStateGetter: UserProgressState

  collapsedStates = new Array<boolean>(0)
  lastActive: number | null = null

  // eslint-disable-next-line
  get mainMenuEntries (): any {
    return this.mainMenuEntriesGetter
  }

  // toggle collapsed-state
  toggleCollapsed (index: number): void {
    const collapseState = this.collapsedStates[index]
    this.collapsedStates.splice(index, 1, !collapseState)
  }

  // set collapsed to true when menu entries had been hidden either by closing the menu or clicking elsewhere
  setCollapsed (index: number): void {
    this.collapsedStates.splice(index, 1, true)
  }

  navigate (indexMainMenuItem: number[], newPage: string): void {
    this.lastActive = indexMainMenuItem[0]
    this.$router.push({ name: newPage }).catch(error => {
      if (!error.toString().indexOf('redundant navigation')) {
        throw error
      }
    })
  }

  beforeMount (): void {
    this.collapsedStates = new Array<boolean>(Object.keys(this.mainMenuEntries).length)

    for (let i = 0; i < this.collapsedStates.length; i++) {
      this.collapsedStates[i] = true
    }
  }

  updated (): void {
    const menuDropDowns = document.getElementsByClassName('dropdown')

    if (menuDropDowns !== null) {
      for (let i = 0; i < menuDropDowns.length; i++) {
        const dropdownItem = menuDropDowns.item(i) as HTMLElement

        if (dropdownItem !== null) {
          const ul = dropdownItem.getElementsByTagName('ul').item(0)

          if (ul !== null) {
            ul.style.minWidth = dropdownItem.clientWidth + 'px'
          }
        }
      }
    }
  }
}
