























import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { phoneRegex } from '@/common/regex'
import ClickableIcon from './ClickableIcon.vue'
@Component({
  components: { ClickableIcon }
})
/*
An input element especially for phone numbers
@group INPUT ELEMENTS
 */
export default class PhoneInput extends Vue {
  // the model used for the data
  @Prop({ default: '' })
  value: string

  // if the input is required
  @Prop({ default: false })
  required: boolean

  // if the input element should be focused
  @Prop({ default: false })
  autofocus: boolean

  // if a trim is applied to the input
  @Prop({ default: false })
  trim: boolean

  // the aria placeholder for accessibility
  @Prop({ default: '' })
  ariaPlaceholder: string

  // unique identifier
  @Prop({ default: 'phone' })
  id: string

  // if the input is disabled
  @Prop({ default: false })
  disabled: boolean

  icon = 'visibility'
  internalModel = ''
  state: boolean | null = null
  invalidMessageRequired = ''
  invalidMessageWrongPattern = ''
  inputElement: HTMLInputElement | null

  phone = phoneRegex()

  @Watch('value')
  onValueChange (newValue: string): void {
    this.internalModel = newValue
    if (newValue) this.validate(this.internalModel)
  }

  validate (input: string): void {
    this.$emit('input', input)
    if (this.inputElement === null) {
      this.state = null
    } else {
      this.state = this.inputElement.checkValidity()
    }
  }

  mounted (): void {
    this.invalidMessageRequired = this.$i18n.t('warning.provide-required-input').toString()
    this.invalidMessageWrongPattern = this.$i18n.t('warning.invalid-phone').toString()
    this.internalModel = this.value
    this.inputElement = document.getElementById(this.id) as HTMLInputElement
  }
}
