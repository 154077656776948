import { Module, Action, VuexModule } from 'vuex-module-decorators'
import { SimpleServerResponse } from '@/store/types'
import { DOWNLOAD_DB_TABLES_CSV, REQUEST_DB_TABLES_CSV } from '@/store/evaluation/actions'
import { EvaluationService } from '@/common/api.service'
import { ConfirmRegistrationCredentials } from '@/store/user/types'

@Module
class EvaluationState extends VuexModule {
  @Action({ rawError: true })
  [REQUEST_DB_TABLES_CSV] (): Promise<SimpleServerResponse> {
    return EvaluationService.requestCSVExport()
  }

  @Action({ rawError: true })
  [DOWNLOAD_DB_TABLES_CSV] (dto: ConfirmRegistrationCredentials): Promise<SimpleServerResponse> {
    return EvaluationService.downloadTablesCSV(dto)
  }
}

export default EvaluationState
