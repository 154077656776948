export const SEARCH_TALENT_BY_NAME = 'action/employee/searchTalentByName'
export const GET_STUDENT_COMPETENCE_PROFILE = 'action/employee/getStudentCompetenceProfile'
export const SET_STUDENT_COMPETENCE_PROFILE = 'action/employee/setStudentCompetenceProfile'
export const GET_EMPLOYEE_BASE_DATA = 'action/employee/getEmployeeBaseData'
export const SET_EMPLOYEE_BASE_DATA = 'action/employee/setEmployeeBaseData'
export const GET_TASKS = 'action/employee/getTasks'
export const UPLOAD_AND_TALENT_SUGGESTION = 'action/employee/uploadAndTalentSuggestion'
export const GET_READ_OUT_COMPETENCES_FOR_TALENT = 'action/employee/getReadOutCompetencesForTalent'
export const UPLOAD_PDF_AND_GET_COMPETENCES = 'action/employee/uploadPDFAndGetCompetences'
export const SEND_MESSAGE = 'action/employee/messaging/sendMessage'
export const GET_RECIPIENT_GROUPS = 'action/employee/messaging/getRecipientGroups'
export const GET_SENT_MESSAGES = 'action/employee/messaging/getSentMessages'
export const GET_MODULES = 'action/employee/module/getAll'
export const CHANGE_MODULE_AVAILABILITY = 'action/employee/module/changeAvailability'
export const GET_I18N_LANGUAGES = 'action/employee/languages/get'
export const GET_I18N_LANGUAGE = 'action/employee/language/get'
export const SAVE_I18N_LANGUAGES = 'action/employee/languages/post'
export const GROUP_SYNONYM = 'action/employee/synonym/group'
export const EDIT_SYNONYM = 'action/employee/synonym/edit'
export const GET_OPEN_ACTIVATION_REQUESTS_TALENTS = 'action/employee/open-activation-requests/talents/get'
export const GET_OPEN_ACTIVATION_REQUESTS_COMPANIES = 'action/employee/open-activation-requests/companies/get'
