import { InquiryDto } from '@/store/inquiry/types'
import { VuexModule, Module, Action } from 'vuex-module-decorators'
import {
  INQUIRIES_FOR_TALENT,
  INQUIRE_TALENT, INQUIRE_TALENT_CANCEL, ACCEPT_DECLINE, INQUIRIES_FOR_COMPANY
} from '@/store/inquiry/actions.ts'
import { CompanyService, InquiryService } from '@/common/api.service'
import { GenericRequest, SimpleServerResponse } from '@/store/types'

@Module
class InquiryState extends VuexModule {
  @Action({ rawError: true })
  public [INQUIRE_TALENT] (data: InquiryDto): Promise<InquiryDto> {
    return CompanyService.inquiryTalent(data)
  }

  @Action({ rawError: true })
  public [INQUIRE_TALENT_CANCEL] (data: GenericRequest): Promise<SimpleServerResponse> {
    return CompanyService.cancelInquiry(data)
  }

  @Action({ rawError: true })
  public [INQUIRIES_FOR_TALENT] (status: string): Promise<InquiryDto[]> {
    return InquiryService.getInquiriesForTalent(status)
  }

  @Action({ rawError: true })
  public [INQUIRIES_FOR_COMPANY] (request: GenericRequest): Promise<InquiryDto[]> {
    return InquiryService.getInquiriesForCompany(request)
  }

  @Action({ rawError: true })
  public [ACCEPT_DECLINE] (dto: GenericRequest): Promise<SimpleServerResponse> {
    return InquiryService.acceptDeclineInquiry(dto)
  }
}

export default InquiryState
