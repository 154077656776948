export const emailRegex = (): string => {
  return '[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)+'
}

export const phoneRegex = (): string => {
  return '^[(/).+\\d -]{1,25}$'
}

export const passwordRegex = (): string => {
  return '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$'
}
