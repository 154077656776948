











import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
/*
@group MENUS
Button used in the meta menu
*/
export default class MetaMenuButton extends Vue {
  // unique identifier
  @Prop()
  id: string

  // icon for the button (material icons)
  @Prop()
  icon: string

  // button text
  @Prop()
  text: string

  // if the text of the button is shown (no text is used on mobile)
  @Prop()
  showText: boolean

  mounted (): void {
    const _sheetId = 'pseudoStyles'
    const _head = document.head || document.getElementsByTagName('head')[0]
    const _sheet = document.getElementById(_sheetId) || document.createElement('style')
    _sheet.id = _sheetId
    _sheet.innerHTML += 'div#' + this.id + '> span:before{content: \'' + this.icon + '\'}'
    _head.appendChild(_sheet)
  }
}
