





















































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
@Component({
  name: 'FooterMenu'
})
/*
@vuese
@group MENUS
Component for the footer menu. It includes the software version, logos, links and possibly social media icons.
*/
export default class FooterMenu extends Vue {
}
